import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { BaseDialogComponent } from '../../forms/base-dialog/base-dialog.component';
import { BaseEnrollmentComponent } from '../base-enrollment/base-enrollment.component';
import { environment } from 'src/environments/environment';
import { FormBuilder } from '@angular/forms';
import { forkJoin, from, mergeMap } from 'rxjs';

@Component({
  selector: 'app-signature-enrollment',
  templateUrl: './signature-enrollment.component.html',
  styleUrls: ['./signature-enrollment.component.css']
})
export class SignatureEnrollmentComponent extends BaseEnrollmentComponent implements OnInit {
  @ViewChild('SignatureStudent') override signatureStudent: ElementRef<HTMLCanvasElement>;
  @ViewChild('SignatureStudio') override signatureStudio: ElementRef<HTMLCanvasElement>;
  @ViewChild('SignatureParent') override signatureParent: ElementRef<HTMLCanvasElement>;

  // Add the proper entity type and bundle
  override eckType = "packages";
  override bundle = "enrollment";
  override entityId = this._injectedDialogData['data']['EntityID'];

  override ngOnInit(): void {
    super.ngOnInit();

    // Initialize tosAccepted based on entity data if available
    this._entityRESTService.getEntity(this.eckType, this.bundle, this.entityId).subscribe(
      (data: any) => {
        if (data && data.field_tos_accepted_date) {
          this.tosAccepted = true;
        }
      }
    );
  }

  override ngAfterViewInit(): void {
    super.ngAfterViewInit();

    // Initialize signatures
    this.signatureInit(this.signatureStudent, 'student');
    this.signatureInit(this.signatureStudio, 'studio');
    if (this.signatureParent) {
      this.signatureInit(this.signatureParent, 'parent');
    }

    // Initialize tosAccepted based on entity data
    if (this.entityData?.field_tos_accepted_date) {
      this.tosAccepted = true;
    } else if (this.enrollmentForm?.get('field_tos_accepted_date')?.value) {
      this.tosAccepted = true;
    } else {
      this.tosAccepted = false;
    }
  }

  /**
   * Override onSubmitSignatures to ensure signatures are properly detected and TOS is accepted
   */
  override async onSubmitSignatures(action: string): Promise<void> {
    // Make sure parent signature is properly detected before submitting
    if (this.signatureParent && this.signatureParent.nativeElement) {
      this.checkParentSignature(this.signatureParent.nativeElement);
    }

    // Check if Terms of Service is accepted
    if (!this.tosAccepted) {
      this._snackBar.open('Please accept the Terms of Service before proceeding', 'Close', {
        duration: 3000,
      });
      return;
    }

    // Call the parent implementation
    await super.onSubmitSignatures(action);
  }
}

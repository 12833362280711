import { Component, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ViewTableComponent } from "../../base/view-table/view-table.component";
import moment from "moment";
import { environment } from "../../../../environments/environment";
import { PrintService } from 'src/app/services/print.service';
import { DialogService } from 'src/app/services/dialog.service';
import { EntityRESTService } from 'src/app/services/entity-rest.service';
import { UtilityService } from 'src/app/services/utility.service';
import { FieldsService } from 'src/app/services/fields.service';
import { DrupalRESTService } from 'src/app/services/drupal-rest.service';
import { TaxonomyService } from 'src/app/services/taxonomy.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthRESTService } from 'src/app/services/auth-rest.service';
import { AuthService } from 'src/app/services/auth.service';
import { MenuRESTService } from 'src/app/services/menu-rest.service';
import { FormBuilder } from '@angular/forms';
import { RouteEventsService } from 'src/app/route-events.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { ConfigPagesService } from 'src/app/services/config-pages.service';
import { PackagesService } from 'src/app/services/packages.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from 'src/app/services/user.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Renderer2 } from '@angular/core';
import { RefreshService } from 'src/app/services/refresh.service';
interface StaffTrackingData {
  name: string;
  job_title: string;
  week: {
    lessons_taught: number;
    lesson_sales: number;
    misc_sales: number;
    cash: number;
  };
  ytd: {
    lessons_taught: number;
    lesson_sales: number;
    misc_sales: number;
    cash: number;
  };
}

interface StaffTableRow {
  period: string;
  lessons_taught: number;
  lesson_sales: number;
  misc_sales: number;
  cash: number;
}

@Component({
  selector: 'app-staff-tracking-report',
  templateUrl: './staff-tracking-report.component.html',
  styleUrls: ['./staff-tracking-report.component.css']
})
export class StaffTrackingReportComponent extends ViewTableComponent {
  currentReport: string = 'staffTracking';
  week: string;
  year: string;
  staffData: StaffTrackingData[] = [];
  override displayedColumns: string[] = ['period', 'lessons_taught', 'lesson_sales', 'misc_sales', 'cash'];

  constructor(
    public override _refreshService: RefreshService,
    private _printService: PrintService,
    public override _dialogService: DialogService,
    public override _entityRESTService: EntityRESTService,
    public override _utilityService: UtilityService,
    public override _fieldsService: FieldsService,
    public override _drupalRESTService: DrupalRESTService,
    public override _taxonomyService: TaxonomyService,
    public override _router: Router,
    public override _activatedRoute: ActivatedRoute,
    public override _authRESTService: AuthRESTService,
    public override _authService: AuthService,
    public override _menuRESTService: MenuRESTService,
    public override _formBuilder: FormBuilder,
    public override _routeEventsService: RouteEventsService,
    public override _permissionsService: PermissionsService,
    public override _renderer2: Renderer2,
    public override _configPages: ConfigPagesService,
    public override _packagesService: PackagesService,
    public override _snackBar: MatSnackBar,
    public override _userRESTService: UserService,
    public override _el: ElementRef,
    public override _changeDetectorRef: ChangeDetectorRef,
    public override _sanitizer: DomSanitizer
  ) {
    super(
      _refreshService,
      _dialogService,
      _entityRESTService,
      _utilityService,
      _fieldsService,
      _drupalRESTService,
      _taxonomyService,
      _router,
      _activatedRoute,
      _authRESTService,
      _authService,
      _menuRESTService,
      _formBuilder,
      _routeEventsService,
      _permissionsService,
      _renderer2,
      _configPages,
      _packagesService,
      _snackBar,
      _userRESTService,
      _el,
      _changeDetectorRef,
      _sanitizer
    );
  }

  onYearChange(event: any) {
    this.year = event.value;
    this.WeeksForYearData = this._fieldsService.WeeksForYearData(Number.parseInt(this.year));
  }

  override ngAfterContentInit(): void {
    this._activatedRoute.queryParams.subscribe(data => {
      this.ShowProgressBar = false;

      if (data['year']) {
        this.year = data['year'];
      } else {
        this.year = moment().format("YYYY");
      }
      if (data['week']) {
        this.week = data['week'];
      } else {
        this.week = moment().add(0, 'week').format("w");
      }

      this.getData([
        { parameter: 'week', value: this.week},
        { parameter: 'year', value: this.year},
      ]);
    });
  }

  override getData(params: any) {
    let selectedDate = moment().year(+this.year).week(+this.week);
    let weekEnd = selectedDate.clone().endOf('isoWeek');
    let currentDate = moment();

    this.isDisabled = !weekEnd.isBefore(currentDate);
    this.ShowProgressBar = true;
    this.queryParams = params;

    if (this.sortParams !== undefined) {
      this.queryParams.push(this.sortParams);
    }

    this._drupalRESTService.httpGET('/api_rest/v1/staff_tracking_report', params)
      .subscribe({
        next: (response) => {
          this.ShowProgressBar = false;
          this.staffData = response?.['staff_data'];
          this.data = response;
        },
        error: (error) => {
          this.ShowProgressBar = false;
          console.error('Error fetching staff tracking data:', error);
        }
      });
  }

  getStaffData(staff: StaffTrackingData): StaffTableRow[] {
    return [
      {
        period: 'Week',
        lessons_taught: staff.week.lessons_taught,
        lesson_sales: staff.week.lesson_sales,
        misc_sales: staff.week.misc_sales,
        cash: staff.week.cash
      },
      {
        period: 'YTD',
        lessons_taught: staff.ytd.lessons_taught,
        lesson_sales: staff.ytd.lesson_sales,
        misc_sales: staff.ytd.misc_sales,
        cash: staff.ytd.cash
      }
    ];
  }

  exportReport() {
    // Get the current query parameters
    const params = {
      week: this.week,
      year: this.year
    };

    // Create the URL with query parameters
    const baseUrl = environment.hostUrl;
    const queryString = new URLSearchParams(params).toString();
    const studioId = this._authService.studios?.[0]?.id;
    const url = `${baseUrl}/api_rest/v1/staff_tracking_report_csv?${queryString}&field_studio_reference_target_id=${studioId}`;

    // Trigger file download
    window.open(url, '_blank');
  }

  refreshData() {
    this.getData([
      { parameter: 'week', value: this.week},
      { parameter: 'year', value: this.year},
    ]);
  }

  // Helper method to format currency values
  private formatCurrency(value: number | string | null | undefined): string {
    if (value === null || value === undefined) {
      return '0.00';
    }

    const numValue = typeof value === 'string' ? parseFloat(value) : value;
    return numValue.toFixed(2);
  }

  printReport() {
    // Build the HTML content for printing
    let content = `
      <div class="report-container">
        <h1 class="report-title">Staff Tracking Report</h1>
        <h2 class="report-subtitle">Week ${this.week}, ${this.year}</h2>`;

    // Add individual staff details if available
    if (this.staffData && this.staffData.length > 0) {
      this.staffData.forEach(staff => {
        content += `
          <div class="staff-detail">
            <h4 class="staff-name">${staff.name || 'N/A'}</h4>
            <table class="report-table">
              <thead>
                <tr>
                  <th></th>
                  <th>Weekly</th>
                  <th>Year-to-Date</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Lessons Taught</td>
                  <td>${staff.week.lessons_taught || 0}</td>
                  <td>${staff.ytd.lessons_taught || 0}</td>
                </tr>
                <tr>
                  <td>Lesson Sales</td>
                  <td>$${this.formatCurrency(staff.week.lesson_sales || 0)}</td>
                  <td>$${this.formatCurrency(staff.ytd.lesson_sales || 0)}</td>
                </tr>
                <tr>
                  <td>Misc Sales</td>
                  <td>$${this.formatCurrency(staff.week.misc_sales || 0)}</td>
                  <td>$${this.formatCurrency(staff.ytd.misc_sales || 0)}</td>
                </tr>
                <tr>
                  <td>Total Cash</td>
                  <td>$${this.formatCurrency(staff.week.cash || 0)}</td>
                  <td>$${this.formatCurrency(staff.ytd.cash || 0)}</td>
                </tr>
              </tbody>
            </table>
          </div>`;
      });
    } else {
      content += `
        <div class="no-data-message">
          No staff data available for this period
        </div>`;
    }

    content += `</div>`;

    // Define custom styles for the report
    const styles = `
      .report-container {
        font-family: Arial, sans-serif;
        margin: 0 auto;
        padding: 20px;
        max-width: 100%;
      }
      .report-title {
        font-size: 24px;
        text-align: center;
        margin-bottom: 5px;
        color: #333;
      }
      .report-subtitle {
        font-size: 18px;
        text-align: center;
        margin-bottom: 20px;
        color: #666;
      }
      .report-table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 20px;
      }
      .report-table th, .report-table td {
        border: 1px solid #ddd;
        padding: 8px;
        text-align: left;
      }
      .report-table th {
        background-color: #f2f2f2;
        font-weight: bold;
      }
      .report-table tr:nth-child(even) {
        background-color: #f9f9f9;
      }
      .no-data-message {
        text-align: center;
        font-style: italic;
        color: #999;
        margin: 30px 0;
      }
      .staff-detail {
        margin-bottom: 25px;
        page-break-inside: avoid;
      }
      .staff-name {
        font-size: 16px;
        margin-bottom: 10px;
        color: #444;
      }
      @media print {
        .staff-detail {
          page-break-inside: avoid;
        }
      }
    `;

    // Use the PrintService to print the report
    this._printService.print(content, {
      title: `Staff Tracking Report - Week ${this.week}, ${this.year}`,
      styles: styles,
      showSchoolName: true
    });
  }
}

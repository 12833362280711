import { Component, OnInit, NgZone, Injector, ApplicationRef } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService } from 'src/app/services/dialog.service';
import { ComponentRefreshService } from 'src/app/services/component-refresh.service';
import { ComponentType } from '@angular/cdk/overlay';

@Component({
  selector: 'app-refresh-helper',
  templateUrl: './refresh-helper.component.html',
  styleUrls: ['./refresh-helper.component.css']
})
export class RefreshHelperComponent implements OnInit {

  constructor(
    private router: Router,
    private dialogService: DialogService,
    private ngZone: NgZone,
    private injector: Injector,
    private appRef: ApplicationRef,
    private componentRefreshService: ComponentRefreshService
  ) { }

  ngOnInit(): void {
  }

  /**
   * Opens an entity component dialog and refreshes the route after it's closed
   * @param component The component to open
   * @param eckType The entity type
   * @param bundle The entity bundle
   * @param action The action to perform
   * @param EntityID The entity ID (optional)
   * @param fieldsData Additional fields data (optional)
   * @param dialogConfig The dialog configuration (default: "defaultWithData")
   */
  openEntityWithRefresh(
    component: ComponentType<unknown>,
    eckType: string,
    bundle: string,
    action: string,
    EntityID?: any,
    fieldsData?: any,
    dialogConfig: string = "defaultWithData"
  ): void {
    // Open the dialog
    this.dialogService.openDialog(component, dialogConfig, {
      data: {
        EntityID: EntityID,
        eckType: eckType,
        bundle: bundle,
        action: action,
        fieldsData: fieldsData ?? '',
      },
    }).afterClosed().subscribe(result => {
      // Always refresh after dialog is closed
      console.log('Dialog closed, refreshing data. Result:', result);

      // Call the getData method of the current component
      this.refreshComponentData();
    });
  }

  /**
   * Opens a payment entity component dialog and refreshes the route after it's closed
   * @param component The component to open
   * @param eckType The entity type
   * @param bundle The entity bundle
   * @param action The action to perform
   * @param EntityID The entity ID (optional)
   * @param fieldsData Additional fields data (optional)
   */
  openPaymentWithRefresh(
    component: ComponentType<unknown>,
    eckType: string,
    bundle: string,
    action: string,
    EntityID?: any,
    fieldsData?: any
  ): void {
    // Open the dialog with payment form configuration
    this.openEntityWithRefresh(component, eckType, bundle, action, EntityID, fieldsData, "paymentFormWithData");
  }

  /**
   * Refreshes the current component's data by calling its getData method
   */
  refreshComponentData(): void {
    console.log('Refreshing component data');

    // Try to find the current component instance
    const currentRoute = this.router.url;
    console.log('Current route:', currentRoute);

    // Try to find a component with a getData method
    let componentName = '';

    // First, try to find a component based on the current route
    if (currentRoute.includes('payment-reports')) {
      componentName = 'PaymentsReportViewComponent';
    } else if (currentRoute.includes('enrollment')) {
      componentName = 'EnrollmentsViewComponent';
    } else if (currentRoute.includes('package-list')) {
      componentName = 'PackageListComponent';
    }

    if (componentName) {
      // Use the ComponentRefreshService to refresh the component
      this.componentRefreshService.refreshComponent(componentName);
    } else {
      // As a fallback, use the navigation approach
      this.refreshCurrentRoute();
    }
  }

  /**
   * Refreshes the current route by navigating to a temporary route and then back
   * This is used as a fallback if we can't find a component with getData
   */
  refreshCurrentRoute(): void {
    // Store the current route
    const currentRoute = this.router.url;
    console.log('Refreshing route:', currentRoute);

    // Use a more reliable approach to refresh the current route
    // First navigate to a different route with skipLocationChange
    // Then navigate back to the current route
    this.router.navigateByUrl('/dashboard', { skipLocationChange: true })
      .then(() => {
        console.log('Navigation to temp route successful');
        // Then navigate back to the original route
        return this.router.navigateByUrl(currentRoute);
      })
      .then(() => {
        console.log('Navigation back to original route successful');
      })
      .catch(error => {
        console.error('Navigation error:', error);
        // Fallback approach if the first one fails
        this.forcePageReload();
      });
  }

  /**
   * Forces a page reload as a last resort
   */
  forcePageReload(): void {
    console.log('Forcing page reload');
    // Use NgZone to ensure this runs in the Angular zone
    this.ngZone.run(() => {
      // Use window.location.reload() as a last resort
      window.location.reload();
    });
  }

  /**
   * Directly refreshes the payments report component
   */
  refreshPaymentsReport(): void {
    console.log('Directly refreshing payments report');
    this.componentRefreshService.refreshPaymentsReport();
  }

  /**
   * Directly refreshes the enrollments view component
   */
  refreshEnrollmentsView(): void {
    console.log('Directly refreshing enrollments view');
    this.componentRefreshService.refreshEnrollmentsView();
  }

  /**
   * Directly refreshes the package list component
   */
  refreshPackageList(): void {
    console.log('Directly refreshing package list');
    this.componentRefreshService.refreshPackageList();
  }
}

<!-- enrollment.component.html -->
<app-entity-mat-dialog-title title="Enrollment"></app-entity-mat-dialog-title>

<div [ngClass]="{
        'loading-shade': showProgressSpinner
     }">

    <div mat-dialog-content class="mat-tab-header">

        <mat-progress-spinner class="matProgressSpinnerCentered" *ngIf="showProgressSpinner" mode="indeterminate"></mat-progress-spinner>

        <mat-tab-group class="eventTabGroup" [selectedIndex]="this.selectedIndex" (selectedTabChange)="setFormMode($event.index)" animationDuration="0ms" #tabs>

            <mat-tab label="View" [disabled]="this.initActionType == 'create'">

                <div class="container-fluid">
                    <div class="row">
                        <!-- Use the new enrollment-agreement-display component -->
                        <app-enrollment-agreement-display
                            [entityData]="entityData"
                            [studentAccountEntityData]="studentAccountEntityData"
                            [studioInformationConfig]="studioInformationConfig"
                            [enrollmentForm]="enrollmentForm"
                            [displaySignatures]="true"
                            [_enrollmentEntityData]="_enrollmentEntityData"
                            (printRequested)="printPage($event.showBackOnly, $event.showFrontOnly)">
                        </app-enrollment-agreement-display>

                        <div id="enrollment-agreement-backpage" class="page2">
                            <div [innerHTML]="backpageHtmlContent"></div>
                        </div>

                        <div class="row mt-3 mb-3">
                            <div class="col-6">
                                <button (click)="printPage(false, true)"  class="me-1" type="button" mat-stroked-button color="primary">
                                    Print
                                </button>
                                <button class="me-1" type="button" mat-stroked-button color="primary" (click)="sendEmailNewEnrollment(this.entityData?.id)">
                                    Email
                                </button>
                            </div>
                            <div class="col-6">
                                <div class="pull-end">
                                    <button
                                    [disabled]="!detectSignatureStudio || !detectSignatureStudent || !tosAccepted"
                                    class="me-1"
                                    type="button"
                                    mat-stroked-button
                                    (click)="onSubmitSignatures('save_and_add')"
                                    color="primary">
                                        Save and Add
                                    </button>
                                    <button
                                    [disabled]="!detectSignatureStudio || !detectSignatureStudent || !tosAccepted"
                                    class="me-1"
                                    type="button"
                                    mat-stroked-button
                                    (click)="onSubmitSignatures('save_and_pay')"
                                    color="primary">
                                        Save and Pay
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </mat-tab>

            <mat-tab [disabled]="this.initActionType != 'create'" label="{{(this.initActionType == 'create' ? 'Create' : 'Edit')}}">
                <mat-stepper [className]="this.initActionType == 'create' ? '' : 'hide-mat-horizontal-stepper-header-container'" [selectedIndex]="this.enrollmentStepperIndex" [linear]="true" #enrollmentStepper>
                    <mat-step [stepControl]="enrollmentForm">

                        <form *ngIf="enrollmentForm" [formGroup]="enrollmentForm" (ngSubmit)="onSubmitEnrollment(f, stepper)" #f="ngForm" class="form">
                            <ng-template matStepLabel>Enrollment</ng-template>


                            <div class="container">
                                <div class="row">
                                    <input class="d-none" formControlName="title" value="'default'" matInput>
                                    <input class="d-none" formControlName="field_payments_structure_type" value="field_payments_structure_type" matInput>

                                    <app-error-message [message]=this.errorMessage></app-error-message>
                                    <!-- Student Account Autocomplete -->
                                    <div class="col-6">
                                        <mat-form-field class="full-width-field">
                                            <mat-label>Student Account</mat-label>
                                            <input
                                                required
                                                name="field_student"
                                                (input)="onAutocompleteStudent($event);"
                                                matInput
                                                formControlName="field_student"
                                                required
                                                #td_student_account
                                                [matAutocomplete]="autoCompleteStudentAccount"
                                                [ngModel]="this._frmValues?.field_student"
                                                >
                                            <button type="button" matSuffix mat-icon-button
                                                aria-label="manage_accounts">
                                                <mat-icon>manage_accounts</mat-icon>
                                            </button>
                                        </mat-form-field>
                                        <mat-autocomplete #autoCompleteStudentAccount="matAutocomplete" (optionSelected)="onAutoCompleteStudentLoadInstructorExecutive($event);checkStudentBalanceLimit(this.enrollmentForm.value.field_student);">
                                            <mat-option
                                                *ngFor="let option of autoCompleteStudentOptions"
                                                [value]="option.label + ' (' + option.value + ')'">
                                                {{option.label}} ({{option.value}})
                                            </mat-option>
                                        </mat-autocomplete>
                                    </div>

                                    <div class="col-6">
                                        <mat-form-field>
                                        <input
                                        formControlName="field_sale_date"
                                        name="field_sale_date"
                                        matInput
                                        (ngModelChange)="onEnrollmentSaleDate($event)"

                                        [ngxMatDatetimePicker]="picker_field_date_time_enrollment_form"
                                        placeholder="Enrollment Date"
                                        [ngModel]="this._frmValues?.field_sale_date"
                                        >
                                        <!-- {{this._frmValues?.field_sale_date}} -->
                                        <mat-datepicker-toggle matSuffix [for]="picker_field_date_time_enrollment_form">
                                        </mat-datepicker-toggle>
                                        <ngx-mat-datetime-picker #picker_field_date_time_enrollment_form
                                            [hideTime]="true"
                                            [enableMeridian]="true"
                                            [showSpinners]="true"
                                            [showSeconds]="false"
                                            [stepHour]="1" [stepMinute]="15"
                                            [touchUi]="false">
                                        </ngx-mat-datetime-picker>
                                        </mat-form-field>
                                    </div>

                                    <!-- Category Dropdown -->
                                    <div class="col-12">
                                        <mat-form-field class="full-width-field">
                                            <mat-label>Category</mat-label>
                                            <mat-select (selectionChange)="calcNewTotal(); validateDECategoryPrice()" matInput formControlName="field_category" name="field_category">
                                                <mat-option>None</mat-option>
                                                <mat-option *ngFor="let element of this._taxonomyService.enrollment_type"
                                                    [value]="element.id">
                                                    {{ element.name }}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <!-- Package Autocomplete -->
                                    <div class="col-12">
                                        <mat-form-field class="full-width-field">
                                        <mat-label>Package</mat-label>
                                        <input formControlName="field_enrollment_package_name" name="field_enrollment_package_name" (input)="onAutocompletePackages($event);" matInput
                                            [matAutocomplete]="autoCompletePackage">
                                        <button type="button" matSuffix mat-icon-button aria-label="manage_accounts">
                                            <mat-icon>manage_accounts</mat-icon>
                                        </button>
                                        </mat-form-field>
                                        <mat-autocomplete #autoCompletePackage="matAutocomplete" (optionSelected)="onPackageLookup($event);">
                                        <mat-option *ngFor="let option of autoCompletePackageOptions" [value]="option.label + ' ' + '(' + option.value + ')'">
                                            {{option.label}} ({{option.value}})
                                        </mat-option>
                                        </mat-autocomplete>
                                    </div>

                                    <div class="container mb-2 d-none">
                                        <div class="row">
                                            <div style="border: 1px solid rgb(204, 204, 204); padding: 13px; border-radius: 10px;">
                                                <h3>Gift Certificates</h3>
                                                <ng-container formArrayName="giftCertificates">
                                                    <div *ngFor="let giftCertificate of giftCertificatesArray.controls; let i = index" [formGroupName]="i">
                                                        <div class="row">
                                                            <div class="col-4">
                                                                <mat-form-field class="full-width-field">
                                                                    <mat-label>Gift Certificate Code</mat-label>
                                                                    <input formControlName="code" matInput>
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col-4">
                                                                <mat-form-field class="full-width-field">
                                                                    <mat-label>Redemption Amount</mat-label>
                                                                    <input type="number" formControlName="redemptionAmount" matInput>
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col-2">
                                                                <button type="button" mat-icon-button color="primary" (click)="applyGiftCertificate(i)">
                                                                    <mat-icon>redeem</mat-icon>
                                                                </button>
                                                                <mat-icon *ngIf="giftCertificate.get('applied').value" style="color: green;">check_circle</mat-icon>
                                                            </div>
                                                            <div class="col-2">
                                                                <mat-icon
                                                                class="ms-2 cursor-pointer"
                                                                (click)="removeGiftCertificate(i)"
                                                                aria-hidden="false"
                                                                aria-label="trash">cancel</mat-icon>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                                <button type="button" mat-raised-button color="primary" (click)="addGiftCertificate()">Add Gift Certificate</button>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-6">
                                            <mat-form-field class="full-width-field">
                                                <mat-label>{{getAmountLabel()}}</mat-label>
                                                <input formControlName="field_enrollment_lesson_price" type="number" (change)="calcNewTotal(); validateDECategoryPrice()" name="field_enrollment_lesson_price" matInput>
                                                <span matPrefix>$&nbsp;</span>
                                            </mat-form-field>
                                        </div>

                                        <div [ngClass]="{ 'd-none': isMiscOrSundry  }" class="col-6">
                                            <mat-form-field class="full-width-field">
                                                <mat-label>Lesson Count</mat-label>
                                                <input min="0" type="number" (change)="calcNewTotal()" name="field_enrollment_lesson_count" matInput formControlName="field_enrollment_lesson_count">
                                            </mat-form-field>
                                        </div>
                                    </div>

                                    <!-- Discount Type Dropdown -->
                                    <div class="col-3">
                                        <mat-form-field class="full-width-field">
                                            <mat-label>Discount Type</mat-label>
                                            <mat-select (ngModelChange)="calcNewTotal()"
                                             matInput
                                             [ngModel]="this._frmValues?.field_discount_type"
                                             formControlName="field_discount_type"
                                             name="field_discount_type">
                                                <mat-option>None</mat-option>
                                                <mat-option
                                                    (optionSelected)="calcNewTotal()"
                                                    *ngFor="let element of this._fieldsService.discountType"
                                                    [value]="element.key">
                                                    {{ element.value }}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <div class="col-3">
                                        <mat-form-field class="full-width-field">
                                            <mat-label>Discount</mat-label>
                                            <input [(ngModel)]="this.enrollmentForm.value.field_discount"
                                            min="0"
                                            type="number"
                                            (change)="calcNewTotal()"
                                            name="field_discount"
                                            matInput formControlName="field_discount">
                                            <span *ngIf="this.enrollmentForm.value.field_discount_type == 'flat'" matPrefix>$&nbsp;</span>
                                            <span *ngIf="this.enrollmentForm.value.field_discount_type == 'percentage'" matSuffix>&nbsp;%</span>
                                        </mat-form-field>
                                    </div>

                                    <div class="col-2">
                                        <mat-form-field class="full-width-field">
                                            <mat-label>Taxes</mat-label>
                                            <input [ngModel]="this._frmValues?.field_tax_percentage" formControlName="field_tax_percentage" type="number" (change)="calcNewTotal()" name="field_tax_percentage" matInput>
                                            <span matSuffix>%&nbsp;</span>
                                        </mat-form-field>
                                    </div>

                                    <!-- This is for the entire enrollment. -->
                                    <input class="d-none" name="field_enrollment_total_price" matInput formControlName="field_enrollment_total_price">
                                    <div class="col-4 mb-1">
                                        <mat-form-field appearance="fill" class="full-width-field">
                                            <mat-label>Total Investment</mat-label>
                                            <input readonly name="field_total_price_with_est_tax" matInput formControlName="field_total_price_with_est_tax">
                                            <span matPrefix>$&nbsp;</span>
                                            <mat-hint>({{this.enrollmentForm.get("field_tax_percentage").value}}% estimated tax and ${{this.enrollmentForm.get("field_discount").value}} discount is calculated.)</mat-hint>
                                        </mat-form-field>
                                    </div>

                                    <div class="mt-3"></div>

                                    <div class="col-3 d-none">
                                        <mat-form-field appearance="fill" class="full-width-field">
                                            <mat-label>Total Lesson Price</mat-label>
                                            <input readonly name="field_total_lessons_price" matInput formControlName="field_total_lessons_price">
                                            <span matPrefix>$&nbsp;</span>
                                        </mat-form-field>
                                    </div>
                                </div>

                                <!-- <div class="row">
                                    <div class="col-4">
                                        <mat-form-field appearance="fill" class="full-width-field">
                                            <mat-label>Total Paid</mat-label>
                                            <input readonly name="field_total_paid" matInput formControlName="field_total_paid">
                                        </mat-form-field>
                                    </div>
                                </div> -->
                            </div>

                            <div class="container">
                                <div class="row">

                                        <div class="col-6 d-none">
                                            <mat-form-field appearance="fill" class="full-width-field">
                                                <mat-label>Lesson Used</mat-label>
                                                <input readonly name="field_lesson_used" matInput formControlName="field_lesson_used">
                                            </mat-form-field>
                                        </div>

                                        <div class="col-6 d-none">
                                            <mat-form-field appearance="fill" class="full-width-field">
                                                <mat-label>Lesson Paid</mat-label>
                                                <input readonly name="field_lessons_paid" matInput formControlName="field_lessons_paid">
                                            </mat-form-field>
                                        </div>

                                        <div class="col-6 d-none">
                                            <mat-form-field appearance="fill" class="full-width-field">
                                                <mat-label>Lesson Available</mat-label>
                                                <input readonly name="field_lesson_available" matInput formControlName="field_lesson_available">
                                            </mat-form-field>
                                        </div>
                                </div>
                            </div>

                            <div *ngIf="studentBalanceWarning" class="col-12 mb-2 mt-2 text-center text-danger alert alert-warning">
                                <mat-badge *ngIf="studentBalanceWarning" color="warn" [matBadge]="'!'" matBadgeSize="small" matBadgeOverlap="false" class="balance-warning-badge">
                                    {{ studentBalanceWarning }}
                                </mat-badge>
                            </div>

                            <div *ngIf="deCategoryPriceWarning" class="col-12 mb-2 mt-2 text-center text-danger alert alert-warning">
                                <mat-badge *ngIf="deCategoryPriceWarning" color="warn" [matBadge]="'!'" matBadgeSize="small" matBadgeOverlap="false" class="balance-warning-badge">
                                    {{ deCategoryPriceWarning }}
                                </mat-badge>
                            </div>

                            <div class="container mb-2">
                                <div class="row">
                                    <div style="border: 1px solid rgb(204, 204, 204); padding: 13px; border-radius: 10px;">
                                        <h3>Scheduled Payments</h3>
                                        <div class="row mb-3">
                                            <mat-radio-group [ngModel]="this._frmValues?.field_payments_structure" class="row" formControlName="field_payments_structure" aria-label="Select an option">
                                                <div class="col-3">
                                                    <mat-radio-button (click)="paid_in_full()" value="paid_in_full">Pay In Full</mat-radio-button>
                                                </div>
                                                <div class="col-3">
                                                    <mat-radio-button (click)="use_installments()" value="installments">Installments</mat-radio-button>
                                                </div>
                                            </mat-radio-group>

                                            <div *ngIf="is_use_installments && (this.actionType == 'create' || this.actionType == 'editEnrollment' || this.actionType == 'edit')" class="d-inline mb-1 mt-1">
                                                <div class="mb-2 mt-2">
                                                    <div class="btn-group me-5" role="group">
                                                        <button type="button" class="btn btn-outline-primary"
                                                            (click)="enrollmentForm.get('field_payments_structure_type').setValue('weekly')"
                                                            [class.active]="enrollmentForm.get('field_payments_structure_type').value == 'weekly'">
                                                            Weekly
                                                        </button>
                                                        <button type="button" class="btn btn-outline-primary"
                                                            (click)="enrollmentForm.get('field_payments_structure_type').setValue('monthly')"
                                                            [class.active]="enrollmentForm.get('field_payments_structure_type').value == 'monthly'">
                                                            Monthly
                                                        </button>
                                                        <button type="button" class="btn btn-outline-primary"
                                                            (click)="enrollmentForm.get('field_payments_structure_type').setValue('by_the_lesson')"
                                                            [class.active]="enrollmentForm.get('field_payments_structure_type').value == 'by_the_lesson'">
                                                            By The Lesson
                                                        </button>
                                                    </div>
                                                    <span>
                                                        <!-- <a mat-button class="ms-1" type="button" (click)="calcScheduledPayments()">Generate Payment Plan</a> -->
                                                        <button type="button" class="btn btn-primary ms-auto" (click)="calcScheduledPayments()">
                                                            Generate Payment Plan
                                                        </button>
                                                    </span>
                                                </div>

                                                <div>
                                                    <ng-container [ngSwitch]="enrollmentForm.get('field_payments_structure_type').value">
                                                        <span *ngSwitchCase="'weekly'">Separate into</span>
                                                        <span *ngSwitchCase="'monthly'">Separate into</span>
                                                        <span *ngSwitchCase="'by_the_lesson'">Payments due every </span>
                                                        <span *ngSwitchDefault></span>
                                                    </ng-container>
                                                    <mat-form-field [ngClass]="{ 'd-none' : enrollmentForm.get('field_payments_structure_type').value == null}" style="width:50px" class="ms-1 me-1">
                                                        <input min="0" formControlName="interval" matInput type="number">
                                                    </mat-form-field>
                                                    <ng-container [ngSwitch]="enrollmentForm.get('field_payments_structure_type').value">
                                                        <span *ngSwitchCase="'weekly'">payments</span>
                                                        <span *ngSwitchCase="'monthly'">payments</span>
                                                        <span *ngSwitchCase="'by_the_lesson'">lessons</span>
                                                        <span *ngSwitchDefault></span>
                                                    </ng-container>
                                                    <span [ngClass]="{ 'd-none': interval != 'monthly' }">
                                                        payments months on the
                                                        <mat-form-field class="ms-1" style="width:180px">
                                                            <mat-label>Select Day</mat-label>
                                                            <mat-select
                                                            matInput
                                                            formControlName="field_recurrent_month_day">
                                                                <mat-option>None</mat-option>
                                                                <mat-option
                                                                    *ngFor="let element of this._fieldsService.dayDropdownSimple"
                                                                    [value]="element.value.toString()">
                                                                    {{ element.name }}</mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </span>
                                                    <span [ngClass]="">
                                                        <ng-container [ngSwitch]="enrollmentForm.get('field_payments_structure_type').value">
                                                            <span *ngSwitchCase="'weekly'"> starting on the</span>
                                                            <span *ngSwitchCase="'monthly'"> starting on the</span>
                                                            <span *ngSwitchCase="'by_the_lesson'">. Start on</span>
                                                            <span *ngSwitchDefault></span>
                                                        </ng-container>
                                                        <mat-form-field [ngClass]="{ 'd-none' : enrollmentForm.get('field_payments_structure_type').value == null}" class="ms-1" style="width:130px">
                                                            <mat-label>Select Day</mat-label>
                                                            <input
                                                            formControlName="field_recurrent_month_day"
                                                            matInput
                                                            [ngxMatDatetimePicker]="pickerRecurrentMonthDay"
                                                            placeholder="">
                                                            <mat-datepicker-toggle matSuffix [for]="pickerRecurrentMonthDay">
                                                            </mat-datepicker-toggle>
                                                            <ngx-mat-datetime-picker #pickerRecurrentMonthDay
                                                                [hideTime]="true"
                                                                [enableMeridian]="true"
                                                                [showSpinners]="true"
                                                                [showSeconds]="false"
                                                                [stepHour]="1" [stepMinute]="15"
                                                                [touchUi]="false">
                                                            </ngx-mat-datetime-picker>
                                                        </mat-form-field>
                                                        <mat-icon
                                                            [ngClass]="{
                                                                'd-none': !(enrollmentForm.get('field_payments_structure_type').value == 'monthly' && enrollmentForm.get('field_payments_structure_type').value != '')
                                                            }"
                                                            class="ms-2 cursor-pointer"
                                                            (click)="paymentPlanGeneratorIncreaseStep(i)"
                                                            aria-hidden="false"
                                                            aria-label="trash">add_box</mat-icon>
                                                    </span>
                                                </div>

                                                <div>
                                                    <ng-container *ngIf="enrollmentForm.get('payment_builder_date_step').controls">
                                                        <div formArrayName="payment_builder_date_step">
                                                            <div *ngFor="let instructorControl of enrollmentForm.get('payment_builder_date_step').controls; let i = index">
                                                                <div [formGroupName]="i" class="row">

                                                                    <div>
                                                                        <span [ngClass]="">
                                                                            Add additional payment on the
                                                                            <mat-form-field class="ms-1" style="width:125px">
                                                                                <mat-label>Select Day</mat-label>
                                                                                <mat-select
                                                                                matInput
                                                                                formControlName="field_recurrent_month_day">
                                                                                    <mat-option>None</mat-option>
                                                                                    <mat-option
                                                                                        *ngFor="let element of this._fieldsService.dayDropdown"
                                                                                        [value]="element.value">
                                                                                        {{ element.name }}</mat-option>
                                                                                </mat-select>
                                                                            </mat-form-field>
                                                                            <mat-icon
                                                                                *ngIf="this.actionType == 'create' || this.actionType == 'editEnrollment' || this.actionType == 'edit'"
                                                                                class="ms-2 cursor-pointer"
                                                                                (click)="paymentPlanGeneratorRemoveStep(i)"
                                                                                aria-hidden="false"
                                                                                aria-label="trash">cancel</mat-icon>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </div>

                                                <!-- Separate into
                                                <mat-form-field style="width:50px" class="ms-1 me-1">
                                                    <input min="0" formControlName="scheduled_payments_min_payment" matInput type="number">
                                                </mat-form-field> -->

                                                <div [ngClass]="{ 'd-none': enrollmentForm.get('field_payments_structure_type').value != 'weekly' }">
                                                    <div class="mt-1 mb-3">
                                                        <section class="example-section">
                                                            <!-- <mat-checkbox *ngFor="let day of daysOfWeek" ngModel name="{{day}}"
                                                                [value]="day"
                                                                class="example-margin">{{day}}</mat-checkbox> -->
                                                            <mat-checkbox formControlName="field_recurrent_weekday_su" name="field_recurrent_weekday_su" class="example-margin">Sun</mat-checkbox>
                                                            <mat-checkbox formControlName="field_recurrent_weekday_mo" name="field_recurrent_weekday_mo" class="example-margin">Mon</mat-checkbox>
                                                            <mat-checkbox formControlName="field_recurrent_weekday_tu" name="field_recurrent_weekday_tu" class="example-margin">Tue</mat-checkbox>
                                                            <mat-checkbox formControlName="field_recurrent_weekday_we" name="field_recurrent_weekday_we" class="example-margin">Wed</mat-checkbox>
                                                            <mat-checkbox formControlName="field_recurrent_weekday_th" name="field_recurrent_weekday_th" class="example-margin">Thu</mat-checkbox>
                                                            <mat-checkbox formControlName="field_recurrent_weekday_fr" name="field_recurrent_weekday_fr" class="example-margin">Fri</mat-checkbox>
                                                            <mat-checkbox formControlName="field_recurrent_weekday_sa" name="field_recurrent_weekday_sa" class="example-margin">Sat</mat-checkbox>
                                                        </section>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <ng-container *ngIf="enrollmentForm.get('field_scheduled_payments').controls">
                                            <div formArrayName="field_scheduled_payments">
                                                <div *ngFor="let paymentControl of (enrollmentForm.get('field_scheduled_payments')).controls; let i = index">
                                                    <div [formGroupName]="i" class="row">

                                                        <mat-divider class="mb-2" *ngIf="i > 0"></mat-divider>

                                                        <input class="d-none" formControlName="id" value="id" matInput>
                                                        <input class="d-none" formControlName="type" name="type" value="scheduled_payments" matInput>
                                                        <input class="d-none" formControlName="bundle" name="bundle" value="scheduled_payments" matInput>
                                                        <input class="d-none" formControlName="field_studio_reference" name="field_studio_reference" value="field_studio_reference" matInput>

                                                        <div class="col-4">
                                                            <mat-form-field class="full-width-field">
                                                                <mat-label>Payment Amount</mat-label>
                                                                <input min="0" type="number" formControlName="field_payment_amount" name="percentage" matInput (input)="calculateTax(i)">
                                                                <span matPrefix>$&nbsp;</span>
                                                            </mat-form-field>
                                                        </div>

                                                        <div [ngClass]="{'col-2': enrollmentForm.get('field_payments_structure_type').value == 'by_the_lesson', 'col-3': enrollmentForm.get('field_payments_structure_type').value != 'by_the_lesson'}">
                                                            <mat-form-field class="full-width-field">
                                                              <mat-label>Tax Amount</mat-label>
                                                              <input type="text" formControlName="field_tax_amount" readonly matInput>
                                                              <span matPrefix>$&nbsp;</span>
                                                            </mat-form-field>
                                                          </div>

                                                        <div class="col-4"
                                                        [ngClass]="{
                                                            'd-none': (enrollmentForm.get('field_payments_structure_type').value == 'by_the_lesson')
                                                        }">
                                                            <mat-form-field>
                                                                <mat-label>Payment Date</mat-label>
                                                                <input
                                                                formControlName="field_payment_date"
                                                                name="field_payment_date"
                                                                matInput
                                                                [ngxMatDatetimePicker]="pickerFieldPaymentDate"
                                                                placeholder="">
                                                                <mat-datepicker-toggle matSuffix [for]="pickerFieldPaymentDate">
                                                                </mat-datepicker-toggle>
                                                                <ngx-mat-datetime-picker #pickerFieldPaymentDate
                                                                    [hideTime]="true"
                                                                    [enableMeridian]="true"
                                                                    [showSpinners]="true"
                                                                    [showSeconds]="false"
                                                                    [stepHour]="1" [stepMinute]="15"
                                                                    [touchUi]="false">
                                                                </ngx-mat-datetime-picker>
                                                            </mat-form-field>
                                                        </div>
                                                        <div [ngClass]="{
                                                            'd-none': enrollmentForm.get('field_payments_structure').value != 'installments'
                                                            || enrollmentForm.get('field_payments_structure_type').value != 'by_the_lesson'
                                                            || (this.initActionType != 'create' && this.initActionType != 'edit' && this.initActionType != 'editEnrollment')
                                                            }" class="col-2">
                                                            <mat-form-field class="full-width-field">
                                                                <mat-label>Due At</mat-label>
                                                                <input min="0" type="number" formControlName="field_number_lesson_remaining" name="field_number_lesson_remaining" matInput>
                                                                <span matPrefix>#&nbsp;</span>
                                                            </mat-form-field>
                                                        </div>

                                                        <div class="col-4 d-none">
                                                            <mat-form-field class="full-width-field">
                                                                <mat-label>Amount Paid</mat-label>
                                                                <input min="0" type="number" formControlName="field_amount_paid" name="field_amount_paid" matInput>
                                                                <span matPrefix>$&nbsp;</span>
                                                            </mat-form-field>
                                                        </div>

                                                        <div class="col-3 d-none">
                                                            <mat-checkbox formControlName="field_paid_in_full" name="field_paid_in_full" class="example-margin">Paid In Full</mat-checkbox>
                                                        </div>

                                                        <!-- <div class="col-3">
                                                            <mat-checkbox [ngClass]="{
                                                                'd-none': i > 0
                                                            }" formControlName="field_down_payment" name="field_down_payment" class="example-margin">Initial <br />Investment</mat-checkbox>
                                                        </div> -->

                                                        <div class="col-1">
                                                            <div>
                                                                <mat-icon
                                                                    *ngIf="this.actionType == 'create'"
                                                                    class="cursor-pointer"
                                                                    (click)="scheduledPaymentRemove(i)"
                                                                    aria-hidden="false"
                                                                    aria-label="trash">cancel</mat-icon>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <!-- [disabled]="!enrollmentForm.get('field_scheduled_payments').valid" -->
                                        <button *ngIf="!is_pay_in_full && this.actionType == 'create'" type="button"
                                            mat-raised-button
                                            class="me-1"
                                            color="primary" (click)="scheduledPaymentIncrement()">
                                            Add Scheduled Payment
                                        </button>
                                    </div>
                                </div>
                            </div>



                                <div class="container">
                                    <div class="row">

                                    <!-- <div class="col-12">
                                        <mat-form-field class="full-width-field">
                                            <mat-label>Expiration Date</mat-label>
                                            <input
                                                matInput
                                                [matDatepicker]="pickerFieldExpirationDate"
                                                required
                                                name="field_expiration_date"
                                                formControlName="field_expiration_date"
                                                >
                                            <mat-datepicker-toggle matSuffix [for]="pickerFieldExpirationDate"></mat-datepicker-toggle>
                                            <mat-datepicker #pickerFieldExpirationDate></mat-datepicker>
                                        </mat-form-field>
                                    </div> -->

                                    <div class="col-12">
                                        <mat-form-field>
                                        <mat-label>Expiration Date</mat-label>
                                        <input
                                        formControlName="field_expiration_date"
                                        name="field_expiration_date"
                                        matInput
                                        [ngxMatDatetimePicker]="pickerFieldExpirationDate"
                                        placeholder="">
                                        <mat-datepicker-toggle matSuffix [for]="pickerFieldExpirationDate">
                                        </mat-datepicker-toggle>
                                        <ngx-mat-datetime-picker #pickerFieldExpirationDate
                                            [hideTime]="true"
                                            [enableMeridian]="true"
                                            [showSpinners]="true"
                                            [showSeconds]="false"
                                            [stepHour]="1" [stepMinute]="15"
                                            [touchUi]="false">
                                        </ngx-mat-datetime-picker>
                                        </mat-form-field>
                                    </div>

                                    <!-- {{this.enrollmentForm.value | json}} -->

                                    <!-- Executive Dropdown -->
                                    <div class="col-12">
                                        <mat-form-field class="full-width-field">
                                            <mat-label>Executive</mat-label>
                                            <mat-select required matInput formControlName="field_executive" name="field_executive">
                                                <mat-option>None</mat-option>
                                                <ng-container *ngFor="let element of _fieldsService.instructorData(); let i = index">
                                                    <mat-option *ngIf="element.status !== '0' || (element.status === '0' && element.uid === field_executive)"
                                                                [value]="element.uid">
                                                        {{ element.value }}
                                                        <ng-container *ngIf="element.status === '0' && element.uid === field_executive"></ng-container>
                                                    </mat-option>
                                                </ng-container>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <!-- Junior Executive Dropdown -->
                                    <!-- <div class="col-4">
                                        <mat-form-field class="full-width-field">
                                            <mat-label>Junior Executive</mat-label>
                                            <mat-select required matInput formControlName="field_junior_executive" name="field_junior_executive">
                                                <mat-option>None</mat-option>
                                                <mat-option *ngFor="let element of this._fieldsService.instructorsJuniorExecutive();"
                                                    [value]="element.uid">
                                                    {{ element.value }}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div> -->

                                    <!-- Instructor List -->
                                    <!-- <app-display-instructors
                                    [field_students]="field_student"
                                    [studentUpdateLoad]="studentUpdateLoad"
                                    [studentRemove]="studentRemove"></app-display-instructors> -->

                                    <div formArrayName="field_instructor_percentages" style="border: 1px solid rgb(204, 204, 204); padding: 13px; border-radius: 10px;">
                                        <h3>Instructor Percentage</h3>
                                        <!-- {{this._frmValues?.field_instructor_percentages | json}} -->
                                        <ng-container *ngIf="enrollmentForm.get('field_instructor_percentages').controls">
                                            <div *ngFor="let instructorControl of (enrollmentForm.get('field_instructor_percentages'))?.controls; let i = index">
                                                <div [formGroupName]="i" class="row">

                                                    <input class="d-none" formControlName="id" value="id" matInput>
                                                    <input class="d-none" formControlName="type" value="instructor_percentages" matInput>
                                                    <input class="d-none" formControlName="bundle" value="instructor_percentages" matInput>

                                                    <div class="col-8">
                                                        <mat-form-field class="full-width-field">
                                                            <mat-label>Instructor</mat-label>
                                                            <mat-select required matInput formControlName="field_instructor" name="instructor">
                                                                <mat-option>None</mat-option>
                                                                <ng-container *ngFor="let element of _fieldsService.instructorData(); let i = index">
                                                                    <mat-option *ngIf="element.status !== '0' || (element.status === '0' && element.uid === field_instructor)"
                                                                                [value]="element.uid">
                                                                        {{ element.value }}
                                                                        <ng-container *ngIf="element.status === '0' && element.uid === field_instructor"></ng-container>
                                                                    </mat-option>
                                                                </ng-container>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>

                                                    <div class="col-3">
                                                        <mat-form-field class="full-width-field">
                                                            <mat-label>Percentage</mat-label>
                                                            <input (ngModelChange)="fromPercentCalcTotal()" min="0" max="100" type="number" formControlName="field_percentage" name="field_percentage" matInput>
                                                            <span matSuffix>&nbsp;%</span>
                                                        </mat-form-field>
                                                    </div>

                                                    <div class="col-3 d-none">
                                                        <mat-form-field class="full-width-field">
                                                            <mat-label>Amount</mat-label>
                                                            <input min="0" type="number" formControlName="field_amount" name="field_amount" matInput>
                                                        </mat-form-field>
                                                    </div>

                                                    <div class="col-1">
                                                        <div>
                                                            <mat-icon
                                                                class="me-2 mt-2"
                                                                *ngIf="this.actionType == 'create'"
                                                                (click)="instructorPercentageRemove(i)"
                                                                aria-hidden="false"
                                                                aria-label="trash">cancel</mat-icon>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <!-- [disabled]="!enrollmentForm.get('field_instructor_percentages').valid" -->
                                        <button *ngIf="!instructorPercentageEditMode" type="button"
                                            mat-raised-button
                                            class="me-1"
                                            color="primary" (click)="instructorPercentageIncrement()">
                                            Add Instructor Percentage
                                        </button>

                                    </div>
                                    <div *ngIf="actionType === 'create' && enrollmentForm.get('field_instructor_percentages').value.length === 0" class="text-danger">
                                        * Please add at least one instructor percentage.
                                    </div>

                                    <div class="col-12">
                                        <button *ngIf="instructorPercentageEditMode" type="button"
                                            mat-raised-button
                                            [disabled]="!enrollmentForm.get('field_instructor_percentages').valid"
                                            class="me-1"
                                            color="primary" (click)="studentUpdateSubmit(i, instructor.value)">
                                            Edit Instructor Percentage
                                        </button>
                                    </div>

                                    <div class="col-12 mt-1">
                                        <mat-form-field class="full-width-field">
                                            <mat-label>Notes</mat-label>
                                            <textarea formControlName="field_notes" [ngModel]="this._frmValues?.field_notes" name="field_notes"
                                                matInput placeholder=""></textarea>
                                        </mat-form-field>
                                    </div>

                                    <!-- <div class="col-6">
                                        <mat-form-field class="full-width-field">
                                            <mat-label>Interval Unit </mat-label>
                                            <mat-select matInput formControlName="field_interval_unit" name="field_interval_unit">
                                                <mat-option>None</mat-option>
                                                <mat-option value="Day">Days</mat-option>
                                                <mat-option value="Weeks">Weeks</mat-option>
                                                <mat-option value="Months">Months</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div> -->

                                    <!-- <div class="col-6">
                                        <mat-form-field class="full-width-field">
                                            <mat-label>Enrollment Status</mat-label>
                                            <mat-select matInput formControlName="field_enrollment_status" name="field_enrollment_status">
                                                <mat-option value="_none">None</mat-option>
                                                <mat-option value="Open">Open</mat-option>
                                                <mat-option value="Close">Closed</mat-option>
                                                <mat-option value="Dropped">Dropped</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div> -->

                                    <!-- <div class="col-12">
                                        <mat-form-field class="full-width-field">
                                            <mat-label>Next Scheduled Payment</mat-label>
                                            <input
                                            matInput
                                            [matDatepicker]="pickerFieldNextScheduledPayment"
                                            name="field_next_scheduled_payment"
                                            formControlName="field_next_scheduled_payment"
                                            >
                                            <mat-datepicker-toggle matSuffix [for]="pickerFieldNextScheduledPayment"></mat-datepicker-toggle>
                                            <mat-datepicker #pickerFieldNextScheduledPayment></mat-datepicker>
                                        </mat-form-field>
                                    </div> -->

                                    <div class="col-12 d-none">
                                        <mat-checkbox
                                        formControlName="field_visibility"
                                        name="field_visibility"
                                        class="">Hidden</mat-checkbox>

                                        <mat-checkbox
                                        formControlName="field_draft"
                                        name="field_draft"
                                        class="">Draft</mat-checkbox>

                                    </div>

                                    <div class="mt-4">
                                      <!-- Button for creating enrollment. -->
                                      <button
                                      *ngIf="this.initActionType == 'create'"
                                      [disabled]="!enrollmentForm.valid || !enrollmentForm.get('field_instructor_percentages').value.length > 0 || studentBalanceWarning || deCategoryPriceWarning" (click)="onSubmitEnrollment(f, 'create')" type="button" mat-button>Next</button>

                                      <!-- Button for editing enrollment. -->
                                      <button
                                      *ngIf="this.initActionType == 'editEnrollment'"
                                       [disabled]="!enrollmentForm.valid || deCategoryPriceWarning" (click)="onSubmitEnrollment(f, 'edit')" type="button" mat-button>Save</button>
                                    </div>


                                    <!-- <p>Form value: {{ enrollmentForm.value | json }}</p>
                                    <p>Form status: {{ enrollmentForm.status | json }}</p>

                                    {{getFormErrors(this.enrollmentForm) | json}} -->

                                </div>
                            </div>
                        </form>
                    </mat-step>

                    <mat-step [stepControl]="agreementFormGroup" label="Agreement">
                      <form [formGroup]="agreementFormGroup">

                        <input class="d-none" formControlName="field_nothing">
                        <!-- <input formControlName="firstName"> -->

                        <div id="enrollment-agreement-new">
                            <p class="text-center"><img style="max-width:350px;" src="assets/images/AMDCBlackHorizontalJPG.jpg" /></p>
                            <!-- Student Enrollment Agreement Section -->
                            <div class="container border p-3 bg-white text-dark">
                              <h4 class="fw-bold text-center">STUDENT ENROLLMENT AGREEMENT</h4>
                              <div class="d-flex justify-content-between">
                                <div>
                                  <p class="mb-1">{{ this.studioInformationConfig?.field_studio_address?.[0]?.address_line1 }},
                                    {{ this.studioInformationConfig?.field_studio_address?.[0]?.locality }},
                                    {{ this.studioInformationConfig?.field_studio_address?.[0]?.administrative_area }}
                                    {{ this.studioInformationConfig?.field_studio_address?.[0]?.postal_code }}</p>
                                  <p class="mb-1">{{ this.studioInformationConfig?.field_studio_phone_number?.[0]?.value }}</p>
                                  <p class="mb-1">{{ this.studioInformationConfig?.field_studio_email?.[0]?.value }}</p>
                                </div>
                                <div class="text-end">
                                  <p class="fw-bold mb-1">AGREEMENT DATE: <span class="text-success">{{ this.entityData?.['field_sale_date'] | date }}</span></p>
                                  <p class="fw-bold">TOTAL FEES: <span class="text-success">${{ calculateTotalTuition() | number:'1.2-2' }}</span></p>
                                </div>
                              </div>
                            </div>

                            <h4>1. PARTIES TO THE AGREEMENT</h4>
                            <div class="row mb-3">
                                <div class="col-12">
                                    <div class="mb-3">
                                        <p class="d-flex">
                                            <span class="fw-bold me-2">STUDIO OWNER INFORMATION:</span>
                                            <span class="flex-grow-1 mx-1 border-bottom" style="min-width: 250px;">{{ this.studioInformationConfig?.field_studio_display_name?.[0].value }}</span>
                                            <span>("us")</span>
                                        </p>
                                    </div>

                                    <div class="mb-3">
                                        <p class="d-flex">
                                            <span class="fw-bold me-2">STUDIO ADDRESS:</span>
                                            <span class="flex-grow-1 mx-1 border-bottom" style="min-width: 250px;">{{ this.studioInformationConfig?.field_studio_address?.[0]?.address_line1 }},
                                        {{ this.studioInformationConfig?.field_studio_address?.[0]?.locality }},
                                        {{ this.studioInformationConfig?.field_studio_address?.[0]?.administrative_area }}
                                            {{ this.studioInformationConfig?.field_studio_address?.[0]?.postal_code }}</span>
                                            <span>("the Studio")</span>
                                    </p>
                                </div>

                                    <h6 class="fw-bold">Buyer Information:</h6>
                                    <div class="border p-2">
                                        <table class="table table-borderless mb-0">
                                            <tr>
                                                <td class="fw-bold" style="width: 10%">Name:</td>
                                                <td style="width: 40%">{{ entityData?.['field_student']?.['title'] || 'Not specified' }} ("you")</td>
                                                <td class="fw-bold" style="width: 15%">Email Address:</td>
                                                <td style="width: 35%">{{ this.studentAccountEntityData?.field_contacts?.field_email || 'Not specified' }}</td>
                                            </tr>
                                            <tr>
                                                <td class="fw-bold">Cell phone#:</td>
                                                <td>{{ this.studentAccountEntityData?.field_contacts?.field_cell_phone || 'Not specified' }}</td>
                                                <td class="fw-bold">Address:</td>
                                                <td>
                                                    <ng-container *ngIf="this.studentAccountEntityData?.field_contacts?.field_address">
                                                        {{ this.studentAccountEntityData?.field_contacts?.field_address?.address_line1 || '' }}
                                                        <ng-container *ngIf="this.studentAccountEntityData?.field_contacts?.field_address?.locality">
                                                            , {{ this.studentAccountEntityData?.field_contacts?.field_address?.locality }}
                                                        </ng-container>
                                                        <ng-container *ngIf="this.studentAccountEntityData?.field_contacts?.field_address?.administrative_area">
                                                            , {{ this.studentAccountEntityData?.field_contacts?.field_address?.administrative_area }}
                                                        </ng-container>
                                                        <ng-container *ngIf="this.studentAccountEntityData?.field_contacts?.field_address?.postal_code">
                                                            {{ this.studentAccountEntityData?.field_contacts?.field_address?.postal_code }}
                                                        </ng-container>
                                                    </ng-container>
                                                    <ng-container *ngIf="!this.studentAccountEntityData?.field_contacts?.field_address">
                                                        Not specified
                                                    </ng-container>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <h4>2. SUMMARY OF CHARGES</h4>

                            <div class="row mb-3">
                                <div
                                    class="col-12 d-flex justify-content-between align-items-center">
                                    <span class="fw-semibold">Enrollment Type:</span>
                                    <span>
                                        <input type="checkbox" id="enrollmentOriginal"
                                            class="form-check-input me-1" [disabled]="true" [checked]="entityData?.field_category == '50'">
                                        <label for="enrollmentOriginal"
                                            class="me-2">Original</label>
                                        <input type="checkbox" id="enrollmentExtension"
                                            class="form-check-input me-1" [disabled]="true" [checked]="entityData?.field_category == '51'">
                                        <label for="enrollmentExtension"
                                            class="me-2">Extension</label>
                                        <input type="checkbox" id="enrollmentRenewal"
                                            class="form-check-input me-1" [disabled]="true" [checked]="entityData?.field_category == '65'">
                                        <label for="enrollmentRenewal"
                                            class="me-2">Renewal</label>
                                        <input type="checkbox" id="enrollmentService"
                                            class="form-check-input me-1" [disabled]="true" [checked]="entityData?.field_category == '5'">
                                        <label for="enrollmentService">Service</label>
                                    </span>
                                </div>
                            </div>

                            <div class="row mb-3">
                                <div
                                    class="col-12 d-flex justify-content-between align-items-center">
                                    <span class="fw-semibold">Lesson Type:</span>
                                    <span>
                                        <input type="checkbox" id="lessonPersonal"
                                            class="form-check-input me-1" [disabled]="true" [checked]="true">
                                        <label for="lessonPersonal" class="me-2">Personal
                                            Lessons</label>
                                        <input type="checkbox" id="lessonClass"
                                            class="form-check-input me-1" [disabled]="true" [checked]="false">
                                        <label for="lessonClass" class="me-2">Class
                                            Lessons</label>
                                        <input type="checkbox" id="lessonPractice"
                                            class="form-check-input me-1" [disabled]="true" [checked]="false">
                                        <label for="lessonPractice" class="me-2">Practice
                                            Lessons</label>
                                        <input type="checkbox" id="lessonVideo"
                                            class="form-check-input me-1" [disabled]="true" [checked]="false">
                                        <label for="lessonVideo">Video Studies</label>
                                    </span>
                                </div>
                            </div>

                            <div class="mb-3 d-flex justify-content-between align-items-center">
                                <span class="fw-semibold">Tuition Per Lesson:</span>
                                <span>
                                    $ <span class="border-bottom px-2 mx-1">_______</span> per hour &nbsp;
                                    $ <span class="border-bottom px-2 mx-1">_______</span> per hour &nbsp;
                                    $ <span class="border-bottom px-2 mx-1">_______</span> per hour &nbsp;
                                    $ <span class="border-bottom px-2 mx-1">_______</span> per hour
                                </span>
                            </div>

                            <div class="mb-3 d-flex">
                                <span class="fw-semibold me-2">Number of Lessons Purchased:</span>
                                <span class="flex-grow-1 border-bottom">{{ entityData?.['field_enrollment_lesson_count'] }}</span>
                            </div>

                            <div class="mb-3 d-flex">
                                <span class="fw-semibold me-2">Total Tuition/Cost:</span>
                                <span class="flex-grow-1 border-bottom">${{ getOriginalPackageCost() }}</span>
                            </div>

                            <div class="mb-3 d-flex">
                                <span class="fw-semibold me-2">Other Services:</span>
                                <span class="flex-grow-1 border-bottom"></span>
                            </div>

                            <div class="mb-3 d-flex">
                                <span class="fw-semibold me-2">Discount:</span>
                                <span class="flex-grow-1 border-bottom">
                                    <ng-container *ngIf="this.entityData?.field_discount_type == 'flat'">
                                        ${{ entityData?.field_discount ? this.entityData?.field_discount : '0' }}
                                    </ng-container>
                                    <ng-container *ngIf="this.entityData?.field_discount_type == 'percentage'">
                                        {{ entityData?.field_discount ? this.entityData?.field_discount : '0' }}%
                                    </ng-container>
                                    <ng-container *ngIf="this.entityData?.field_discount_type != 'percentage' && this.entityData?.field_discount_type != 'flat'">
                                        ${{ entityData?.field_discount ? this.entityData?.field_discount : '0' }}
                                    </ng-container>
                                </span>
                            </div>

                            <div class="mb-3 d-flex">
                                <span class="fw-semibold me-2">Sales Tax:</span>
                                <span class="flex-grow-1 border-bottom">{{ entityData?.field_tax_percentage ? this.entityData?.field_tax_percentage : '0' }}%</span>
                            </div>

                            <div class="mb-3 d-flex">
                                <span class="fw-bold me-2">Total Fees Due & Payable <span class="text-primary">By</span> You ("Fees"):</span>
                                <span class="flex-grow-1 border-bottom">${{ entityData?.field_enrollment_total_price ? this.entityData?.field_enrollment_total_price : '0' }}</span>
                            </div>

                            <div *ngIf="enrollmentForm?.get('field_payments_structure')?.value !== 'paid_in_full'">
                                <h4>3. Lending Disclosures for Budget Courses or Studio Payment Programs</h4>
                                <div class="row mb-3">
                                    <div class="col-12">
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <table class="table table-bordered">
                                                        <tr>
                                                            <td>1. Total Fees under this agreement...................</td>
                                                            <td>${{ calculateTotalTuition() | number:'1.2-2' }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>2. Outstanding Balance of Previous Agreement...</td>
                                                            <td>${{ entityData?.field_outstanding_balance_pc ? entityData?.field_outstanding_balance_pc : '0' | number:'1.2-2' }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>3. Unearned Finance Charge.....................</td>
                                                            <td>${{ entityData?.field_unearned_finance_charge ? entityData?.field_unearned_finance_charge : '0' | number:'1.2-2' }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>4. Previous Balance to be Refinanced (2-3), if any..</td>
                                                            <td>${{ (entityData?.field_outstanding_balance_pc - (entityData?.field_unearned_finance_charge || 0)) | number:'1.2-2' }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>5. Consolidated Cash Price (1+4).......................</td>
                                                            <td>${{ (calculateTotalTuition() + (entityData?.field_outstanding_balance_pc - (entityData?.field_unearned_finance_charge || 0))) | number:'1.2-2' }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>6. Cash Down Payment.............................</td>
                                                            <td>${{ entityData?.field_payment_amount ? entityData?.field_payment_amount : '0' | number:'1.2-2' }}</td>
                                                        </tr>
                                                    </table>

                                                    <div class="p-3 mb-2" style="border: 1px solid #000; background-color: #f2f2f2;">
                                                        <strong>AMOUNT FINANCED:</strong><br>
                                                        The amount of credit that is provided to you or on your behalf...................................
                                                        <span style="float: right;">${{ entityData?.field_enrollment_total_price }}</span>
                                                    </div>

                                                    <div class="p-3 mb-2" style="border: 1px solid #000; background-color: #f2f2f2;">
                                                        <strong>FINANCE CHARGE:</strong><br>
                                                        The dollar amount the credit will cost you......
                                                        <span style="float: right;">${{ entityData?.field_finance_charge ? entityData?.field_finance_charge : '0' }}</span>
                                                    </div>

                                                    <div class="p-3 mb-2" style="border: 1px solid #000; background-color: #f2f2f2;">
                                                        <strong>TOTAL OF PAYMENTS</strong><br>
                                                        The amount you will have paid after you have made all payments as scheduled........................
                                                        <span style="float: right;">${{ calculateTotalTuition() | number:'1.2-2' }}</span>
                                                    </div>

                                                    <div class="p-3 mb-2" style="border: 1px solid #000; background-color: #f2f2f2;">
                                                        <strong>TOTAL SALE PRICE</strong><br>
                                                        The total cost of your purchase on credit including the down payment of ${{ entityData?.field_payment_amount ? entityData?.field_payment_amount : '0' | number:'1.2-2' }}...........
                                                        <span style="float: right;">${{ calculateTotalTuition() | number:'1.2-2' }}</span>
                                                    </div>

                                                    <div class="p-3 mb-2" style="border: 1px solid #000; background-color: #f2f2f2;">
                                                        <strong>ANNUAL PERCENTAGE RATE</strong><br>
                                                        The cost of your credit as a yearly rate............
                                                        <span style="float: right;">{{ entityData?.field_finance_apr ? entityData?.field_finance_apr : '0' }}%</span>
                                                    </div>
                                                </div>

                                                <div class="col-md-6">
                                                    <strong>YOUR PAYMENT SCHEDULE WILL BE:</strong>
                                                    <table class="table table-bordered mt-2">
                                                        <tr>
                                                            <th class="text-center">No. of Payments</th>
                                                            <th class="text-center">Amount of Payments</th>
                                                            <th class="text-center">{{ entityData?.field_payments_structure_type === 'weekly' ? 'Weekly' : 'Monthly' }} Beginning</th>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-center">{{ (entityData?.field_scheduled_payments?.length) || (entityData?.field_scheduled_payments?.['0'] ? 1 : 0) }}</td>
                                                            <td class="text-center">${{ (entityData?.field_scheduled_payments?.[0]?.['0']?.field_payment_amount) || (entityData?.field_scheduled_payments?.['0']?.field_payment_amount) || '0' | number:'1.2-2' }}</td>
                                                            <td class="text-center">{{ entityData?.field_next_scheduled_payment || (entityData?.field_scheduled_payments?.[0]?.['0']?.field_payment_date | date) || (entityData?.field_scheduled_payments?.['0']?.field_payment_date | date) || 'Not specified' }}</td>
                                                        </tr>
                                                    </table>
                                                    <p>and on the same {{ entityData?.field_payments_structure_type === 'weekly' ? 'day each week' : 'date each month' }} thereafter until paid in full.</p>

                                                    <div class="mt-4">
                                                        <p class="mt-3">
                                                            NOTICE TO YOU: DO NOT SIGN THIS AGREEMENT BEFORE YOU READ IT OR IF IT CONTAINS ANY BLANK SPACES INTENDED FOR THE AGREED TERMS TO THE EXTENT OF THEN AVAILABLE INFORMATION ARE LEFT BLANK. YOU ARE ENTITLED TO A COPY OF THE AGREEMENT AT THE TIME YOU SIGN IT. KEEP THIS AGREEMENT TO PROTECT YOUR LEGAL RIGHT.
                                                        </p>

                                                        <div class="mt-3">
                                                            <p>If you pay off early, you:</p>
                                                            <div class="ms-4">
                                                                <div class="d-flex align-items-center mb-2">
                                                                    <div class="form-check me-2 mb-0">
                                                                        <input class="form-check-input" type="checkbox" id="penaltyYes">
                                                                        <label class="form-check-label" for="penaltyYes">may</label>
                                                                    </div>
                                                                    <div class="form-check me-2 mb-0">
                                                                        <input class="form-check-input" type="checkbox" id="penaltyNo" checked>
                                                                        <label class="form-check-label" for="penaltyNo" [checked]="!entityData?.field_early_payment_penalty">will not</label>
                                                                    </div>
                                                                    <span class="ms-4">have to pay a penalty</span>
                                                                </div>
                                                                <div class="d-flex align-items-center">
                                                                    <div class="form-check me-2 mb-0">
                                                                        <input class="form-check-input" type="checkbox" id="refundYes" [checked]="entityData?.field_early_payment_refund">
                                                                        <label class="form-check-label" for="refundYes">may</label>
                                                                    </div>
                                                                    <div class="form-check me-2 mb-0">
                                                                        <input class="form-check-input" type="checkbox" id="refundNo" [checked]="!entityData?.field_early_payment_refund">
                                                                        <label class="form-check-label" for="refundNo">will not</label>
                                                                    </div>
                                                                    <span class="ms-4">be entitled to a refund or part of the finance charge, under rule of 78, pro-rata or actuarial method, whichever, is applicable in your state.</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="conainer">
                                <div class="form-check mt-3">
                                    <input class="form-check-input" type="checkbox" id="termsCheck"
                                        [checked]="tosAccepted"
                                        (change)="updateTosAcceptedDate($event)">
                                    <label class="form-check-label" for="termsCheck">
                                        This agreement is subject to and will be governed by the Terms of Service, which are incorporated in this agreement by reference.
                                        By executing this agreement, you acknowledge that you have read the Terms of Service and agree to abide by them.
                                        All references to "this agreement" mean this agreement together with the attached <a class="cursor-pointer" (click)="printPage(true, false)">Terms of Service</a>.
                                        <!-- <span class="text-danger">*</span> -->
                                    </label>
                                </div>

                                <div *ngIf="tosAccepted" class="mt-2 ps-4 text-success fst-italic small">
                                    <i class="fas fa-check-circle me-1"></i> Terms of Service accepted on {{ (entityData?.field_tos_accepted_date || enrollmentForm.get('field_tos_accepted_date')?.value) | date:'MMM d, y, h:mm a':'local' }}
                                </div>

                                <div *ngIf="!tosAccepted && (detectSignatureStudio || detectSignatureStudent)" class="mt-2 ps-4 text-danger fst-italic small">
                                    <i class="fas fa-exclamation-circle me-1"></i> You must accept the Terms of Service to proceed
                                </div>
                            </div>

                            <div class="row mt-5 mb-4">
                                <div class="col-12">
                                    <h4 class="fw-bold mb-3 pb-2 border-bottom">Signatures</h4>
                                    <div class="row g-4">
                                        <div class="col-6">
                                            <div class="signature-box p-3 border rounded">
                                                <p class="fw-bold mb-2">Studio Owner:</p>
                                                <div class="signature-container bg-light p-1 border rounded" style="min-height: 125px;">
                                                    <canvas width="341px" height="125px" class="signature-canvas" #SignatureStudio></canvas>
                                                </div>
                                                <div class="d-flex justify-content-between align-items-center mt-2">
                                                    <button class="mt-1 display-none" type="button" mat-stroked-button (click)="signatureClearStudio()">Clear</button>
                                                    <p class="mb-0 text-end"><span class="fw-bold">Date:</span> {{ this.entityData?.['field_sale_date'] | date }}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="signature-box p-3 border rounded">
                                                <p class="fw-bold mb-2">Buyer Signature:</p>
                                                <div class="signature-container bg-light p-1 border rounded" style="min-height: 125px;">
                                                    <canvas width="341px" height="125px" class="signature-canvas" #SignatureStudent></canvas>
                                                </div>
                                                <div class="d-flex justify-content-between align-items-center mt-2">
                                                    <button class="mt-1 display-none" type="button" mat-stroked-button (click)="signatureClearStudent()">Clear</button>
                                                    <p class="mb-0 text-end"><span class="fw-bold">Date:</span> {{ this.entityData?.['field_sale_date'] | date }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-4">
                                        <div class="col-12">
                                            <div class="parent-signature p-3 border rounded bg-light">
                                                <p class="fw-bold mb-2">Parent/Guardian Signature (if applicable):</p>
                                                <div class="signature-container bg-light p-1 border rounded" style="min-height: 125px;">
                                                    <canvas width="682px" height="125px" class="signature-canvas" #SignatureParent></canvas>
                                                </div>
                                                <div class="d-flex justify-content-between align-items-center mt-2">
                                                    <button class="mt-1" [class.display-none]="!detectSignatureParent" type="button" mat-stroked-button (click)="signatureClearParent()">Clear</button>
                                                    <p class="mb-0 text-end"><span class="fw-bold">Date:</span> {{ this.entityData?.['field_sale_date'] | date }}</p>
                                                </div>
                                                <p class="small text-muted mt-2 mb-0">On behalf of my minor child and myself, I agree to assume and comply with all the provisions of this agreement, including the provision titled "Participation & Assumption of Risk" and "Indemnity." I promise to pay any financial obligation that my minor child does not pay for any reason.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>

                        <div id="enrollment-agreement-backpage" class="page2">
                            <div [innerHTML]="backpageHtmlContent"></div>
                        </div>

                        <div class="row mt-3">
                            <div class="col-6">
                                <button (click)="printPage(false, true)"  class="me-1" type="button" mat-stroked-button color="primary">
                                    Print
                                </button>
                                <button class="me-1" type="button" mat-stroked-button color="primary" (click)="sendEmailNewEnrollment(this.entityData?.id)">
                                    Email
                                </button>
                            </div>
                            <div class="col-6">
                                <div class="d-flex justify-content-end">
                                    <button
                                    [disabled]="!detectSignatureStudio || !detectSignatureStudent || !tosAccepted"
                                    class="me-1"
                                    type="button"
                                    mat-stroked-button
                                    (click)="onSubmitSignatures('save_and_add')"
                                    color="primary">
                                        Save and Add
                                    </button>
                                    <button
                                    [disabled]="!detectSignatureStudio || !detectSignatureStudent || !tosAccepted"
                                    class="me-1"
                                    type="button"
                                    mat-stroked-button
                                    (click)="onSubmitSignatures('save_and_pay')"
                                    color="primary">
                                        Save and Pay
                                    </button>
                                </div>
                                <!-- Validation message to show what's missing -->
                                <div class="col-12 mt-2" *ngIf="!detectSignatureStudio || !detectSignatureStudent || !tosAccepted">
                                    <div class="alert alert-warning">
                                        <strong>Please complete the following before proceeding:</strong>
                                        <ul class="mb-0 mt-2">
                                            <li *ngIf="!detectSignatureStudio">Studio signature is required</li>
                                            <li *ngIf="!detectSignatureStudent">Student signature is required</li>
                                            <li *ngIf="!tosAccepted">Terms of Service must be accepted</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="mt-3">
                          <button class="me-1" mat-button matStepperPrevious>Back</button>
                          <button mat-button matStepperNext>Next</button>
                        </div> -->
                      </form>
                    </mat-step>

                    <mat-step [stepControl]="paymentForm" label="Payment">
                        <app-payments-entity-form
                        [enrollmentData]="enrollmentEntityData"
                        [studentData]="studentAccountEntityData"
                        (paymentSubmitted)="onPaymentSubmitted($event)"
                        [isNested]="true">
                      </app-payments-entity-form>
                      </mat-step>

                    <mat-step>
                      <ng-template matStepLabel>Done</ng-template>
                      <p>You are now done.</p>
                      <div>
                        <!-- 'field_student_account': this.enrollmentForm?.value?.field_student, -->
                        <button
                            class="me-1"
                            mat-button
                            (click)="openEntityComponent(EventLessonEntityComponent, 'events', 'lessons', 'create', '', {
                                'field_type': getFieldTypeBasedOnDepartment(this.enrollmentEntityData?.field_student?.field_student_department),
                                'field_student': [
                                    {
                                        'type': 'attendees',
                                        'title': this.enrollmentEntityData?.field_student?.title,
                                        'field_enrollment': this.enrollmentEntityData?.id,
                                        'field_status': '64',
                                        'field_students': this.enrollmentEntityData?.field_student?.field_contacts,
                                        'field_student_account': this.enrollmentEntityData?.field_student?.title + ' (' + this.enrollmentEntityData?.field_student?.id + ')',
                                        'bundle': 'attendance'
                                    },
                                ],
                                'field_instructor': this.enrollmentForm?.value?.field_instructor_percentages?.[0]?.field_instructor
                            })">
                            Schedule Appointment
                        </button>
                        <button mat-button (click)="this._dialog.closeAll(); openEntityComponent(EnrollmentEntityComponent, 'packages', 'enrollment', 'create', null)">Add Enrollment</button>
                      </div>
                    </mat-step>
                </mat-stepper>

            </mat-tab>

            <mat-tab label="Delete" [disabled]="this.initActionType == 'create'" *ngIf="this.actionType != 'create'">
                <app-error-message [message]=this.errorMessage></app-error-message>
                <app-entity-confirm-delete></app-entity-confirm-delete>
                <app-enrollment-lesson-list-view (dataCountEvent)='updateCount($event)' [enrollmentID]="this.enrollmentID"></app-enrollment-lesson-list-view>
            </mat-tab>

        </mat-tab-group>

    </div>
</div>

<div>
    <!-- <p> -->
    <!-- {{findInvalidControlsRecursive(this?.enrollmentForm) | json}} -->
    <!-- </p> -->
    <!-- {{this?.enrollmentForm?.value | json}} -->
    <!-- enrollmentForm.valid: {{enrollmentForm?.valid}} -->
    <!-- get why the form isn't valid. -->
    <!-- enrollmentForm.errors: {{enrollmentForm?.errors | json}} -->
</div>

<mat-dialog-actions
    [ngClass]="{
        'd-none': !(this.actionType === 'delete' || this.actionType === 'edit')
    }"
    align="end">
    <ng-container *ngIf="this.actionType === 'create'">
        <!-- <button type="button" (click)="onSubmit(f);" mat-raised-button color="accent">Save</button> -->
    </ng-container>
    <ng-container *ngIf="this.actionType === 'edit'">
        <ng-container *ngIf="this.initActionType == 'edit'">
            <button type="button" (click)="setFormMode('delete')" mat-raised-button color="accent">Delete</button>
            <button type="button" (click)="onSubmit(f);" mat-raised-button color="accent">Save</button>
        </ng-container>
        <ng-container *ngIf="this.initActionType === 'create'">
            <button type="button" (click)="closeDialog()" mat-raised-button color="accent">Cancel</button>
            <button type="button" (click)="onSubmit(f);" mat-raised-button color="accent">Save</button>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="this.actionType === 'delete'">
        <button (click)="closeDialog();" type="submit" mat-raised-button color="accent">Cancel</button>
        <button [disabled]="this.eventCount > 0" (click)="onDeleteSubmit(this._injectedDialogData['data']['EntityID'])" type="submit" mat-raised-button
            color="accent">Delete Enrollment</button>
    </ng-container>
</mat-dialog-actions>

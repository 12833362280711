import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DrupalRESTService } from 'src/app/services/drupal-rest.service';
import { FieldsService } from 'src/app/services/fields.service';
import { timeZones, getTimeZoneDisplay, getTimezonesByCountry } from 'src/app/constants/time-zones';
import { PhonePipe } from 'src/app/pipes/phone.pipe';

@Component({
  selector: 'app-form-studio-information',
  templateUrl: './form-studio-information.component.html',
  styleUrls: ['./form-studio-information.component.css'],
  providers: [PhonePipe]
})
export class FormStudioInformationComponent implements OnInit {
  studioInformationForm: FormGroup;
  actionType: 'create' | 'edit' = 'create';
  showProgressSpinner = false;
  successMessage: string;
  errorMessage: string;

  timeZones = timeZones;
  filteredTimeZones = timeZones; // Start with all timezones
  getTimeZoneDisplay = getTimeZoneDisplay;

  // Map of country codes to their administrative areas
  countryAdministrativeAreas: { [key: string]: any[] } = {};

  // Current administrative area label based on selected country
  administrativeAreaLabel = 'State';

  // Current postal code label based on selected country
  postalCodeLabel = 'ZIP Code';

  constructor(
    private fb: FormBuilder,
    private drupalRESTService: DrupalRESTService,
    public _fieldsService: FieldsService,
    private phonePipe: PhonePipe
  ) {}

  ngOnInit(): void {
    this.studioInformationForm = this.fb.group({
      studioDisplayName: ['', Validators.required],
      studioEmail: ['', [Validators.required, Validators.email]],
      studioPhoneNumber: ['', Validators.required],
      country: ['', Validators.required],
      isBonded: [false],
      studioWebsite: this.fb.group({
        uri: ['', Validators.required],
        title: ['', Validators.required]
      }),
      studioAddress: this.fb.group({
        givenName: ['', Validators.required],
        familyName: ['', Validators.required],
        company: [''],
        streetAddress: ['', Validators.required],
        city: ['', Validators.required],
        state: [''],
        zipCode: ['', Validators.required]
      }),
      studioTimeZone: ['', Validators.required]
    });

    // Initialize administrative areas for countries
    this.initializeCountryAdministrativeAreas();

    // Set up country change listener
    this.studioInformationForm.get('country').valueChanges.subscribe(country => {
      this.onCountryChange(country);
    });

    // Set up phone number formatter
    this.studioInformationForm.get('studioPhoneNumber').valueChanges.subscribe(phoneNumber => {
      if (phoneNumber && !phoneNumber.includes('(') && phoneNumber.length > 0) {
        const formattedPhoneNumber = this.phonePipe.transform(phoneNumber);
        this.studioInformationForm.get('studioPhoneNumber').setValue(formattedPhoneNumber, { emitEvent: false });
      }
    });

    this.loadStudioInformation();
  }

  /**
   * Initialize administrative areas for different countries
   */
  initializeCountryAdministrativeAreas() {
    // US states are already in the FieldsService
    this.countryAdministrativeAreas['US'] = this._fieldsService.StatesAbbreviations;

    // Add Canadian provinces
    this.countryAdministrativeAreas['CA'] = [
      { name: "Alberta", abbreviation: "AB" },
      { name: "British Columbia", abbreviation: "BC" },
      { name: "Manitoba", abbreviation: "MB" },
      { name: "New Brunswick", abbreviation: "NB" },
      { name: "Newfoundland and Labrador", abbreviation: "NL" },
      { name: "Northwest Territories", abbreviation: "NT" },
      { name: "Nova Scotia", abbreviation: "NS" },
      { name: "Nunavut", abbreviation: "NU" },
      { name: "Ontario", abbreviation: "ON" },
      { name: "Prince Edward Island", abbreviation: "PE" },
      { name: "Quebec", abbreviation: "QC" },
      { name: "Saskatchewan", abbreviation: "SK" },
      { name: "Yukon", abbreviation: "YT" }
    ];

    // Add Australian states/territories
    this.countryAdministrativeAreas['AU'] = [
      { name: "Australian Capital Territory", abbreviation: "ACT" },
      { name: "New South Wales", abbreviation: "NSW" },
      { name: "Northern Territory", abbreviation: "NT" },
      { name: "Queensland", abbreviation: "QLD" },
      { name: "South Australia", abbreviation: "SA" },
      { name: "Tasmania", abbreviation: "TAS" },
      { name: "Victoria", abbreviation: "VIC" },
      { name: "Western Australia", abbreviation: "WA" }
    ];

    // Add UK counties/regions
    this.countryAdministrativeAreas['GB'] = [
      { name: "England", abbreviation: "ENG" },
      { name: "Northern Ireland", abbreviation: "NIR" },
      { name: "Scotland", abbreviation: "SCT" },
      { name: "Wales", abbreviation: "WLS" }
    ];

    // Add Italian provinces
    this.countryAdministrativeAreas['IT'] = [
      { name: "Agrigento", abbreviation: "AG" },
      { name: "Alessandria", abbreviation: "AL" },
      { name: "Ancona", abbreviation: "AN" },
      { name: "Aosta", abbreviation: "AO" },
      { name: "Arezzo", abbreviation: "AR" },
      { name: "Ascoli Piceno", abbreviation: "AP" },
      { name: "Asti", abbreviation: "AT" },
      { name: "Avellino", abbreviation: "AV" },
      { name: "Bari", abbreviation: "BA" },
      { name: "Barletta-Andria-Trani", abbreviation: "BT" },
      { name: "Belluno", abbreviation: "BL" },
      { name: "Benevento", abbreviation: "BN" },
      { name: "Bergamo", abbreviation: "BG" },
      { name: "Biella", abbreviation: "BI" },
      { name: "Bologna", abbreviation: "BO" },
      { name: "Bolzano", abbreviation: "BZ" },
      { name: "Brescia", abbreviation: "BS" },
      { name: "Brindisi", abbreviation: "BR" },
      { name: "Cagliari", abbreviation: "CA" },
      { name: "Caltanissetta", abbreviation: "CL" },
      { name: "Campobasso", abbreviation: "CB" },
      { name: "Carbonia-Iglesias", abbreviation: "CI" },
      { name: "Caserta", abbreviation: "CE" },
      { name: "Catania", abbreviation: "CT" },
      { name: "Catanzaro", abbreviation: "CZ" },
      { name: "Chieti", abbreviation: "CH" },
      { name: "Como", abbreviation: "CO" },
      { name: "Cosenza", abbreviation: "CS" },
      { name: "Cremona", abbreviation: "CR" },
      { name: "Crotone", abbreviation: "KR" },
      { name: "Cuneo", abbreviation: "CN" },
      { name: "Enna", abbreviation: "EN" },
      { name: "Fermo", abbreviation: "FM" },
      { name: "Ferrara", abbreviation: "FE" },
      { name: "Firenze", abbreviation: "FI" },
      { name: "Foggia", abbreviation: "FG" },
      { name: "Forlì-Cesena", abbreviation: "FC" },
      { name: "Frosinone", abbreviation: "FR" },
      { name: "Genova", abbreviation: "GE" },
      { name: "Gorizia", abbreviation: "GO" },
      { name: "Grosseto", abbreviation: "GR" },
      { name: "Imperia", abbreviation: "IM" },
      { name: "Isernia", abbreviation: "IS" },
      { name: "L'Aquila", abbreviation: "AQ" },
      { name: "La Spezia", abbreviation: "SP" },
      { name: "Latina", abbreviation: "LT" },
      { name: "Lecce", abbreviation: "LE" },
      { name: "Lecco", abbreviation: "LC" },
      { name: "Livorno", abbreviation: "LI" },
      { name: "Lodi", abbreviation: "LO" },
      { name: "Lucca", abbreviation: "LU" },
      { name: "Macerata", abbreviation: "MC" },
      { name: "Mantova", abbreviation: "MN" },
      { name: "Massa-Carrara", abbreviation: "MS" },
      { name: "Matera", abbreviation: "MT" },
      { name: "Medio Campidano", abbreviation: "VS" },
      { name: "Messina", abbreviation: "ME" },
      { name: "Milano", abbreviation: "MI" },
      { name: "Modena", abbreviation: "MO" },
      { name: "Monza e Brianza", abbreviation: "MB" },
      { name: "Napoli", abbreviation: "NA" },
      { name: "Novara", abbreviation: "NO" },
      { name: "Nuoro", abbreviation: "NU" },
      { name: "Ogliastra", abbreviation: "OG" },
      { name: "Olbia-Tempio", abbreviation: "OT" },
      { name: "Oristano", abbreviation: "OR" },
      { name: "Padova", abbreviation: "PD" },
      { name: "Palermo", abbreviation: "PA" },
      { name: "Parma", abbreviation: "PR" },
      { name: "Pavia", abbreviation: "PV" },
      { name: "Perugia", abbreviation: "PG" },
      { name: "Pesaro e Urbino", abbreviation: "PU" },
      { name: "Pescara", abbreviation: "PE" },
      { name: "Piacenza", abbreviation: "PC" },
      { name: "Pisa", abbreviation: "PI" },
      { name: "Pistoia", abbreviation: "PT" },
      { name: "Pordenone", abbreviation: "PN" },
      { name: "Potenza", abbreviation: "PZ" },
      { name: "Prato", abbreviation: "PO" },
      { name: "Ragusa", abbreviation: "RG" },
      { name: "Ravenna", abbreviation: "RA" },
      { name: "Reggio Calabria", abbreviation: "RC" },
      { name: "Reggio Emilia", abbreviation: "RE" },
      { name: "Rieti", abbreviation: "RI" },
      { name: "Rimini", abbreviation: "RN" },
      { name: "Roma", abbreviation: "RM" },
      { name: "Rovigo", abbreviation: "RO" },
      { name: "Salerno", abbreviation: "SA" },
      { name: "Sassari", abbreviation: "SS" },
      { name: "Savona", abbreviation: "SV" },
      { name: "Siena", abbreviation: "SI" },
      { name: "Siracusa", abbreviation: "SR" },
      { name: "Sondrio", abbreviation: "SO" },
      { name: "Taranto", abbreviation: "TA" },
      { name: "Teramo", abbreviation: "TE" },
      { name: "Terni", abbreviation: "TR" },
      { name: "Torino", abbreviation: "TO" },
      { name: "Trapani", abbreviation: "TP" },
      { name: "Trento", abbreviation: "TN" },
      { name: "Treviso", abbreviation: "TV" },
      { name: "Trieste", abbreviation: "TS" },
      { name: "Udine", abbreviation: "UD" },
      { name: "Varese", abbreviation: "VA" },
      { name: "Venezia", abbreviation: "VE" },
      { name: "Verbano-Cusio-Ossola", abbreviation: "VB" },
      { name: "Vercelli", abbreviation: "VC" },
      { name: "Verona", abbreviation: "VR" },
      { name: "Vibo Valentia", abbreviation: "VV" },
      { name: "Vicenza", abbreviation: "VI" },
      { name: "Viterbo", abbreviation: "VT" }
    ];

    // Add Mexican states
    this.countryAdministrativeAreas['MX'] = [
      { name: "Aguascalientes", abbreviation: "AGU" },
      { name: "Baja California", abbreviation: "BCN" },
      { name: "Baja California Sur", abbreviation: "BCS" },
      { name: "Campeche", abbreviation: "CAM" },
      { name: "Chiapas", abbreviation: "CHP" },
      { name: "Chihuahua", abbreviation: "CHH" },
      { name: "Coahuila", abbreviation: "COA" },
      { name: "Colima", abbreviation: "COL" },
      { name: "Durango", abbreviation: "DUR" },
      { name: "Guanajuato", abbreviation: "GUA" },
      { name: "Guerrero", abbreviation: "GRO" },
      { name: "Hidalgo", abbreviation: "HID" },
      { name: "Jalisco", abbreviation: "JAL" },
      { name: "México", abbreviation: "MEX" },
      { name: "Michoacán", abbreviation: "MIC" },
      { name: "Morelos", abbreviation: "MOR" },
      { name: "Nayarit", abbreviation: "NAY" },
      { name: "Nuevo León", abbreviation: "NLE" },
      { name: "Oaxaca", abbreviation: "OAX" },
      { name: "Puebla", abbreviation: "PUE" },
      { name: "Querétaro", abbreviation: "QUE" },
      { name: "Quintana Roo", abbreviation: "ROO" },
      { name: "San Luis Potosí", abbreviation: "SLP" },
      { name: "Sinaloa", abbreviation: "SIN" },
      { name: "Sonora", abbreviation: "SON" },
      { name: "Tabasco", abbreviation: "TAB" },
      { name: "Tamaulipas", abbreviation: "TAM" },
      { name: "Tlaxcala", abbreviation: "TLA" },
      { name: "Veracruz", abbreviation: "VER" },
      { name: "Yucatán", abbreviation: "YUC" },
      { name: "Zacatecas", abbreviation: "ZAC" }
    ];
  }

  /**
   * Handle country change to update administrative area label and validation
   */
  onCountryChange(countryCode: string) {
    const stateControl = this.studioInformationForm.get('studioAddress.state');

    // Update administrative area label based on country
    this.administrativeAreaLabel = this.getAdministrativeAreaLabel(countryCode);

    // Update postal code label based on country
    this.postalCodeLabel = this.getPostalCodeLabel(countryCode);

    // Filter timezones based on selected country
    this.filteredTimeZones = getTimezonesByCountry(countryCode);

    // If country has predefined administrative areas, make the field required
    if (this.countryAdministrativeAreas[countryCode]) {
      stateControl.setValidators([Validators.required]);
    } else {
      // For countries without predefined areas, make it optional
      stateControl.clearValidators();
    }

    stateControl.updateValueAndValidity();
  }

  /**
   * Get the appropriate label for administrative area based on country
   */
  getAdministrativeAreaLabel(countryCode: string): string {
    const labels = {
      'US': 'State',
      'CA': 'Province',
      'AU': 'State/Territory',
      'GB': 'Region',
      'MX': 'State',
      'FR': 'Region',
      'DE': 'State',
      'JP': 'Prefecture',
      'CN': 'Province',
      'IT': 'Province'
    };

    return labels[countryCode] || 'State/Province';
  }

  /**
   * Get the appropriate label for postal code based on country
   */
  getPostalCodeLabel(countryCode: string): string {
    const labels = {
      'US': 'ZIP Code',
      'CA': 'Postal Code',
      'GB': 'Postcode',
      'AU': 'Postcode',
      'DE': 'PLZ',
      'FR': 'Code Postal',
      'JP': 'Postal Code',
      'CN': 'Postal Code',
      'IT': 'CAP'
    };

    return labels[countryCode] || 'Postal/ZIP Code';
  }

  /**
   * Get administrative areas for the selected country
   */
  getAdministrativeAreas(countryCode: string): any[] {
    return this.countryAdministrativeAreas[countryCode] || [];
  }

  loadStudioInformation() {
    const configFieldName = 'field_studio_information_ref';
    this.drupalRESTService
      .httpGET(`/api_rest/v1/loadStudioConfig?config_field_name=${configFieldName}`)
      .subscribe(
        (response) => {
          const timezoneValue = response?.['field_studio_timezone']?.[0]?.value;
          const countryCode = response?.['field_studio_address']?.[0]?.country_code;
          const phoneNumber = response?.['field_studio_phone_number']?.[0]?.value;

          // Format the phone number using the PhonePipe
          const formattedPhoneNumber = this.phonePipe.transform(phoneNumber);

          const formData = {
            studioDisplayName: response?.['field_studio_display_name']?.[0]?.value,
            studioEmail: response?.['field_studio_email']?.[0]?.value,
            studioPhoneNumber: formattedPhoneNumber,
            country: countryCode,
            isBonded: response?.['field_studio_is_bonded']?.[0]?.value === '1' || response?.['field_studio_is_bonded']?.[0]?.value === true,
            studioWebsite: {
              uri: response?.['field_studio_website']?.[0]?.uri,
              title: response?.['field_studio_website']?.[0]?.title
            },
            studioAddress: {
              givenName: response?.['field_studio_address']?.[0]?.given_name,
              familyName: response?.['field_studio_address']?.[0]?.family_name,
              company: response?.['field_studio_address']?.[0]?.organization,
              streetAddress: response?.['field_studio_address']?.[0]?.address_line1,
              city: response?.['field_studio_address']?.[0]?.locality,
              state: response?.['field_studio_address']?.[0]?.administrative_area,
              zipCode: response?.['field_studio_address']?.[0]?.postal_code
            },
            studioTimeZone: timezoneValue
          };

          // First trigger country change to set up filtered timezones and other country-specific settings
          if (countryCode) {
            this.onCountryChange(countryCode);

            // If the saved timezone is not in the filtered list for this country,
            // we need to add it to ensure it's available for selection
            if (timezoneValue) {
              const timezoneExists = this.filteredTimeZones.some(tz => tz.value === timezoneValue);
              if (!timezoneExists) {
                const fullTimezone = this.timeZones.find(tz => tz.value === timezoneValue);
                if (fullTimezone) {
                  // Add the timezone to the filtered list if it exists in the full list
                  this.filteredTimeZones = [...this.filteredTimeZones, fullTimezone];
                }
              }
            }
          }

          // Now patch the form values
          this.studioInformationForm.patchValue(formData);
        },
        (error) => {
          console.error('Error loading studio information:', error);
          this.errorMessage = 'Error loading studio information.';
        }
      );
  }

  saveStudioInformation() {
    const configFieldName = 'field_studio_information_ref';
    const formValues = this.studioInformationForm.value;

    // Clean the phone number by removing formatting characters
    const cleanedPhoneNumber = formValues.studioPhoneNumber.replace(/\D/g, '');

    const postData = {
      config_field_name: configFieldName,
      config_data: {
        field_studio_display_name: [{value: formValues.studioDisplayName}],
        field_studio_email: [{value: formValues.studioEmail}],
        field_studio_phone_number: [{value: cleanedPhoneNumber}],
        field_studio_is_bonded: [{value: formValues.isBonded ? '1' : '0'}],
        field_studio_website: [{
          uri: formValues.studioWebsite.uri,
          title: formValues.studioWebsite.title
        }],
        field_studio_address: [{
          country_code: formValues.country,
          given_name: formValues.studioAddress.givenName,
          family_name: formValues.studioAddress.familyName,
          organization: formValues.studioAddress.company,
          address_line1: formValues.studioAddress.streetAddress,
          locality: formValues.studioAddress.city,
          administrative_area: formValues.studioAddress.state,
          postal_code: formValues.studioAddress.zipCode
        }],
        field_studio_timezone: [{value: formValues.studioTimeZone}]
      }
    };

    this.showProgressSpinner = true;
    this.successMessage = '';
    this.errorMessage = '';

    this.drupalRESTService
      .httpPOST('/api_rest/v1/saveStudioConfig', postData)
      .subscribe(
        (response) => {
          console.log('Studio information saved successfully:', response);
          this.successMessage = 'Studio information saved successfully.';
          this.showProgressSpinner = false;
        },
        (error) => {
          console.error('Error saving studio information:', error);
          this.errorMessage = 'Error saving studio information.';
          this.showProgressSpinner = false;
        }
      );
  }

  onSubmit() {
    this.saveStudioInformation();
  }

  onUpdate() {
    this.saveStudioInformation();
  }

  /**
   * Show all available timezones regardless of selected country
   */
  showAllTimezones() {
    this.filteredTimeZones = this.timeZones;

    // Need to set timeout to allow the mat-select to update before setting a value
    setTimeout(() => {
      // Reset the timezone value since 'show_all' is not a valid timezone
      const currentTimezone = this.studioInformationForm.get('studioTimeZone').value;
      if (currentTimezone === 'show_all') {
        this.studioInformationForm.get('studioTimeZone').setValue('');
      }
    });
  }
}

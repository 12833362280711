import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * Service to handle refreshing components across the application
 */
@Injectable({
  providedIn: 'root'
})
export class RefreshService {
  // Create subjects for each component type that needs refreshing
  private paymentsRefreshSource = new Subject<void>();
  private enrollmentsRefreshSource = new Subject<void>();
  private packagesRefreshSource = new Subject<void>();

  // Observable streams that components can subscribe to
  paymentsRefresh$ = this.paymentsRefreshSource.asObservable();
  enrollmentsRefresh$ = this.enrollmentsRefreshSource.asObservable();
  packagesRefresh$ = this.packagesRefreshSource.asObservable();

  constructor() { }

  /**
   * Refresh payments components
   */
  refreshPayments(): void {
    console.log('RefreshService: Refreshing payments');
    this.paymentsRefreshSource.next();
  }

  /**
   * Refresh enrollments components
   */
  refreshEnrollments(): void {
    console.log('RefreshService: Refreshing enrollments');
    this.enrollmentsRefreshSource.next();
  }

  /**
   * Refresh packages components
   */
  refreshPackages(): void {
    console.log('RefreshService: Refreshing packages');
    this.packagesRefreshSource.next();
  }

  /**
   * Refresh a component by type
   * @param componentType The type of component to refresh ('payments', 'enrollments', 'packages')
   */
  refreshByType(componentType: string): void {
    console.log(`RefreshService: Refreshing ${componentType}`);

    switch (componentType) {
      case 'payments':
        this.refreshPayments();
        break;
      case 'enrollments':
        this.refreshEnrollments();
        break;
      case 'packages':
        this.refreshPackages();
        break;
      default:
        console.warn(`RefreshService: Unknown component type: ${componentType}`);
    }
  }
}

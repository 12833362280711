import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/services/auth.service';
import { DrupalRESTService } from 'src/app/services/drupal-rest.service';

@Component({
  selector: 'app-square-terminal-payment',
  templateUrl: './square-terminal-payment.component.html',
  styleUrls: ['./square-terminal-payment.component.css']
})

export class SquareTerminalPaymentComponent implements OnInit {
  amount: number;
  currency: string;
  studioId: string;
  enrollmentIds: string[] = [];
  studentAccountId: string | null = null;
  paymentStatus: 'SUCCESS' | 'PENDING' | 'FAILURE' | 'CANCELED' | null = null;
  paymentStatusMessage: string = '';
  isProcessing: boolean = false;
  checkoutId: string | null = null;
  paymentForm: FormGroup | null = null;
  savedCardId: string | null = null;
  idempotencyKey: string = '';
  private pollingInterval: any;

  constructor(
    private drupalRESTService: DrupalRESTService,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<SquareTerminalPaymentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log('SquareTerminalPaymentComponent received data:', data);
    this.amount = data.amount;
    this.currency = data.currency || 'USD';
    this.studioId = data.studioId;
    this.enrollmentIds = data.enrollmentIds || [];
    this.studentAccountId = data.studentAccountId || null;
    this.paymentForm = data.paymentForm || null;
    this.savedCardId = data.savedCardId || null;
    this.idempotencyKey = data.idempotencyKey || '';
  }

  ngOnInit() {
    if (!this.amount || this.amount <= 0 || !this.studioId) {
      console.error('Invalid amount or studio ID provided:', { amount: this.amount, studioId: this.studioId });
      this.snackBar.open('Invalid amount or studio ID provided', 'Close', { duration: 5000 });
      this.dialogRef.close({ status: 'ERROR', message: 'Invalid amount or studio ID' });
    } else {
      console.log('SquareTerminalPaymentComponent initialized with:', { amount: this.amount, currency: this.currency, studioId: this.studioId });

      // Automatically start the payment process
      this.capturePayment();
    }
  }

  onCapturePaymentClick() {
    console.log('Capture payment clicked');
    this.capturePayment();
  }

  onCancelPaymentClick() {
    console.log('Cancel payment clicked');
    this.cancelPayment();
  }

  private capturePayment() {
    if (this.isProcessing) {
      return;
    }

    this.isProcessing = true;
    this.paymentStatus = null;
    this.paymentStatusMessage = '';
    this.checkoutId = null;

    const amountInCents = Math.round(this.amount * 100);

    if (!this.studioId) {
      this.handlePaymentError(new Error('No studio ID available'));
      return;
    }

    if (!this.enrollmentIds.length) {
      this.handlePaymentError(new Error('No enrollment IDs provided'));
      return;
    }

    const endpoint = `/api/square/terminal/checkout/${this.studioId}/${amountInCents}/${this.currency}`;
    const payload = {
      enrollmentIds: this.enrollmentIds,
      studentAccountId: this.studentAccountId,
      currency: this.currency,
      amount: amountInCents,
      paymentForm: this.paymentForm.value,
      savedCardId: this.savedCardId
    };

    this.drupalRESTService.httpPOST(endpoint, payload).subscribe({
      next: (response) => {
        this.handlePaymentSuccess(response);
        // Start polling after successful checkout creation
        this.startPolling();
      },
      error: (error) => this.handlePaymentError(error)
    });
  }

  private cancelPayment() {
    if (!this.checkoutId || this.paymentStatus !== 'PENDING') {
      return;
    }

    this.isProcessing = true;

    if (!this.studioId) {
      this.handleCancellationError(new Error('No studio ID available'));
      return;
    }

    const endpoint = `/api/square/terminal/cancel/${this.studioId}/${this.checkoutId}`;

    // Remove query parameters from the URL
    this.drupalRESTService.httpPOST(endpoint, {}).subscribe({
      next: (response) => this.handleCancellationSuccess(response),
      error: (error) => this.handleCancellationError(error)
    });
  }

  private handlePaymentSuccess(response: any) {
    this.isProcessing = false;

    // Handle Terminal Checkout response
    if (response.checkout && response.checkout.id) {
      this.checkoutId = response.checkout.id;
      this.paymentStatus = response.checkout.status === 'PENDING' ? 'PENDING' : 'SUCCESS';
      this.paymentStatusMessage = `Terminal checkout created. ID: ${this.checkoutId}`;
      this.snackBar.open('Terminal checkout created successfully!', 'Close', { duration: 5000 });
    }
    // Handle Direct Payment response
    else if (response.success && response.payment) {
      this.paymentStatus = response.payment.status === 'COMPLETED' ? 'SUCCESS' : 'PENDING';
      this.checkoutId = response.payment.id;
      this.paymentStatusMessage = `Payment processed successfully. ID: ${this.checkoutId}`;
      this.snackBar.open('Payment processed successfully!', 'Close', { duration: 5000 });
      this.onClose(); // Close the dialog since payment is complete
    }
    else {
      this.handlePaymentError(new Error('Invalid response from server'));
    }
  }

  private handlePaymentError(error: any) {
    this.isProcessing = false;
    this.paymentStatus = 'FAILURE';

    // Check for the specific error message about no device ID
    if (error?.error?.error === 'No device ID configured for this studio.') {
      this.paymentStatusMessage = 'No Square Terminal device is configured for this studio. Please contact your administrator to set up a device.';
    } else if (error?.error?.errorCode === 'NO_DEVICE_ID') {
      this.paymentStatusMessage = 'No Square Terminal device is configured for this studio. Please contact your administrator to set up a device.';
    }
    // Check for authentication errors
    else if (error?.error?.errors && Array.isArray(error.error.errors)) {
      const authError = error.error.errors.find(e =>
        e.category === 'AUTHENTICATION_ERROR' ||
        e.code === 'UNAUTHORIZED' ||
        e.detail?.includes('could not be authorized')
      );

      if (authError) {
        this.paymentStatusMessage = 'Square authentication failed. Your Square account may need to be reauthorized.';
      } else {
        this.paymentStatusMessage = 'Failed to create terminal checkout. Please try again.';
      }
    } else {
      this.paymentStatusMessage = 'Failed to create terminal checkout. Please try again.';
    }

    console.error('Error creating terminal checkout:', error);
    this.snackBar.open(this.paymentStatusMessage, 'Close', { duration: 5000 });
  }

  private handleCancellationSuccess(response: any) {
    this.isProcessing = false;
    this.paymentStatus = 'CANCELED';
    this.paymentStatusMessage = 'Terminal checkout cancelled successfully.';
    this.snackBar.open(this.paymentStatusMessage, 'Close', { duration: 5000 });
  }

  private handleCancellationError(error: any) {
    this.isProcessing = false;
    this.paymentStatusMessage = 'Failed to cancel terminal checkout. Please try again.';
    console.error('Error cancelling terminal checkout:', error);
    this.snackBar.open(this.paymentStatusMessage, 'Close', { duration: 5000 });
  }

  onClose() {
    this.dialogRef.close({
      status: this.paymentStatus,
      checkoutId: this.checkoutId,
      error: this.paymentStatus === 'FAILURE' ? this.paymentStatusMessage : null
    });
  }

  private startPolling() {
    this.pollingInterval = setInterval(() => {
      if (!this.checkoutId || !this.studioId) return;

      const endpoint = `/api/square/terminal/status/${this.studioId}/${this.checkoutId}`;
      this.drupalRESTService.httpGET(endpoint).subscribe({
        next: (response) => {
          switch (response?.['status']) {
            case 'COMPLETED':
              this.paymentStatus = 'SUCCESS';
              this.paymentStatusMessage = 'Payment completed successfully';
              this.stopPolling();
              this.onClose();
              break;
            case 'CANCELED':
              this.paymentStatus = 'CANCELED';
              this.paymentStatusMessage = 'Payment was canceled';
              this.stopPolling();
              this.onClose();
              break;
            case 'FAILED':
              this.paymentStatus = 'FAILURE';
              this.paymentStatusMessage = 'Payment failed';
              this.stopPolling();
              this.onClose();
              break;
            // Payment is still processing
            case 'IN_PROGRESS':
            case 'PENDING':
              this.paymentStatus = 'PENDING';
              this.paymentStatusMessage = 'Payment is processing...';
              break;
          }
        },
        error: (error) => {
          console.error('Error polling payment status:', error);
          this.paymentStatus = 'FAILURE';
          this.paymentStatusMessage = 'Error checking payment status';
        }
      });
    }, 2000);
  }

  private stopPolling() {
    if (this.pollingInterval) {
      clearInterval(this.pollingInterval);
      this.pollingInterval = null;
    }
  }

  ngOnDestroy() {
    this.stopPolling();
  }
}

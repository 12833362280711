import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ComponentRefreshService {
  // Create subjects for each component type
  private paymentsReportRefreshSource = new Subject<void>();
  private enrollmentsViewRefreshSource = new Subject<void>();
  private packageListRefreshSource = new Subject<void>();
  private genericRefreshSource = new Subject<string>();

  // Observable streams that components can subscribe to
  paymentsReportRefresh$ = this.paymentsReportRefreshSource.asObservable();
  enrollmentsViewRefresh$ = this.enrollmentsViewRefreshSource.asObservable();
  packageListRefresh$ = this.packageListRefreshSource.asObservable();
  genericRefresh$ = this.genericRefreshSource.asObservable();

  constructor() {
    // Listen for custom refresh events
    document.addEventListener('amt-refresh-data', (event: any) => {
      const componentName = event.detail?.componentName;
      console.log(`ComponentRefreshService: Received refresh event for ${componentName}`);

      if (componentName) {
        this.refreshComponent(componentName);
      }
    });
  }

  /**
   * Refreshes a component by name
   * @param componentName The name of the component to refresh
   */
  refreshComponent(componentName: string): void {
    console.log(`ComponentRefreshService: Refreshing ${componentName}`);

    switch (componentName) {
      case 'PaymentsReportViewComponent':
        this.refreshPaymentsReport();
        break;
      case 'EnrollmentsViewComponent':
        this.refreshEnrollmentsView();
        break;
      case 'PackageListComponent':
        this.refreshPackageList();
        break;
      default:
        this.genericRefreshSource.next(componentName);
        break;
    }
  }

  /**
   * Refreshes the payments report component
   */
  refreshPaymentsReport(): void {
    console.log('ComponentRefreshService: Refreshing payments report');
    this.paymentsReportRefreshSource.next();
  }

  /**
   * Refreshes the enrollments view component
   */
  refreshEnrollmentsView(): void {
    console.log('ComponentRefreshService: Refreshing enrollments view');
    this.enrollmentsViewRefreshSource.next();
  }

  /**
   * Refreshes the package list component
   */
  refreshPackageList(): void {
    console.log('ComponentRefreshService: Refreshing package list');
    this.packageListRefreshSource.next();
  }
}

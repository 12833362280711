import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BaseDialogComponent } from '../../forms/base-dialog/base-dialog.component';
import { environment } from 'src/environments/environment';
import { MatStepper } from '@angular/material/stepper';
import { Validators, FormArray } from '@angular/forms';
import { EnrollmentEntityComponent } from '../../forms/enrollment-entity/enrollment-entity.component';
import { EventLessonEntityComponent } from '../../forms/event-lesson-entity/event-lesson-entity.component';
import { from, mergeMap, forkJoin } from 'rxjs';
import { getEnrollmentAgreementBackpageContent } from '../../../../assets/text/enrollment-agreement-backpage.content';

@Component({
  selector: 'app-base-enrollment',
  templateUrl: './base-enrollment.component.html'
})
export class BaseEnrollmentComponent extends BaseDialogComponent {

  public backpageHtmlContent: string = '';

  detectSignatureStudio: boolean;
  detectSignatureStudent: boolean;
  detectSignatureParent: boolean;
  flag1: boolean;
  flag2: boolean;

  tosAccepted: boolean = false;

  studioInformationConfig;
  taxRate: number = 0;

  EventLessonEntityComponent = EventLessonEntityComponent;
  EnrollmentEntityComponent = EnrollmentEntityComponent;

  enrollmentStepperIndex: number = 0;

  @ViewChild('SignatureStudent') signatureStudent: ElementRef<HTMLCanvasElement>;
  @ViewChild('SignatureStudio') signatureStudio: ElementRef<HTMLCanvasElement>;
  @ViewChild('SignatureParent') signatureParent: ElementRef<HTMLCanvasElement>;
  @ViewChild('enrollmentStepper') enrollmentStepper: MatStepper;

  override ngOnInit(): void {
    this.initActionType = this._injectedDialogData['data']['action'];
    this.actionType = this._injectedDialogData['data']['action'];
    this.enrollmentID = this._injectedDialogData['data']['EntityID'];

    // Load extra data for enrollments.
    if (this._injectedDialogData['data']['action'] == 'view' || this._injectedDialogData['data']['action'] == '0' || this._injectedDialogData['data']['action'] == 'signEnrollment') {
      this.loadEnrollmentData(this.enrollmentID).subscribe(data => {
        this._enrollmentEntityData = data;

        // Load additional student data once we know which enrollment we are working with.
        this._entityRESTService.getEntity('student_accounts', 'student_account', data['field_student'][0].target_id)
          .subscribe(data => {
            this.studentAccountEntityData = data;
          });
      });

      this._drupalRESTService.httpGET('/api_rest/v1/getStudioInformation')
        .subscribe(data => {
          this.studioInformationConfig = data;
          console.log('Studio Information:', this.studioInformationConfig);
        }, error => {
          console.error('Error fetching studio information:', error);
        });
    }

    // Load extra data for enrollments.
    if (this._injectedDialogData['data']['action'] == 'signEnrollment') {
      // this.enrollmentStepper.selectedIndex = 3;
      this.loadEnrollmentData(this.enrollmentID).subscribe(data => {
        this._enrollmentEntityData = data;

        // Load additional student data once we know which enrollment we are working with.
        this._entityRESTService.getEntity('student_accounts', 'student_account', data['field_student'][0].target_id)
          .subscribe(data => {
            // console.log('student_accounts student_account data', data);
            this.studentAccountEntityData = data;

            if (this.enrollmentStepper != undefined) {
              this.enrollmentStepper.selectedIndex = 2;
            }
          });
      });
    }

    this.enrollmentForm = this._formBuilder.group({
      title: ['default title'],
      field_draft: [false],
      field_student: [this._frmValues?.field_student, Validators.required],
      field_sale_date: [this._frmValues?.field_sale_date, Validators.required],
      field_expiration_date: [null, Validators.required],
      field_category: [null, Validators.required],
      field_enrollment_package_name: [null, Validators.required],
      field_enrollment_lesson_price: [null, Validators.required],
      field_enrollment_lesson_count: [null, Validators.required],
      field_total_lessons_price: ['', Validators.required],
      field_enrollment_total_price: [null, Validators.required],
      field_total_price_with_est_tax: [null, Validators.required],
      field_taxes: [null, Validators.required],
      field_tax_percentage: [this.taxRate, Validators.required],
      field_total_paid: [null],
      field_lesson_used: [null],
      field_lessons_paid: [null],
      field_lesson_available: [null],
      field_payments_structure: [],
      field_payments_structure_type: [],
      field_executive: [null, Validators.required],
      field_junior_executive: [null],
      field_scheduled_payments: new FormArray([]),
      field_instructor_percentages: new FormArray([]),
      field_interval_unit: [''],
      field_discount: [0, Validators.required],
      field_discount_type: [],
      field_enrollment_status: ['Open', Validators.required],
      field_next_scheduled_payment: [''],
      field_visibility: [false],
      field_notes: [''],
      field_tos_accepted_date: [null],
      interval: [this.interval],
      field_recurrent_month_day: [''],
      field_recurrent_weekday_mo: [''],
      field_recurrent_weekday_tu: [''],
      field_recurrent_weekday_we: [''],
      field_recurrent_weekday_th: [''],
      field_recurrent_weekday_fr: [''],
      field_recurrent_weekday_sa: [''],
      field_recurrent_weekday_su: [''],
      payment_builder_date_step: new FormArray([]),
      giftCertificateCode: [''],
      giftCertificates: this._formBuilder.array([])
    });
    this.agreementFormGroup = this._formBuilder.group({
      field_nothing: [''],
    });
    this.paymentForm = this._formBuilder.group({
      field_payment_id: [this.paymentID, Validators.required],
      // field_customer_name: [null],
      field_date_and_time: [this.todaysDate, Validators.required],
      field_gross_tuition: [null, Validators.required],
      field_student_name: [null, Validators.required],
      field_enrollment_name: this._formBuilder.group({
        'target_id': [null, Validators.required]
      }),
      field_scheduled_payment: this._formBuilder.group({
        'target_id': [null]
      }),
      field_payment_type: [null, Validators.required],
      cc_number: [null],
      cc_cvv: [null],
      cc_expiration: [null],
      cc_expiration_month: [null],
      cc_expiration_year: [null],
      cc_postal_code: [null],
      field_stripe_charge_id: [this.entityData?.field_stripe_charge_id],
      reason_to_refund: [null],
      field_payments_use_card: [null],
      field_notes: [null],
    });
  }

  override ngAfterViewInit(): void {
    this.agreementFormGroup = this._formBuilder.group({
      field_nothing: [''],
    });

    // Signature stuff.
    this.signatureInit(this.signatureStudent, 'student');
    this.signatureInit(this.signatureStudio, 'studio');

    // Pull current tax rate.
    if (this.actionType == "create") {
      this._fieldsService.getTaxRateConfig().subscribe(data => {
        this.enrollmentForm.get("field_tax_percentage").setValue(data?.[0]?.['value']);
      });
    } else {
      // Respect the tax value if in edit mode.
    }

    this.getUniquePaymentID()
      .subscribe(data => {
        this.paymentID = data;
        // this.setFormMode("create");
      });
  }

  setStepperIndex(index) {
    // this.enrollmentStepper.linear = true;
    if (this.enrollmentStepper != undefined) {
      this.enrollmentStepper.selected.completed = true;
      this.enrollmentStepper.selected.editable = false;
      this.enrollmentStepper.next();
    }
  }

  referenceUploadedFileComplete(action) {
    if (action === 'save_and_pay') {
      // Autofill payment information
      this.paymentForm.patchValue({
        field_date_and_time: this.enrollmentForm.get('field_sale_date').value,
        field_student_name: this.enrollmentEntityData['field_student']['title'] + ' (' + this.enrollmentEntityData['field_student']['id'] + ')',
        field_enrollment_name: {
          target_id: this.enrollmentEntityData['id'],
        },
      });

      this.displayProgressSpinner(false);
    }
  }

  signatureInit(elementRef: ElementRef<HTMLCanvasElement>, elementName: string) {
    let canvas = elementRef.nativeElement;
    let context = canvas.getContext('2d');

    if (!context) { return; }

    context.lineCap = 'round';
    context.strokeStyle = 'black';
    context.lineWidth = 3;

    let x = 0, y = 0;
    let isMouseDown = false;

    // Resize the canvas to match display size
    canvas.width = canvas.offsetWidth;
    canvas.height = canvas.offsetHeight;

    const stopDrawing = () => { isMouseDown = false; }

    const startDrawing = (event: MouseEvent | TouchEvent) => {
      isMouseDown = true;

      if (window.TouchEvent && event instanceof TouchEvent) { // Check for TouchEvent support
        const touch = event.touches[0] || event.changedTouches[0];
        x = touch.clientX - canvas.getBoundingClientRect().left;
        y = touch.clientY - canvas.getBoundingClientRect().top;
      } else {
        x = (event as MouseEvent).offsetX;
        y = (event as MouseEvent).offsetY;
      }

      if (elementName === 'student') {
        this.detectSignatureStudent = true;
      }
      if (elementName === 'studio') {
        this.detectSignatureStudio = true;
      }
      if (elementName === 'parent') {
        this.detectSignatureParent = true;
      }

    }

    const drawLine = (event: MouseEvent | TouchEvent) => {
      if (!isMouseDown) return;

      let newX, newY;

      if (window.TouchEvent && event instanceof TouchEvent) { // Check for TouchEvent support
        const touch = event.touches[0] || event.changedTouches[0];
        newX = touch.clientX - canvas.getBoundingClientRect().left;
        newY = touch.clientY - canvas.getBoundingClientRect().top;
      } else {
        newX = (event as MouseEvent).offsetX;
        newY = (event as MouseEvent).offsetY;
      }

      context.beginPath();
      context.moveTo(x, y);
      context.lineTo(newX, newY);
      context.stroke();
      x = newX;
      y = newY;
    }

    // Add event listeners for touch and mouse events.
    canvas.addEventListener('mousedown', startDrawing);
    canvas.addEventListener('mousemove', drawLine);
    canvas.addEventListener('mouseup', stopDrawing);
    canvas.addEventListener('mouseleave', stopDrawing);

    canvas.addEventListener('touchstart', startDrawing);
    canvas.addEventListener('touchmove', drawLine);
    canvas.addEventListener('touchend', stopDrawing);
    canvas.addEventListener('touchcancel', stopDrawing);

    // Additional pointer events for better compatibility
    canvas.addEventListener('pointerdown', startDrawing);
    canvas.addEventListener('pointermove', drawLine);
    canvas.addEventListener('pointerup', stopDrawing);
    canvas.addEventListener('pointerout', stopDrawing);
  }

  signatureClear(elementRef: ElementRef<HTMLCanvasElement>) {
    let canvas = elementRef;

    let context = canvas.nativeElement.getContext('2d');
    context.clearRect(0, 0, context.canvas.width, context.canvas.height)
  }

  signatureClearStudent() {
    this.signatureClear(this.signatureStudent);
    this.detectSignatureStudent = false;
  }

  signatureClearStudio() {
    this.signatureClear(this.signatureStudio);
    this.detectSignatureStudio = false;
  }

  signatureClearParent() {
    this.signatureClear(this.signatureParent);
    this.detectSignatureParent = false;
  }

  printPage(backPageOnly = false, promptPrint = false) {
    let printContents, popupWin, printContentsBackpage;

    // Check if essential data for backpage is loaded
    if (!this.studentAccountEntityData || !this.studioInformationConfig) {
      console.error('Print called before student or studio data loaded.');
      this._snackBar.open('Data required for printing is not yet loaded. Please wait and try again.', 'Close', { duration: 4000 });
      return; // Stop execution if data isn't ready
    }

    printContents = document.getElementById('enrollment-agreement')?.innerHTML;
    if (!printContents) {
      printContents = document.getElementById('enrollment-agreement-new')?.innerHTML;
    }

    // Prepare data object
    const saleDateSource = this._enrollmentEntityData?.field_sale_date?.[0]?.value || this.enrollmentForm.get('field_sale_date')?.value;
    const formattedSaleDate = saleDateSource ? new Date(saleDateSource).toLocaleDateString() : undefined;

    const backpageData = {
      // Use fallbacks directly in the object creation if needed, but the check above is primary
      studentName: this.studentAccountEntityData?.title || '[Student Name Missing]',
      studioName: this.studioInformationConfig?.field_studio_display_name?.[0]?.value || '[Studio Name Missing]',
      agreementDate: formattedSaleDate || '[Date Missing]',
      bondingInfo: this.studioInformationConfig?.field_studio_is_bonded || '' // Expects boolean/string from API
    };
    console.log('backpageData', backpageData);

    // Call the function with data
    printContentsBackpage = getEnrollmentAgreementBackpageContent(backpageData);

    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    if (popupWin) {
      popupWin.document.open();
      popupWin.document.write(`
        <html>
          <head>
            <title>Enrollment Copy</title>
            <style type="text/css">
              body {
                font-size:10px;
              }
              p {
                font-family: "Times New Roman";
              }

              .padding-main-divcls{
                padding: 5px;
              }

              .text-center{
                text-align: center
              }
              .width-full{
                width: 100%;
              }

              .box {
                  border-style: solid;
                  border-width: 1px;
                  width: 65px;
                  height: 100px;
                  float: right;
                  margin-right: 50px;
                  font-size: 10px;
                  padding: 5px;
              }
              .box-divcls {
                width: 100%;
                display: inline-block;
              }

              .TermsConditionTable, tr , td {
                padding: 4px !important;
              }
              tr, td {
                page-break-inside: avoid !important;
              }
              .display-none {
                display:none;
              }

              .page1 {
                min-height: calc(100vh - 100px);
                overflow: hidden;
              }

              .page2, .page3, .page4 { /* Apply base styles to all pages */
                font-size:10px;
                page-break-before: always; /* Ensure back pages start on new print pages */
              }
              .page2:first-child, .page3:first-child, .page4:first-child { /* Avoid break before the very first element if needed */
                 page-break-before: auto;
              }

              @media print {
                .print-d-none {
                  display: none !important; /* Hide elements with this class when printing */
                }
                /* Optional: You could add other print-specific styles here */
              }

            </style>
            <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha1/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-GLhlTQ8iRABdZLl6O3oVMWSktQOp6b7In1Zl3/Jr59b6EGGoI1aFkw7cmDA6j6gD" crossorigin="anonymous">
          </head>
            <body ${promptPrint ? 'onload="window.print();window.close();"' : ''}>
              <p class="text-center"><img style="max-width:350px;" src="assets/images/AMDCBlackHorizontalJPG.jpg" /></p>
              <div class="container-fluid">
                ${backPageOnly ? printContentsBackpage : printContents}
                ${!backPageOnly ? printContentsBackpage : ''}
              </div>
            </body>
        </html>
      `)
      popupWin.document.close();

      // Wait for the print window to load before printing and closing
      popupWin.onload = function () {
        setTimeout(function () {
          if (promptPrint) {
            popupWin.print();
            popupWin.close();
          }
        }, 500); // Adjust the delay as needed
      };
    } else {
        this._snackBar.open('Could not open print window. Please check browser pop-up settings.', 'Close', { duration: 5000 });
    }
  }

  calculateEstimatedTax(paymentAmount: number): number {
    const taxRate = this.enrollmentForm.get('field_tax_percentage').value / 100 || 0;
    return parseFloat((paymentAmount * taxRate).toFixed(2));
  }

  calculateTax(index: number) {
    const paymentControl = (this.enrollmentForm.get('field_scheduled_payments') as FormArray).at(index);
    const paymentAmount = paymentControl.get('field_payment_amount').value;
    const taxAmount = this.calculateEstimatedTax(paymentAmount);
    paymentControl.patchValue({ estimated_tax: taxAmount });
  }

  getPaymentStructureTypeLabel(): string {
    const type = this.entityData?.field_payments_structure_type;
    switch (type) {
      case 'paid_in_full':
        return 'Pay in full';
      case 'weekly':
        return 'Weekly';
      case 'monthly':
        return 'Monthly';
      case 'by_the_lesson':
        return 'By the lesson';
      default:
        return '';
    }
  }

  loadContactData() {
    if (this.studentAccountEntityData && this.studentAccountEntityData.field_contacts) {
      // The field_contacts might be an object with 'target_id' or a direct ID reference
      let contactId = null;

      if (typeof this.studentAccountEntityData.field_contacts === 'object') {
        // Check if it's an array with target_id
        if (Array.isArray(this.studentAccountEntityData.field_contacts) &&
          this.studentAccountEntityData.field_contacts.length > 0) {
          if (this.studentAccountEntityData.field_contacts[0].target_id) {
            contactId = this.studentAccountEntityData.field_contacts[0].target_id;
          } else if (this.studentAccountEntityData.field_contacts[0].id) {
            contactId = this.studentAccountEntityData.field_contacts[0].id;
          }
        } else if (this.studentAccountEntityData.field_contacts.target_id) {
          // Direct object with target_id
          contactId = this.studentAccountEntityData.field_contacts.target_id;
        } else if (this.studentAccountEntityData.field_contacts.id) {
          // Direct object with id
          contactId = this.studentAccountEntityData.field_contacts.id;
        }
      } else {
        // It might be a direct ID value
        contactId = this.studentAccountEntityData.field_contacts;
      }

      if (contactId) {
        console.log('Loading contact data for ID:', contactId);
        this._entityRESTService.getEntity('contacts', 'student_record', contactId)
          .subscribe(contactData => {
            // Store the contact data directly in studentAccountEntityData.field_contacts
            // This ensures backward compatibility with existing template references
            this.studentAccountEntityData.field_contacts = contactData;
            console.log('Contact data loaded:', contactData);
          }, error => {
            console.error('Error loading contact data:', error);
          });
      } else {
        console.warn('Could not determine contact ID from student account data');
      }
    } else {
      console.warn('No field_contacts found in student account data');
    }
  }

  /**
 * Updates the field_tos_accepted_date when the Terms of Service checkbox is checked
 * @param event The change event from the checkbox
 */
  updateTosAcceptedDate(event: Event) {
    const checkbox = event.target as HTMLInputElement;

    // Update the tosAccepted property immediately to update the UI
    this.tosAccepted = checkbox.checked;

    if (checkbox.checked) {
      // Format the date to match the required format without milliseconds
      const now = new Date();
      // Use the helper method to format the date
      const currentDatetime = this.formatDateForDrupal(now);

      // If we're in create mode, update the form value
      if (this.actionType === 'create') {
        this.enrollmentForm.patchValue({
          field_tos_accepted_date: currentDatetime
        });
        console.log('TOS accepted date updated in form:', currentDatetime);
      } else if (this.entityData?.id) {
        // If we're in edit mode, update the entity directly
        const values = {
          field_tos_accepted_date: currentDatetime
        };

        // Immediately update the local entityData for UI responsiveness
        if (this.entityData) {
          this.entityData.field_tos_accepted_date = currentDatetime;
        }

        this._entityRESTService.patchEntity(
          this.eckType,
          this.bundle,
          this.entityData.id,
          values
        ).subscribe(
          response => {
            console.log('TOS accepted date updated:', response);
            // this._snackBar.open('Terms of Service accepted', 'Close', {
            //   duration: 3000,
            // });
          },
          error => {
            console.error('Error updating TOS accepted date:', error);
            // Rollback the UI change if the API call fails
            if (this.entityData) {
              this.entityData.field_tos_accepted_date = null;
              this.tosAccepted = false;
            }
            this._snackBar.open('Error updating Terms of Service acceptance', 'Close', {
              duration: 3000,
            });
          }
        );
      }
    } else {
      // Checkbox is unchecked - clear the TOS accepted date

      // If we're in create mode, update the form value
      if (this.actionType === 'create') {
        this.enrollmentForm.patchValue({
          field_tos_accepted_date: null
        });
        console.log('TOS accepted date cleared in form');
      } else if (this.entityData?.id) {
        // If we're in edit mode, update the entity directly
        const values = {
          field_tos_accepted_date: null
        };

        // Immediately update the local entityData for UI responsiveness
        if (this.entityData) {
          this.entityData.field_tos_accepted_date = null;
        }

        this._entityRESTService.patchEntity(
          this.eckType,
          this.bundle,
          this.entityData.id,
          values
        ).subscribe(
          response => {
            console.log('TOS accepted date cleared:', response);
            // this._snackBar.open('Terms of Service acceptance withdrawn', 'Close', {
            //   duration: 3000,
            // });
          },
          error => {
            console.error('Error clearing TOS accepted date:', error);
            // Rollback in case of error
            this.tosAccepted = true;
            this._snackBar.open('Error updating Terms of Service acceptance', 'Close', {
              duration: 3000,
            });
          }
        );
      }
    }
  }

  /**
* Formats a date to the required format for the Drupal backend (YYYY-MM-DDThh:mm:ss)
* @param date The date to format
* @returns Formatted date string
*/
  formatDateForDrupal(date: Date): string {
    // Format the date in local time instead of UTC
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // Format as YYYY-MM-DDThh:mm:ss
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  }

  async onSubmitSignatures(action) {
    this.displayProgressSpinner(true);
    this.setStepperIndex(2);

    const toBlob = (canvasElement: HTMLCanvasElement): Promise<Blob> =>
      new Promise((resolve) => canvasElement.toBlob(resolve));

    const handleBlob = (fieldName: string, element: ElementRef<HTMLCanvasElement>) =>
      from(toBlob(element.nativeElement)).pipe(
        mergeMap((blob) =>
          this._drupalRESTService
            .uploadFile('packages', 'enrollment', fieldName, environment.name + '_' + fieldName + '.png', blob)
            .pipe(
              mergeMap((data) => {
                const values = {
                  [fieldName]: [
                    {
                      target_id: data['fid']?.[0]?.['value'],
                      description: '',
                    },
                  ],
                };

                if (typeof this.entityData?.['id'] !== 'undefined') {
                  return this._entityRESTService.patchEntity(
                    this.eckType,
                    this.bundle,
                    this.entityData['id'],
                    values
                  );
                }

                throw new Error('Missing entity ID.');
              })
            )
        )
      );

    let observables = [];

    // Check if student signature is not present before attempting to upload
    if (this.detectSignatureStudent && !this._enrollmentEntityData?.['field_signature']?.[0]?.['url']) {
      observables.push(handleBlob('field_signature', this.signatureStudent));
    }

    // Check if representative signature is not present before attempting to upload
    if (this.detectSignatureStudio && !this._enrollmentEntityData?.['field_representative_signature']?.[0]?.['url']) {
      observables.push(handleBlob('field_representative_signature', this.signatureStudio));
    }

    // Check if parent signature is not present before attempting to upload
    if (this.detectSignatureParent && this.signatureParent && this.signatureParent.nativeElement &&
      !this._enrollmentEntityData?.['field_parent_guardian_signature']?.[0]?.['url']) {
      // Check if there's actually something drawn on the canvas
      this.checkParentSignature(this.signatureParent.nativeElement);

      if (this.detectSignatureParent) {
        observables.push(handleBlob('field_parent_guardian_signature', this.signatureParent));
      }
    }

    if (observables.length > 0) {
      forkJoin(observables).subscribe(
        () => {
          this.referenceUploadedFileComplete(action);
          this.displayProgressSpinner(false);

          if (action === 'save_and_add') {
            this._snackBar.open('Signatures saved successfully', 'Close', {
              duration: 3000,
            });
            this.closeDialog();
          }
        },
        (err) => {
          console.error(err);
          this.displayProgressSpinner(false);
          this._snackBar.open('Error saving signatures', 'Close', {
            duration: 3000,
          });
        }
      );
    } else {
      this.displayProgressSpinner(false);

      if (action === 'save_and_add') {
        this._snackBar.open('No new signatures to save', 'Close', {
          duration: 3000,
        });
        this.closeDialog();
      } else if (action === 'save_and_pay') {
        this.referenceUploadedFileComplete(action);
      }
    }
  }

  checkParentSignature(canvas: HTMLCanvasElement) {
    // Get a snapshot of the canvas data
    const context = canvas.getContext('2d');
    if (!context) return;

    // Get image data to check if there's something drawn
    const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
    const data = imageData.data;

    // Check if the canvas has any non-white/non-transparent pixels
    let hasSignature = false;
    for (let i = 0; i < data.length; i += 4) {
      // If any pixel has a non-zero alpha value and is not white
      if (data[i + 3] > 0 && !(data[i] === 255 && data[i + 1] === 255 && data[i + 2] === 255)) {
        hasSignature = true;
        break;
      }
    }

    // Update detection state
    this.detectSignatureParent = hasSignature;
  }

  sendEmailNewEnrollment(enrollmentID) {
    this._drupalRESTService.httpGET('/api_rest/v1/sendNewEnrollmentEmail?enrollmentID=' + enrollmentID)
      .subscribe(
        data => {
          console.log('API response:', data);
          if (data['status'] === 'error') {
            const errorMessage = data['message'] || 'An error occurred while sending the email.';
            // Snackbar message that is centered.
            this._snackBar.open(errorMessage, 'Close', {
              duration: 3000,
              horizontalPosition: 'center',
            });
          } else {
            // Snackbar message that is centered.
            this._snackBar.open('Email sent successfully.', 'Close', {
              duration: 3000,
              horizontalPosition: 'center',
            });
          }
        },
        error => {
          console.error('Error sending email:', error);
          // Snackbar message that is centered.
          this._snackBar.open('An error occurred while sending the email.', 'Close', {
            duration: 3000,
            horizontalPosition: 'center',
          });
        }
      );
  }

  generateBackpageDisplayContent() {
    if (this.studentAccountEntityData && this.studioInformationConfig) {
        const saleDateSource = this._enrollmentEntityData?.field_sale_date?.[0]?.value || this.enrollmentForm.get('field_sale_date')?.value;
        const formattedSaleDate = saleDateSource ? new Date(saleDateSource).toLocaleDateString() : undefined;

        const backpageData = {
            studentName: this.studentAccountEntityData?.title || '[Student Name Missing]',
            studioName: this.studioInformationConfig?.field_studio_display_name?.[0]?.value || '[Studio Name Missing]',
            agreementDate: formattedSaleDate || '[Date Missing]',
            bondingInfo: this.studioInformationConfig?.field_studio_is_bonded || ''
        };
        this.backpageHtmlContent = getEnrollmentAgreementBackpageContent(backpageData);
    } else {
        // Handle case where data isn't ready yet
        this.backpageHtmlContent = '<p>Loading agreement details...</p>';
    }
}
}

import { Component, OnInit, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ViewTableComponent } from '../../base/view-table/view-table.component';
import { MatTableDataSource } from '@angular/material/table';
import moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilityService } from 'src/app/services/utility.service';
import { DialogService } from 'src/app/services/dialog.service';
import { EntityRESTService } from 'src/app/services/entity-rest.service';
import { FieldsService } from 'src/app/services/fields.service';
import { DrupalRESTService } from 'src/app/services/drupal-rest.service';
import { TaxonomyService } from 'src/app/services/taxonomy.service';
import { AuthRESTService } from 'src/app/services/auth-rest.service';
import { AuthService } from 'src/app/services/auth.service';
import { MenuRESTService } from 'src/app/services/menu-rest.service';
import { FormBuilder } from '@angular/forms';
import { RouteEventsService } from 'src/app/route-events.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { ConfigPagesService } from 'src/app/services/config-pages.service';
import { PackagesService } from 'src/app/services/packages.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from 'src/app/services/user.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Renderer2 } from '@angular/core';
import { PrintService } from 'src/app/services/print.service';
import { RefreshService } from 'src/app/services/refresh.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { EventServicesEntityComponent } from '../../forms/event-services-entity/event-services-entity.component';
import { StudentDashboardWrapperComponent } from '../../student/student-dashboard-wrapper/student-dashboard-wrapper.component';
import { NgForm } from '@angular/forms';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-sale-no-sale-report',
  templateUrl: './sale-no-sale-report.component.html',
  styleUrls: ['./sale-no-sale-report.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({
        height: '0px',
        minHeight: '0',
        overflow: 'hidden',
        opacity: '0',
        visibility: 'hidden'
      })),
      state('expanded', style({
        height: '*',
        opacity: '1',
        visibility: 'visible'
      })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class SaleNoSaleReportComponent extends ViewTableComponent implements OnInit {
  year: string;
  week: string;
  override WeeksForYearData: any[] = [];
  override LastFourYearsData: any[] = this.getLastFourYears();
  consumable: any;
  salesData: any[] = [];
  saleRecords: any[] = [];
  studentJourneyData: any[] = [];
  // Map to store enrollment details for quick lookup
  enrollmentDetailsMap: Map<string, any> = new Map();

  override displayedColumns: string[] = [
    'expand',
    'saleDate',
    'studentName',
    'categoryType',
    'packageName',
    'units',
    'enrollmentAmount',
    'paidAmount',
    'teachers',
    'closer',
    'status',
    'potentialOutcome'
  ];
  dateTimeRange: Date[] = [];
  isDateRangeActive: boolean = false;

  // For expandable rows
  isExpansionDetailRow = (index: number, row: any) => row.hasOwnProperty('expanded');
  expandedElement: any | null;
  expandedElements: { [key: string]: boolean } = {};
  alwaysExpanded: boolean = false; // Set to false to have rows not expanded by default

  // Add these properties
  override autoCompleteStudentOptions: any[] = [];
  selectedStudentId: string = '';

  // Service attempt related properties
  serviceAttemptsByStudent: { [studentId: string]: any[] } = {};
  serviceTypeOptions: any[] = [];
  serviceStatusOptions: any[] = [];
  executiveOptions: any[] = [];

  // New properties for the quick service creation
  selectedStatus: string = '113'; // Default to Pending Status
  currentExecutive: string = '';
  currentUserId: string = '';
  currentDateTime: Date = new Date();

  // Print-related properties
  isPrinting: boolean = false;

  StudentDashboardWrapperComponent = StudentDashboardWrapperComponent;

  constructor(
    public override _refreshService: RefreshService,
    private _printService: PrintService,
    public override _dialogService: DialogService,
    public override _entityRESTService: EntityRESTService,
    public override _utilityService: UtilityService,
    public override _fieldsService: FieldsService,
    public override _drupalRESTService: DrupalRESTService,
    public override _taxonomyService: TaxonomyService,
    public override _router: Router,
    public override _activatedRoute: ActivatedRoute,
    public override _authRESTService: AuthRESTService,
    public override _authService: AuthService,
    public override _menuRESTService: MenuRESTService,
    public override _formBuilder: FormBuilder,
    public override _routeEventsService: RouteEventsService,
    public override _permissionsService: PermissionsService,
    public override _renderer2: Renderer2,
    public override _configPages: ConfigPagesService,
    public override _packagesService: PackagesService,
    public override _snackBar: MatSnackBar,
    public override _userRESTService: UserService,
    public override _el: ElementRef,
    public override _changeDetectorRef: ChangeDetectorRef,
    public override _sanitizer: DomSanitizer
  ) {
    super(
      _refreshService,
      _dialogService,
      _entityRESTService,
      _utilityService,
      _fieldsService,
      _drupalRESTService,
      _taxonomyService,
      _router,
      _activatedRoute,
      _authRESTService,
      _authService,
      _menuRESTService,
      _formBuilder,
      _routeEventsService,
      _permissionsService,
      _renderer2,
      _configPages,
      _packagesService,
      _snackBar,
      _userRESTService,
      _el,
      _changeDetectorRef,
      _sanitizer
    );
  }

  getCurrentWeek() {
    return moment().isoWeek().toString();
  }

  override ngOnInit(): void {
    // Load instructor data first
    this._fieldsService.getInstructorData(true).then(() => {
      // Then load service options and other data
      this.loadServiceTypeOptions();
      this.loadServiceStatusOptions();
      this.loadExecutiveOptions();
      this.getCurrentUserInfo();
      this.setCurrentTime();
    });

    this._activatedRoute.queryParams.subscribe(params => {
      const currentMoment = moment();
      if (params['date_from'] && params['date_to']) {
        this.dateTimeRange = [
          new Date(params['date_from']),
          new Date(params['date_to'])
        ];
        this.isDateRangeActive = true;
        this.year = currentMoment.format("YYYY");
        this.week = this.getCurrentWeek();
        this.onYearChange(this.year);
        this.loadData();
      } else {
        this.isDateRangeActive = false;
        this.year = params['year'] || currentMoment.format("YYYY");
        this.week = params['week'] || this.getCurrentWeek();
        this.onYearChange(this.year);
        this.loadData();
      }
    });
  }

  onYearChange(year: string) {
    this.year = year;
    this.WeeksForYearData = this._fieldsService.WeeksForYearData(Number(this.year));
  }

  override dataSource = new MatTableDataSource<any>([]);

  override ngAfterContentInit(): void {
    this._activatedRoute.queryParams.subscribe(data => {
      const currentMoment = moment();
      this.week = data['week'] || currentMoment.isoWeek().toString();
      this.year = data['year'] || currentMoment.format("YYYY");
      this.loadData();
    });
  }

  dateRangeChange(event: any) {
    if (event.value && Array.isArray(event.value)) {
      this.dateTimeRange = event.value;
      this.isDateRangeActive = true;
      this.loadData();
    }
  }

  loadData() {
    const params = [];

    if (this.isDateRangeActive && this.dateTimeRange?.length > 0) {
      params.push(
        { parameter: 'date_from', value: moment(this.dateTimeRange[0]).format('YYYY-MM-DD') },
        { parameter: 'date_to', value: moment(this.dateTimeRange[1]).format('YYYY-MM-DD') }
      );
    } else if (this.week && this.year) {
      params.push(
        { parameter: 'week', value: this.week.toString() },
        { parameter: 'year', value: this.year }
      );
    }

    // Add student filter if selected
    if (this.selectedStudentId) {
      params.push({ parameter: 'student_id', value: this.selectedStudentId });
    }

    this.getData(params);
  }

  override getData(params: { parameter: string; value: string }[]) {
    this.ShowProgressBar = true;
    // Clear existing data
    this.salesData = [];
    this.saleRecords = [];
    this.studentJourneyData = [];
    this.enrollmentDetailsMap.clear();
    this.expandedElements = {};

    if (!params.some(p => p.parameter === 'date_from')) {
      params.push({ parameter: 'weekYear', value: this.year.toString() });
      params.push({ parameter: 'weekNumber', value: this.week.toString() });
    }

    this._drupalRESTService.httpGET('/api_rest/v1/loadSaleNoSaleReport', params).subscribe(
      data => {
        this.ShowProgressBar = false;
        // Process sales data
        this.salesData = data?.['sales_data'] || [];
        this.studentJourneyData = data?.['student_journey_data'] || [];

        // Create a map for quick lookup of enrollment details
        this.salesData.forEach(sale => {
          if (sale.id) {
            this.enrollmentDetailsMap.set(sale.id, this.formatEnrollmentDetails(sale));
          }
        });

        // Only keep 'Sale' records or show all records for this report
        this.saleRecords = this.salesData; // Show all records instead of filtering

        // Add the student_id from student_journey_data to corresponding sales records
        this.saleRecords.forEach(record => {
          // Find matching student journey data by student name
          const journey = this.studentJourneyData.find(j => j.student_name === record.student_name);
          if (journey) {
            record.student_id = journey.student_id;

            // Ensure contact_id is available both directly and as student_contact_id
            // This makes the component more robust by prioritizing direct API values but falling back to journey data
            record.contact_id = record.contact_id || journey.contact_id;  // Keep existing contact_id if present
            record.student_contact_id = journey.contact_id || record.contact_id; // Also assign to student_contact_id for legacy support
          } else {
            // Generate a unique ID if no matching journey data found
            record.student_id = `temp_${Math.random().toString(36).substring(2, 9)}`;
          }

          // Add the expanded property to each element and set it based on alwaysExpanded setting
          record.expanded = false;
          this.expandedElements[record.student_id] = this.alwaysExpanded;
        });

        this.dataSource = new MatTableDataSource(this.saleRecords);
      },
      error => this.handleError(error)
    );
  }

  private getLastFourYears(): any[] {
    const currentYear = moment().year();
    return Array.from({ length: 4 }, (_, i) => ({
      key: (currentYear - i).toString(),
      value: (currentYear - i).toString()
    }));
  }

  // Toggle expansion for a row
  toggleRow(element: any) {
    if (!element || !element.student_id) {
      return;
    }

    const studentId = element.student_id;

    // Make sure it's initialized
    if (this.expandedElements[studentId] === undefined) {
      this.expandedElements[studentId] = false;
    }

    // Toggle the value
    this.expandedElements[studentId] = !this.expandedElements[studentId];

    // Force change detection to ensure the UI updates
    this._changeDetectorRef.detectChanges();

    // Pre-load service attempts when expanding
    if (this.expandedElements[studentId]) {
      this.getServiceAttempts(studentId);
    }
  }

  // Check if a student has journey data
  getStudentJourney(studentId: string): any {
    return this.studentJourneyData.find(journey => journey.student_id === studentId);
  }

  // Format enrollment details for better display
  formatEnrollmentDetails(enrollment: any): any {
    if (!enrollment) return null;

    return {
      date: enrollment.sale_date ? moment(enrollment.sale_date).format('MMM D, YYYY') : 'N/A',
      package: enrollment.package_name || 'N/A',
      amount: enrollment.enrollment_amount ? `$${parseFloat(enrollment.enrollment_amount).toFixed(2)}` : '$0.00',
      paid: enrollment.paid_amount ? `$${parseFloat(enrollment.paid_amount).toFixed(2)}` : '$0.00',
      status: enrollment.status || 'N/A',
      teacher: enrollment.teachers || 'N/A',
      closer: enrollment.closer || 'N/A',
      outcome: enrollment.potential_outcome || 'N/A'
    };
  }

  // Get enrollment details by ID
  getEnrollmentDetails(enrollmentId: string): any {
    if (!enrollmentId) return null;

    // Find the student journey that contains this enrollment
    const journey = this.studentJourneyData.find(journey => {
      return journey.enrollment_details && journey.enrollment_details[enrollmentId];
    });

    if (journey && journey.enrollment_details[enrollmentId]) {
      const details = journey.enrollment_details[enrollmentId];
      return {
        date: details.date ? moment(details.date).format('MMM D, YYYY') : 'N/A',
        package: details.package || 'N/A',
        amount: details.amount ? `$${parseFloat(details.amount).toFixed(2)}` : '$0.00',
        status: details.status || 'N/A',
        teacher: details.teachers || 'N/A'
      };
    }

    // If not found in journey data, check salesData
    if (this.enrollmentDetailsMap.has(enrollmentId)) {
      return this.enrollmentDetailsMap.get(enrollmentId);
    }

    // If not found in salesData, return basic info
    return {
      id: enrollmentId,
      date: 'Not in current view',
      package: 'Load the date range containing this enrollment to see details'
    };
  }

  override onPrint() {
    // Set printing flag
    this.isPrinting = true;

    // Preload service attempts for all students
    this.prepareServiceAttemptsForPrint().then(() => {
      // Build HTML content directly from the data - using minimal structure and compact formatting
      let content = `
        <h2 class="report-title">Sale/No Sale Report</h2>
        <p class="report-period">
          ${this.isDateRangeActive && this.dateTimeRange?.length > 0
            ? `Date Range: ${moment(this.dateTimeRange[0]).format('MM/DD/YY')} to ${moment(this.dateTimeRange[1]).format('MM/DD/YY')}`
            : `Week: ${this.week}, Year: ${this.year}`
          }
        </p>
      `;

      // Sales Data Section - with reduced columns for compactness
      content += `
        <div class="section">
          <h3>Sales Data</h3>
          ${this.saleRecords.length > 0 ? `
            <table class="print-table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Student</th>
                  <th>Type</th>
                  <th>Package</th>
                  <th>Amount</th>
                  <th>Teachers</th>
                  <th>Closer</th>
                </tr>
              </thead>
              <tbody>
                ${this.saleRecords.map(item => `
                  <tr>
                    <td>${item.sale_date ? moment(item.sale_date).format('MM/DD/YY') : ''}</td>
                    <td>${item.student_name || ''}</td>
                    <td>${item.category_type || ''}</td>
                    <td>${item.package_name || ''}</td>
                    <td>${item.enrollment_amount ? '$' + parseFloat(item.enrollment_amount).toFixed(2) : ''}</td>
                    <td>${item.teachers || ''}</td>
                    <td>${item.closer || ''}</td>
                  </tr>
                `).join('')}
              </tbody>
            </table>
          ` : '<p>No sales found for this period</p>'}
        </div>
      `;

      // Student Details Section - only if there are student records
      if (this.saleRecords.length > 0) {
        content += `<div class="student-details-section print-compact">${this.generateStudentDetailsForPrint()}</div>`;
      }

      // Create a formatted title
      let title = 'Sale/No Sale Report';
      if (this.isDateRangeActive && this.dateTimeRange?.length > 0) {
        const startDate = moment(this.dateTimeRange[0]).format('MM/DD/YY');
        const endDate = moment(this.dateTimeRange[1]).format('MM/DD/YY');
        title += ` - ${startDate} to ${endDate}`;
      } else {
        title += ` - Week ${this.week}, ${this.year}`;
      }

      // Send content to print service with custom print styles
      const printStyles = `
        @page { size: letter portrait; margin: 0.4in; }
        .print-table { font-size: 11px; }
        .print-table th, .print-table td { padding: 4px; }
        .print-compact h4 { font-size: 14px; margin: 12px 0 6px 0; }
        .print-compact h5 { font-size: 12px; margin: 8px 0 4px 0; }
        .print-compact table { font-size: 10px; }
        .print-compact th, .print-compact td { padding: 3px; }
        .student-section { border-top: 1px solid #ddd; margin-top: 10px; }
      `;

      this._printService.print(content, {
        title,
        showSchoolName: true,
        promptPrint: true,
        styles: printStyles
      });

      // Reset printing flag after printing is initiated
      this.isPrinting = false;
    }).catch(error => {
      console.error('Error while preparing print data:', error);
      this._snackBar.open('Error preparing print data. Please try again.', 'Close', { duration: 5000 });
      this.isPrinting = false;
    });
  }

  /**
   * Generate student details section for printing - optimized for compact layout
   */
  private generateStudentDetailsForPrint(): string {
    let detailsContent = '';

    // Process each student
    this.saleRecords.forEach(record => {
      if (!record.student_id) return;

      const journey = this.getStudentJourney(record.student_id);
      const serviceAttempts = this.serviceAttemptsByStudent[record.student_id] || [];

      // Only include students with either journey data or service attempts
      if ((!journey && serviceAttempts.length === 0) ||
          (journey && !this.hasAnyEnrollment(journey) && serviceAttempts.length === 0)) {
        return;
      }

      // Student Header
      detailsContent += `
        <div class="student-section">
          <h4>${record.student_name || 'Student'}</h4>
      `;

      // Enrollment History Section - with abbreviated headings
      if (journey && this.hasAnyEnrollment(journey)) {
        detailsContent += `
          <h5>Enrollment History</h5>
          <table>
            <thead>
              <tr>
                <th>Type</th>
                <th>Date</th>
                <th>Package</th>
                <th>Amount</th>
                <th>Teachers</th>
              </tr>
            </thead>
            <tbody>
        `;

        // Add enrollment rows - note the removal of ID column and abbreviation of type names
        if (journey.de_enrollment) {
          detailsContent += this.generateEnrollmentRowCompact(journey.de_enrollment, 'DE');
        }
        if (journey.pre_ori_enrollment) {
          detailsContent += this.generateEnrollmentRowCompact(journey.pre_ori_enrollment, 'Pre-Orig');
        }
        if (journey.ori_enrollment) {
          detailsContent += this.generateEnrollmentRowCompact(journey.ori_enrollment, 'Original');
        }
        if (journey.ext_enrollment) {
          detailsContent += this.generateEnrollmentRowCompact(journey.ext_enrollment, 'Extension');
        }
        if (journey.ren_enrollment) {
          detailsContent += this.generateEnrollmentRowCompact(journey.ren_enrollment, 'Renewal');
        }
        if (journey.current_enrollment) {
          detailsContent += this.generateEnrollmentRowCompact(journey.current_enrollment, 'Current');
        }

        detailsContent += `
            </tbody>
          </table>
        `;
      }

      // Service Attempts Section - with compact format
      if (serviceAttempts.length > 0) {
        detailsContent += `
          <h5>Service Attempts</h5>
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Type</th>
                <th>Status</th>
                <th>Executive</th>
                <th>Notes</th>
              </tr>
            </thead>
            <tbody>
        `;

        serviceAttempts.forEach(attempt => {
          const dateFormatted = attempt.date ? moment(attempt.date).format('MM/DD/YY h:mm a') : 'N/A';
          detailsContent += `
            <tr>
              <td>${dateFormatted}</td>
              <td>${attempt.serviceType || ''}</td>
              <td>${attempt.status || ''}</td>
              <td>${attempt.executive || ''}</td>
              <td>${attempt.notes || ''}</td>
            </tr>
          `;
        });

        detailsContent += `
            </tbody>
          </table>
        `;
      }

      // Close student section
      detailsContent += `</div>`;
    });

    return detailsContent || '<p>No detailed student information available.</p>';
  }

  /**
   * Generate an enrollment row for the print output - compact version
   */
  private generateEnrollmentRowCompact(enrollmentId: string, type: string): string {
    const details = this.getEnrollmentDetails(enrollmentId);
    return `
      <tr>
        <td><strong>${type}</strong></td>
        <td>${details?.date ? moment(details?.date, 'MMM D, YYYY').format('MM/DD/YY') : 'N/A'}</td>
        <td>${details?.package || 'N/A'}</td>
        <td>${details?.amount || '$0.00'}</td>
        <td>${details?.teacher || 'N/A'}</td>
      </tr>
    `;
  }

  /**
   * Prepare service attempts data for all students before printing
   */
  private async prepareServiceAttemptsForPrint(): Promise<void> {
    // Display progress indicator
    this.ShowProgressBar = true;

    // Expand all rows to ensure we load all student data
    this.expandAllRows();

    try {
      // Create a promise for each student to load their service attempts
      const loadPromises = this.saleRecords
        .filter(record => record.student_id)
        .map(record => {
          return new Promise<void>((resolve) => {
            // Check if we already have service attempts for this student
            if (this.serviceAttemptsByStudent[record.student_id]?.length > 0) {
              resolve(); // Already loaded
              return;
            }

            // Load service attempts for this student
            this._drupalRESTService.httpGET('/api_rest/v1/no_sale_report/services', [
              { parameter: 'student_id', value: record.student_id }
            ]).subscribe(
              data => {
                if (Array.isArray(data)) {
                  this.serviceAttemptsByStudent[record.student_id] = data.map(service => ({
                    id: service.id,
                    date: service.field_date_and_time,
                    serviceType: service.field_type_name || 'Unknown',
                    status: service.field_status_name || 'Unknown',
                    statusColor: service.field_status_color || '#000000',
                    executive: service.field_executive_name || 'Unknown',
                    notes: service.field_notes || '',
                    raw: service
                  }));
                } else {
                  this.serviceAttemptsByStudent[record.student_id] = [];
                }
                resolve();
              },
              error => {
                console.error(`Error loading service attempts for student ${record.student_id}:`, error);
                this.serviceAttemptsByStudent[record.student_id] = [];
                resolve(); // Resolve even on error to continue with printing
              }
            );
          });
        });

      // Wait for all service attempts to be loaded
      await Promise.all(loadPromises);
    } catch (error) {
      console.error('Error in prepareServiceAttemptsForPrint:', error);
      throw error;
    } finally {
      // Hide progress indicator
      this.ShowProgressBar = false;
    }
  }

  /**
   * Expand all rows for printing
   */
  private expandAllRows(): void {
    this.saleRecords.forEach(record => {
      if (record.student_id) {
        this.expandedElements[record.student_id] = true;
        // Pre-load service attempts when expanding
        this.getServiceAttempts(record.student_id);
      }
    });
  }

  override resetForm() {
    this.dateTimeRange = [];
    this.isDateRangeActive = false;
    this.selectedStudentId = '';
    this.autoCompleteStudentOptions = [];

    // Reset the form to clear all inputs
    if (this.form) {
      this.form.resetForm();
    }

    const currentMoment = moment();
    this.week = this.getCurrentWeek();
    this.year = currentMoment.format("YYYY");
    this.onYearChange(this.year);
    this.loadData();
  }

  // Add these methods
  override onAutocompleteStudent(event: any) {
    const searchText = event.target.value;
    if (searchText && searchText.length > 2) {
      // Use the same method as EnrollmentsViewComponent through UtilityService
      let term: string = searchText;
      let eckType: string = "student_accounts";
      let bundle: string = "student_account";
      let field: string = "title";

      this._utilityService.getAMTAbstractAutocomplete(term, eckType, bundle, field).subscribe(
        (data: any) => {
          this.autoCompleteStudentOptions = data.map((item: any) => ({
            label: item.label,
            value: item.value
          }));
        },
        error => this.handleError(error)
      );
    }
  }

  override redirectUserProfile(studentInput: HTMLInputElement) {
    const selectedStudent = this.autoCompleteStudentOptions.find(
      option => option.label === studentInput.value
    );
    if (selectedStudent) {
      this._router.navigate(['/student-accounts', selectedStudent.value]);
    }
  }

  override onSubmit(form: any) {
    if (form && form.value) {
      const studentName = form.value.title;
      const selectedStudent = this.autoCompleteStudentOptions.find(
        option => option.label === studentName
      );
      this.selectedStudentId = selectedStudent ? selectedStudent.value : '';
    }
    this.loadData();
  }

  /**
   * Load service type options from taxonomy
   */
  loadServiceTypeOptions() {
    if (Array.isArray(this._taxonomyService.service_type)) {
      this.serviceTypeOptions = (this._taxonomyService.service_type as any[]).map(type => ({
        id: type.id,
        name: type.name
      }));
    } else {
      this.serviceTypeOptions = [];
    }
  }

  /**
   * Load service status options from taxonomy
   */
  loadServiceStatusOptions() {
    if (Array.isArray(this._taxonomyService.service_status)) {
      this.serviceStatusOptions = (this._taxonomyService.service_status as any[]).map(status => ({
        id: status.id,
        name: status.name,
        color: status.field_color?.color || '#000000'
      }));
    } else {
      this.serviceStatusOptions = [];
    }
  }

  /**
   * Load executive options (users)
   */
  loadExecutiveOptions() {
    // Replace the direct API call with the fieldsService method
    if (this._fieldsService.instructorsExecutive()) {
      // If data is already available, use it
      this.executiveOptions = this._fieldsService.instructorsExecutive().map((user: any) => ({
        id: user.uid,
        name: user.value
      }));
    } else {
      // If data is not available, fetch it
      this._fieldsService.getInstructorData(true).then(() => {
        this.executiveOptions = this._fieldsService.instructorsExecutive().map((user: any) => ({
          id: user.uid,
          name: user.value
        }));
      });
    }
  }

  /**
   * Get service attempts for a student
   * @param studentId Student ID
   * @returns Array of service attempts
   */
  getServiceAttempts(studentId: string): any[] {
    if (!studentId) {
      console.warn('Cannot get service attempts: No student ID provided');
      return [];
    }

    // Check if we already have data cached or need to load it
    if (!this.serviceAttemptsByStudent[studentId]) {
      // Load data from API, but only if not already loading
      this.loadServiceAttemptsForStudent(studentId);
      // Return empty array while loading
      return [];
    }

    return this.serviceAttemptsByStudent[studentId];
  }

  /**
   * Load service attempts for a specific student from the API
   * @param studentId Student ID
   */
  loadServiceAttemptsForStudent(studentId: string) {
    if (!studentId) {
      console.warn('Cannot load service attempts: No student ID provided');
      return;
    }

    // Initialize with empty array if not already set
    if (!this.serviceAttemptsByStudent[studentId]) {
      this.serviceAttemptsByStudent[studentId] = [];
    }

    this._drupalRESTService.httpGET('/api_rest/v1/no_sale_report/services', [
      { parameter: 'student_id', value: studentId }
    ]).subscribe(
      data => {
        if (Array.isArray(data)) {
          const formattedAttempts = data.map(service => ({
            id: service.id,
            date: service.field_date_and_time,
            serviceType: service.field_type_name || 'Unknown',
            status: service.field_status_name || 'Unknown',
            statusColor: service.field_status_color || '#000000',
            executive: service.field_executive_name || 'Unknown',
            notes: service.field_notes || '',
            raw: service
          }));

          this.serviceAttemptsByStudent[studentId] = formattedAttempts;
        } else {
          console.warn(`Received non-array data for student ${studentId}:`, typeof data);
          this.serviceAttemptsByStudent[studentId] = [];
        }

        // Force Angular to detect the changes
        this._changeDetectorRef.detectChanges();
      },
      error => {
        this.handleError(error);
        this.serviceAttemptsByStudent[studentId] = [];
        this._changeDetectorRef.detectChanges();
      }
    );
  }

  /**
   * Open the service dialog to create a new service
   * @param studentId Student ID
   */
  openServiceDialog(studentId: string) {
    if (!studentId) {
      this._snackBar.open('No student selected', 'Close', { duration: 3000 });
      return;
    }

    // Get student details
    const student = this.studentJourneyData.find(j => j.student_id === studentId);
    if (!student) {
      this._snackBar.open('Student not found', 'Close', { duration: 3000 });
      return;
    }

    // Prepare data for the dialog
    const dialogData = {
      EntityID: null,
      fieldsData: {
        '___field_student': {
          id: studentId,
          title: student.student_name
        }
      }
    };

    // Open dialog
    this._dialogService.openDialog(EventServicesEntityComponent, dialogData).afterClosed().subscribe(result => {
      if (result) {
        // Reload service attempts after dialog is closed
        this.loadServiceAttemptsForStudent(studentId);
        this._snackBar.open('Service attempt created successfully', 'Close', { duration: 3000 });
      }
    });
  }

  /**
   * Edit a service attempt
   * @param serviceId Service ID to edit
   */
  editServiceAttempt(serviceId: string) {
    if (!serviceId) return;

    console.log('Editing service attempt:', serviceId);

    this._dialogService.openDialog(EventServicesEntityComponent, "defaultWithData", {
      data: {
        EntityID: serviceId,
        eckType: 'events',
        bundle: 'services',
        action: 'edit'
      }
    }).afterClosed().subscribe(result => {
      console.log('Dialog closed with result:', result);
      // Always refresh regardless of result to ensure we have the latest data
      console.log('Refreshing service attempts for all students...');

      // Reload all service attempts that are currently cached
      if (this.serviceAttemptsByStudent) {
        Object.keys(this.serviceAttemptsByStudent).forEach(studentId => {
          console.log('Refreshing service attempts for student:', studentId);
          // Clear cached data first to force a fresh load
          delete this.serviceAttemptsByStudent[studentId];
          // Then reload from API
          this.loadServiceAttemptsForStudent(studentId);
        });
      }

      // Force Angular to detect changes
      this._changeDetectorRef.detectChanges();

      if (result) {
        this._snackBar.open('Service attempt updated successfully', 'Close', { duration: 3000 });
      }
    });
  }

  /**
   * Get the contact entity for a student account
   * @param studentId Student account ID
   * @returns Promise that resolves to the contact entity ID
   */
  async getStudentContact(studentId: string): Promise<string> {
    try {
      const data: any = await firstValueFrom(
        this._entityRESTService.getEntity('student_accounts', 'student_account', studentId)
      );

      // Check if the student has contacts
      if (data && data.field_contacts) {
        // Support for couples (multiple contacts)
        if (Array.isArray(data.field_contacts)) {
          // Return the first contact ID
          if (data.field_contacts.length > 0) {
            return data.field_contacts[0].id;
          }
        } else {
          // For a single contact
          return data.field_contacts.id;
        }
      }
      throw new Error('No contacts found for this student');
    } catch (error) {
      console.error('Error getting student contact:', error);
      throw error;
    }
  }

  /**
   * Create a service attempt using the quick form
   * @param form Quick service form
   * @param studentId Student ID
   */
  async createQuickServiceAttempt(form: NgForm, studentId: string) {
    if (!form.valid || !studentId) {
      this._snackBar.open('Please fill all required fields', 'Close', { duration: 3000 });
      return;
    }

    // Get student details
    const student = this.studentJourneyData.find(j => j.student_id === studentId);
    if (!student) {
      this._snackBar.open('Student not found', 'Close', { duration: 3000 });
      return;
    }

    // Ensure we have a valid executive selected
    if (!this.currentExecutive) {
      this._snackBar.open('No executive selected. Please select an executive.', 'Close', { duration: 3000 });
      return;
    }

    // Format the date and time correctly for the API
    let formattedDateTime = '';
    if (this.currentDateTime) {
      // Format as YYYY-MM-DDTHH:MM:SS (the format API expects)
      formattedDateTime = moment(this.currentDateTime).format('YYYY-MM-DDTHH:mm:ss');
    } else {
      formattedDateTime = moment().format('YYYY-MM-DDTHH:mm:ss');
    }

    try {
      // Get the contact ID for the student account
      const contactId = await this.getStudentContact(studentId);

      // Create attendee data for field_student with proper contact reference
      const attendeeData = {
        title: student.student_name,
        type: 'attendees',
        bundle: 'attendance',
        field_student_account: studentId,
        field_students: contactId // Using the contact entity ID
      };

      // Create the service with the structured attendee data
      const serviceData = {
        title: `Service for ${student.student_name}`,
        field_type: form.value.serviceType,
        field_status: this.selectedStatus,
        field_executive: this.currentExecutive,
        field_date_and_time: formattedDateTime,
        field_notes: form.value.notes || '',
        field_student: [attendeeData] // Sending as an array of attendees
      };

      this._entityRESTService.createEntity('events', 'services', serviceData).subscribe(
        serviceResponse => {
          this._snackBar.open('Service attempt created successfully', 'Close', { duration: 3000 });
          form.resetForm();

          // Reset form but keep the current selections
          setTimeout(() => {
            // Reinitialize the form with current values
            form.form.patchValue({
              serviceType: '',
              notes: ''
            });
            this.setCurrentTime();
          });

          this.loadServiceAttemptsForStudent(studentId);
        },
        error => {
          this.handleError(error);
          this._snackBar.open('Failed to create service attempt: ' + (error.error?.message || error.message || 'Unknown error'), 'Close', { duration: 5000 });
        }
      );
    } catch (error) {
      this._snackBar.open('Failed to get contact for student: ' + error.message, 'Close', { duration: 5000 });
    }
  }

  /**
   * Get current user information
   */
  getCurrentUserInfo() {
    // Check if user is already authenticated
    if (this._authService.is_user_authenticated() && this._authService.current_user) {
      this.currentUserId = this._authService.current_user['uid'];

      // Set the currentExecutive value to the current user's ID
      this.currentExecutive = this._authService.current_user['uid'];

      // Update the selected status to "Sale" (106) by default
      this.selectedStatus = '106'; // Default to Sale status
    }
  }

  /**
   * Set the status for quick service creation
   * @param statusId Status ID to set (106 for Sale, 107 for No Sale)
   */
  setQuickStatus(statusId: string) {
    this.selectedStatus = statusId;
  }

  /**
   * Set the current time for the date picker
   */
  setCurrentTime() {
    // Set to current time rounded to nearest 15 minutes
    const now = new Date();
    const minutes = now.getMinutes();
    const roundedMinutes = Math.floor(minutes / 15) * 15;

    const roundedDate = new Date(now);
    roundedDate.setMinutes(roundedMinutes);
    roundedDate.setSeconds(0);
    roundedDate.setMilliseconds(0);

    this.currentDateTime = roundedDate;
  }

  /**
   * Check if a student journey has any enrollment records
   */
  private hasAnyEnrollment(journey: any): boolean {
    return !!(journey.de_enrollment || journey.pre_ori_enrollment ||
             journey.ori_enrollment || journey.ext_enrollment ||
             journey.ren_enrollment || journey.current_enrollment);
  }
}

<!-- signature-enrollment.component.html -->
<app-entity-mat-dialog-title
	title="Sign Enrollment"></app-entity-mat-dialog-title>

<div [ngClass]="{
        'loading-shade': showProgressSpinner
     }">

	<div mat-dialog-content>

		<mat-progress-spinner class="matProgressSpinnerCentered"
			*ngIf="showProgressSpinner" mode="indeterminate"></mat-progress-spinner>

		<form [formGroup]="this?.agreementFormGroup">

			<input class="d-none" formControlName="field_nothing">
			<!-- <input formControlName="firstName"> -->

			<!-- Use the new enrollment-agreement-display component -->
			<app-enrollment-agreement-display
				[entityData]="entityData"
				[studentAccountEntityData]="studentAccountEntityData"
				[studioInformationConfig]="studioInformationConfig"
				[enrollmentForm]="enrollmentForm"
				[displaySignatures]="false"
				[displayPrintButton]="false"
				[displayEmailButton]="false"
				[_enrollmentEntityData]="_enrollmentEntityData">
			</app-enrollment-agreement-display>

			<p class="text-center">
				<small><a class="cursor-pointer" (click)="printPage(true, false)">Click here
						to view back of agreement (fully included and opened when printed or
						emailed)</a></small>
			</p>

			<div class="container">
				<!-- Terms of Service Agreement -->
				<div class="row mt-3">
					<div class="col-12">
						<div class="form-check mt-3">
							<input class="form-check-input" type="checkbox" id="termsCheck"
								[checked]="tosAccepted"
								(change)="updateTosAcceptedDate($event)">
							<label class="form-check-label" for="termsCheck">
								This agreement is subject to and will be governed by the Terms of
								Service, which are incorporated in this agreement by reference.
								By executing this agreement, you acknowledge that you have read the Terms
								of Service and agree to abide by them.
								All references to "this agreement" mean this agreement together with the
								attached <a class="cursor-pointer" (click)="printPage(true, false)">Terms
									of Service</a>.
							</label>
						</div>

						<div *ngIf="tosAccepted" class="mt-2 ps-4 text-success fst-italic small">
							<i class="fas fa-check-circle me-1"></i> Terms of Service accepted on {{
							(entityData?.field_tos_accepted_date ||
							enrollmentForm.get('field_tos_accepted_date')?.value) |
							date:'MMM d, y, h:mm a':'local' }}
						</div>

						<div
							*ngIf="!tosAccepted && (detectSignatureStudio || detectSignatureStudent)"
							class="mt-2 ps-4 text-danger fst-italic small">
							<i class="fas fa-exclamation-circle me-1"></i> You must accept the Terms
							of Service to proceed
						</div>
					</div>
				</div>
			</div>

			<div class="row pb-3 pt-3">
				<p *ngIf="this.entityData?.['field_sale_date']">Signed on: {{
					this.entityData?.['field_sale_date'] | date }}</p>

				<div class="col-6">
					<div class="signature-box p-3 border rounded">
						<p class="fw-bold mb-2">Studio Owner:</p>
						<div class="signature-container bg-light p-1 border rounded"
							style="min-height: 125px;">
							<canvas
								*ngIf="this._enrollmentEntityData?.['field_representative_signature']?.[0]?.['url'] == null"
								width="341px" height="125px" class="signature-canvas"
								#SignatureStudio></canvas>

							<div
								*ngIf="this._enrollmentEntityData?.['field_representative_signature']?.[0]?.['url']">
								<img class="img-fluid"
									[src]="this._enrollmentEntityData?.['field_representative_signature'][0]?.['url']" />
							</div>
						</div>
						<div class="d-flex justify-content-between align-items-center mt-2">
							<button class="mt-1" [class.display-none]="!detectSignatureStudio"
								type="button" mat-stroked-button
								(click)="signatureClearStudio()"
								*ngIf="this._enrollmentEntityData?.['field_representative_signature']?.[0]?.['url'] == null">Clear</button>
							<p class="mb-0 text-end"><span class="fw-bold">Date:</span> {{
								this.entityData?.['field_sale_date'] | date }}</p>
						</div>
					</div>
				</div>

				<div class="col-6">
					<div class="signature-box p-3 border rounded">
						<p class="fw-bold mb-2">Buyer Signature:</p>
						<div class="signature-container bg-light p-1 border rounded"
							style="min-height: 125px;">
							<canvas
								*ngIf="this._enrollmentEntityData?.['field_signature']?.[0]?.['url'] == null"
								width="341px" height="125px" class="signature-canvas"
								#SignatureStudent></canvas>

							<div
								*ngIf="this._enrollmentEntityData?.['field_signature']?.[0]?.['url']">
								<img class="img-fluid"
									[src]="this._enrollmentEntityData?.['field_signature'][0]?.['url']" />
							</div>
						</div>
						<div class="d-flex justify-content-between align-items-center mt-2">
							<button class="mt-1" [class.display-none]="!detectSignatureStudent"
								type="button" mat-stroked-button
								(click)="signatureClearStudent()"
								*ngIf="this._enrollmentEntityData?.['field_signature']?.[0]?.['url'] == null">Clear</button>
							<p class="mb-0 text-end"><span class="fw-bold">Date:</span> {{
								this.entityData?.['field_sale_date'] | date }}</p>
						</div>
					</div>
				</div>
			</div>

			<!-- Parent/Guardian Signature Section -->
			<div class="row mt-4">
				<div class="col-12">
					<div class="parent-signature p-3 border rounded bg-light">
						<p class="fw-bold mb-2">Parent/Guardian Signature (if applicable):</p>
						<div class="signature-container bg-light p-1 border rounded"
							style="min-height: 125px;">
							<canvas
								*ngIf="this._enrollmentEntityData?.['field_parent_guardian_signature']?.[0]?.['url'] == null"
								width="782px" height="125px" class="signature-canvas"
								#SignatureParent></canvas>

							<div
								*ngIf="this._enrollmentEntityData?.['field_parent_guardian_signature']?.[0]?.['url']">
								<img class="img-fluid"
									[src]="this._enrollmentEntityData?.['field_parent_guardian_signature'][0]?.['url']" />
							</div>
						</div>
						<div class="d-flex justify-content-between align-items-center mt-2">
							<button class="mt-1" [class.display-none]="!detectSignatureParent"
								type="button" mat-stroked-button (click)="signatureClearParent()"
								*ngIf="this._enrollmentEntityData?.['field_parent_guardian_signature']?.[0]?.['url'] == null">Clear</button>
							<p class="mb-0 text-end"><span class="fw-bold">Date:</span> {{
								this.entityData?.['field_sale_date'] | date }}</p>
						</div>
						<p class="small text-muted mt-2 mb-0">On behalf of my minor child and
							myself, I agree to assume and comply with all the provisions of this
							agreement, including the provision titled
							"Participation & Assumption of Risk" and "Indemnity." I promise to pay
							any financial obligation that my minor child does not pay for any
							reason.</p>
					</div>
				</div>
			</div>

			<div class="row mt-3 mb-3">
				<div class="col-6">
					<button
						(click)="printPage(false, true)"
						class="me-1" type="button" mat-stroked-button color="primary">
						Print
					</button>
					<button
						(click)="sendEmailNewEnrollment(this.enrollmentID)"
						class="me-1" type="button" mat-stroked-button color="primary">
						Email
					</button>
				</div>
				<div class="col-6">
					<div class="d-flex justify-content-end">
						<button
							[disabled]="(!detectSignatureStudio && !this._enrollmentEntityData?.['field_representative_signature']?.[0]?.['url']) ||
													(!detectSignatureStudent && !this._enrollmentEntityData?.['field_signature']?.[0]?.['url']) ||
													!tosAccepted"
							class="me-1"
							type="button"
							mat-stroked-button
							(click)="onSubmitSignatures('save_and_add')"
							color="primary">
							Save and Add
						</button>
					</div>
				</div>
			</div>

			<!-- Validation message to show what's missing -->
			<div class="row mt-2"
				*ngIf="((!detectSignatureStudio && !this._enrollmentEntityData?.['field_representative_signature']?.[0]?.['url']) ||
								(!detectSignatureStudent && !this._enrollmentEntityData?.['field_signature']?.[0]?.['url']) ||
								!tosAccepted)">
				<div class="col-12">
					<div class="alert alert-warning">
						<strong>Please complete the following before proceeding:</strong>
						<ul class="mb-0 mt-2">
							<li
								*ngIf="!detectSignatureStudio && !this._enrollmentEntityData?.['field_representative_signature']?.[0]?.['url']">Studio
								signature is required</li>
							<li
								*ngIf="!detectSignatureStudent && !this._enrollmentEntityData?.['field_signature']?.[0]?.['url']">Student
								signature is required</li>
							<li *ngIf="!tosAccepted">Terms of Service must be accepted</li>
						</ul>
					</div>
				</div>
			</div>

			<div class="mt-3 d-none">
				<!-- TODO: Display this only when there is a stepper. -->
				<!-- <button class="me-1" mat-button matStepperPrevious>Back</button> -->
				<!-- <button mat-button matStepperNext>Next</button> -->
			</div>
		</form>

	</div>

</div>

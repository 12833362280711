import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { ViewTableComponent } from '../../base/view-table/view-table.component';
import { environment } from '../../../../environments/environment';
import { DrupalRESTService } from '../../../services/drupal-rest.service';
import { FieldsService } from '../../../services/fields.service';
import { AuthService } from '../../../services/auth.service';
import { StudentDashboardWrapperComponent } from '../../student/student-dashboard-wrapper/student-dashboard-wrapper.component';
import { PrintService } from '../../../services/print.service';
import { DialogService } from '../../../services/dialog.service';
import { EntityRESTService } from '../../../services/entity-rest.service';
import { UtilityService } from '../../../services/utility.service';
import { TaxonomyService } from '../../../services/taxonomy.service';
import { Router } from '@angular/router';
import { AuthRESTService } from '../../../services/auth-rest.service';
import { MenuRESTService } from '../../../services/menu-rest.service';
import { FormBuilder } from '@angular/forms';
import { RouteEventsService } from '../../../route-events.service';
import { PermissionsService } from '../../../services/permissions.service';
import { Renderer2 } from '@angular/core';
import { ConfigPagesService } from '../../../services/config-pages.service';
import { PackagesService } from '../../../services/packages.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from '../../../services/user.service';
import { ElementRef, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { RefreshService } from 'src/app/services/refresh.service';
interface StudentInfo {
  id: string;
  name: string;
}

interface DepartmentData {
  count: number;
  students: StudentInfo[];
}

interface StudentActivityData {
  with_lessons: {
    'Front Dept': DepartmentData;
    'Middle Dept': DepartmentData;
    'Back Dept': DepartmentData;
    total_lessons: number;
  };
  without_lessons: {
    'Front Dept': DepartmentData;
    'Middle Dept': DepartmentData;
    'Back Dept': DepartmentData;
  };
}

interface InstructorActivityData {
  instructor_name: string;
  data: StudentActivityData;
}

@Component({
  selector: 'app-weekly-student-activity',
  templateUrl: './weekly-student-activity.component.html',
  styleUrls: ['./weekly-student-activity.component.css']
})
export class WeeklyStudentActivityComponent extends ViewTableComponent implements OnInit {
  year: string;
  week: string;
  override instructorData: InstructorActivityData[] = [];
  override displayedColumns: string[] = ['Front Dept', 'Middle Dept', 'Back Dept'];
  StudentDashboardWrapperComponent = StudentDashboardWrapperComponent;

  constructor(
    public override _refreshService: RefreshService,
    override _activatedRoute: ActivatedRoute,
    override _fieldsService: FieldsService,
    override _drupalRESTService: DrupalRESTService,
    override _authService: AuthService,
    override _dialogService: DialogService,
    override _entityRESTService: EntityRESTService,
    override _utilityService: UtilityService,
    override _taxonomyService: TaxonomyService,
    override _router: Router,
    override _authRESTService: AuthRESTService,
    override _menuRESTService: MenuRESTService,
    override _formBuilder: FormBuilder,
    override _routeEventsService: RouteEventsService,
    override _permissionsService: PermissionsService,
    override _renderer2: Renderer2,
    override _configPages: ConfigPagesService,
    override _packagesService: PackagesService,
    override _snackBar: MatSnackBar,
    override _userRESTService: UserService,
    override _el: ElementRef,
    override _changeDetectorRef: ChangeDetectorRef,
    override _sanitizer: DomSanitizer,
    private printService: PrintService
  ) {
    super(
      _refreshService,
      _dialogService,
      _entityRESTService,
      _utilityService,
      _fieldsService,
      _drupalRESTService,
      _taxonomyService,
      _router,
      _activatedRoute,
      _authRESTService,
      _authService,
      _menuRESTService,
      _formBuilder,
      _routeEventsService,
      _permissionsService,
      _renderer2,
      _configPages,
      _packagesService,
      _snackBar,
      _userRESTService,
      _el,
      _changeDetectorRef,
      _sanitizer
    );
  }

  override ngAfterContentInit(): void {
    this._activatedRoute.queryParams.subscribe(data => {
      this.ShowProgressBar = false;

      if (data['year']) {
        this.year = data['year'];
      } else {
        this.year = moment().format("YYYY");
      }

      if (data['week']) {
        this.week = data['week'];
      } else {
        this.week = moment().add(0, 'week').format("w");
      }

      this.WeeksForYearData = this._fieldsService.WeeksForYearData(Number.parseInt(this.year));
      this.getData([
        { parameter: 'week', value: this.week},
        { parameter: 'year', value: this.year},
      ]);
    });
  }

  onWeekChange(event: any): void {
    this.week = event.value;
    this.getData([
      { parameter: 'week', value: this.week },
      { parameter: 'year', value: this.year },
    ]);
  }

  onYearChange(event: any): void {
    this.year = event.value;
    this.WeeksForYearData = this._fieldsService.WeeksForYearData(Number.parseInt(this.year));
    this.getData([
      { parameter: 'week', value: this.week },
      { parameter: 'year', value: this.year },
    ]);
  }

  override getData(params: { parameter: string; value: string; }[]): void {
    this.ShowProgressBar = true;
    this.queryParams = params;

    if (this.sortParams !== undefined) {
      this.queryParams.push(this.sortParams);
    }

    this._drupalRESTService.httpGET('/api_rest/v1/weekly_student_activity_report', params)
      .subscribe({
        next: (response) => {
          this.ShowProgressBar = false;
          this.instructorData = Object.entries(response).map(([instructor_name, data]) => ({
            instructor_name,
            data: data as StudentActivityData
          }));
        },
        error: (error) => {
          this.ShowProgressBar = false;
          console.error('Error fetching weekly student activity data:', error);
        }
      });
  }

  refreshData(): void {
    this.getData(this.queryParams);
  }

  exportReport(): void {
    const params = {
      week: this.week,
      year: this.year
    };

    const baseUrl = environment.hostUrl;
    const queryString = new URLSearchParams(params).toString();
    const studioId = this._authService.studios?.[0]?.id;
    const url = `${baseUrl}/api_rest/v1/weekly_student_activity_report_csv?${queryString}&field_studio_reference_target_id=${studioId}`;

    window.open(url, '_blank');
  }

  override resetForm(form?: NgForm): void {
    if (form) {
      form.resetForm();
    }
    this.getData([]);
  }

  printReport() {
    const content = `
      <h2>Weekly Student Activity Report</h2>
      <p>Week: ${this.week}, Year: ${this.year}</p>
      ${this.instructorData.map(instructor => `
        <div class="section">
          <h3>${instructor.instructor_name}</h3>
          <p>Total Lessons: ${instructor.data.with_lessons.total_lessons}</p>

          <table>
            <thead>
              <tr>
                <th>Front Dept</th>
                <th>Middle Dept</th>
                <th>Back Dept</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div class="student-section">
                    <strong>With Lessons (${instructor.data.with_lessons['Front Dept'].count})</strong>
                    <div class="student-list">
                      ${instructor.data.with_lessons['Front Dept'].students.map(student =>
                        `${student.name}`).join('<br>')}
                    </div>
                  </div>
                  <div class="student-section">
                    <strong>Without Lessons (${instructor.data.without_lessons['Front Dept'].count})</strong>
                    <div class="student-list">
                      ${instructor.data.without_lessons['Front Dept'].students.map(student =>
                        `${student.name}`).join('<br>')}
                    </div>
                  </div>
                </td>
                <td>
                  <div class="student-section">
                    <strong>With Lessons (${instructor.data.with_lessons['Middle Dept'].count})</strong>
                    <div class="student-list">
                      ${instructor.data.with_lessons['Middle Dept'].students.map(student =>
                        `${student.name}`).join('<br>')}
                    </div>
                  </div>
                  <div class="student-section">
                    <strong>Without Lessons (${instructor.data.without_lessons['Middle Dept'].count})</strong>
                    <div class="student-list">
                      ${instructor.data.without_lessons['Middle Dept'].students.map(student =>
                        `${student.name}`).join('<br>')}
                    </div>
                  </div>
                </td>
                <td>
                  <div class="student-section">
                    <strong>With Lessons (${instructor.data.with_lessons['Back Dept'].count})</strong>
                    <div class="student-list">
                      ${instructor.data.with_lessons['Back Dept'].students.map(student =>
                        `${student.name}`).join('<br>')}
                    </div>
                  </div>
                  <div class="student-section">
                    <strong>Without Lessons (${instructor.data.without_lessons['Back Dept'].count})</strong>
                    <div class="student-list">
                      ${instructor.data.without_lessons['Back Dept'].students.map(student =>
                        `${student.name}`).join('<br>')}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      `).join('')}
    `;

    const customStyles = `
      .student-section {
        margin-bottom: 15px;
      }
      .student-section strong {
        display: block;
        margin-bottom: 5px;
      }
      .student-list {
        margin-left: 15px;
        color: #666;
        font-size: 0.9em;
      }
      th, td {
        width: 33.33%;
      }
    `;

    this.printService.print(content, {
      title: 'Weekly Student Activity Report',
      styles: customStyles,
      showSchoolName: true
    });
  }

  override onSubmit(form: NgForm): void {
    this.getData([
      { parameter: 'week', value: this.week },
      { parameter: 'year', value: this.year },
    ]);
  }
}

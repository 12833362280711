import { Component, OnInit, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ViewTableComponent } from '../../base/view-table/view-table.component';
import { MatTableDataSource } from '@angular/material/table';
import moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilityService } from 'src/app/services/utility.service';
import { DialogService } from 'src/app/services/dialog.service';
import { EntityRESTService } from 'src/app/services/entity-rest.service';
import { FieldsService } from 'src/app/services/fields.service';
import { DrupalRESTService } from 'src/app/services/drupal-rest.service';
import { TaxonomyService } from 'src/app/services/taxonomy.service';
import { AuthRESTService } from 'src/app/services/auth-rest.service';
import { AuthService } from 'src/app/services/auth.service';
import { MenuRESTService } from 'src/app/services/menu-rest.service';
import { FormBuilder } from '@angular/forms';
import { RouteEventsService } from 'src/app/route-events.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { ConfigPagesService } from 'src/app/services/config-pages.service';
import { PackagesService } from 'src/app/services/packages.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from 'src/app/services/user.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Renderer2 } from '@angular/core';
import { PrintService } from 'src/app/services/print.service';
import { RefreshService } from 'src/app/services/refresh.service';
@Component({
  selector: 'app-weekly-enrollment-sales-tracking-report',
  templateUrl: './weekly-enrollment-sales-tracking-report.component.html',
  styleUrls: ['./weekly-enrollment-sales-tracking-report.component.css']
})
export class WeeklyEnrollmentSalesTrackingReportComponent extends ViewTableComponent implements OnInit {

  year: string;
  week: string;
  override WeeksForYearData: any[] = [];
  override LastFourYearsData: any[] = this.getLastFourYears();
  consumable: any;
  inquiriesData: any[] = [];
  deEnrollmentsData: any[] = [];
  preEnrollmentsData: any[] = [];
  oriData: any[] = [];
  extData: any[] = [];
  renData: any[] = [];
  nonUnitSalesData: any[] = [];
  miscSalesData: any[] = [];
  dateTimeRange: Date[] = [];
  isDateRangeActive: boolean = false;

  constructor(
    public override _refreshService: RefreshService,
    private _printService: PrintService,
    public override _dialogService: DialogService,
    public override _entityRESTService: EntityRESTService,
    public override _utilityService: UtilityService,
    public override _fieldsService: FieldsService,
    public override _drupalRESTService: DrupalRESTService,
    public override _taxonomyService: TaxonomyService,
    public override _router: Router,
    public override _activatedRoute: ActivatedRoute,
    public override _authRESTService: AuthRESTService,
    public override _authService: AuthService,
    public override _menuRESTService: MenuRESTService,
    public override _formBuilder: FormBuilder,
    public override _routeEventsService: RouteEventsService,
    public override _permissionsService: PermissionsService,
    public override _renderer2: Renderer2,
    public override _configPages: ConfigPagesService,
    public override _packagesService: PackagesService,
    public override _snackBar: MatSnackBar,
    public override _userRESTService: UserService,
    public override _el: ElementRef,
    public override _changeDetectorRef: ChangeDetectorRef,
    public override _sanitizer: DomSanitizer
  ) {
    super(
      _refreshService,
      _dialogService,
      _entityRESTService,
      _utilityService,
      _fieldsService,
      _drupalRESTService,
      _taxonomyService,
      _router,
      _activatedRoute,
      _authRESTService,
      _authService,
      _menuRESTService,
      _formBuilder,
      _routeEventsService,
      _permissionsService,
      _renderer2,
      _configPages,
      _packagesService,
      _snackBar,
      _userRESTService,
      _el,
      _changeDetectorRef,
      _sanitizer
    );
  }

  getCurrentWeek() {
    return moment().isoWeek().toString();
  }

  override ngOnInit(): void {
    this._activatedRoute.queryParams.subscribe(params => {
      const currentMoment = moment();
      if (params['date_from'] && params['date_to']) {
        this.dateTimeRange = [
          new Date(params['date_from']),
          new Date(params['date_to'])
        ];
        this.isDateRangeActive = true;
        this.year = currentMoment.format("YYYY");
        this.week = this.getCurrentWeek();
        this.onYearChange(this.year);
        this.loadData();
      } else {
        this.isDateRangeActive = false;
        this.year = params['year'] || currentMoment.format("YYYY");
        this.week = params['week'] || this.getCurrentWeek();
        this.onYearChange(this.year);
        this.loadData();
      }
    });
  }

  onYearChange(year: string) {
    this.year = year;
    this.WeeksForYearData = this._fieldsService.WeeksForYearData(Number(this.year));
  }

  override dataSource = new MatTableDataSource<any>([]);

  override ngAfterContentInit(): void {
    this._activatedRoute.queryParams.subscribe(data => {
      const currentMoment = moment();
      this.week = data['week'] || currentMoment.isoWeek().toString();
      this.year = data['year'] || currentMoment.format("YYYY");
      this.loadData();
    });
  }

  dateRangeChange(event: any) {
    if (event.value && Array.isArray(event.value)) {
      this.dateTimeRange = event.value;
      this.isDateRangeActive = true;
      this.loadData();
    }
  }

  loadData() {
    if (this.isDateRangeActive && this.dateTimeRange?.length > 0) {
      this.getData([
        { parameter: 'date_from', value: moment(this.dateTimeRange[0]).format('YYYY-MM-DD') },
        { parameter: 'date_to', value: moment(this.dateTimeRange[1]).format('YYYY-MM-DD') }
      ]);
    } else if (this.week && this.year) {
      this.getData([
        { parameter: 'week', value: this.week.toString() },
        { parameter: 'year', value: this.year }
      ]);
    }
  }

  override getData(params: { parameter: string; value: string }[]) {
    this.ShowProgressBar = true;

    if (!params.some(p => p.parameter === 'date_from')) {
      params.push({ parameter: 'weekYear', value: this.year.toString() });
      params.push({ parameter: 'weekNumber', value: this.week.toString() });
    }

    this._drupalRESTService.httpGET('/api_rest/v1/loadWeeklyEnrollmentSalesTrackingReport', params).subscribe(
      data => {
        this.ShowProgressBar = false;
        this.inquiriesData = (data?.['inquiry_merged_report'] || []).map(item => ({
          name: item.title,
          callDates: item.field_inquiry_date,
          bookingDates: item.field_booked_date
        }));
        this.deEnrollmentsData = data?.['dance_evaluation_report'] || [];
        this.preEnrollmentsData = data?.['pre_original_report'] || [];
        this.oriData = data?.['original_report'] || [];
        this.extData = data?.['extension_report'] || [];
        this.renData = data?.['renewal_report'] || [];
        this.nonUnitSalesData = data?.['non_unit_report'] || [];
        this.miscSalesData = data?.['miscellaneous_report'] || [];
      },
      error => this.handleError(error)
    );
  }

  private getLastFourYears(): any[] {
    const currentYear = moment().year();
    return Array.from({ length: 4 }, (_, i) => ({
      key: (currentYear - i).toString(),
      value: (currentYear - i).toString()
    }));
  }

  override onPrint() {
    // Build HTML content directly from the data
    let content = `
      <h2 class="report-title">Weekly Enrollment Sales Tracking Report</h2>
      <p class="report-period">
        ${this.isDateRangeActive && this.dateTimeRange?.length > 0
          ? `Date Range: ${moment(this.dateTimeRange[0]).format('MMM D, YYYY')} to ${moment(this.dateTimeRange[1]).format('MMM D, YYYY')}`
          : `Week: ${this.week}, Year: ${this.year}`
        }
      </p>
    `;

    // Inquiries Section
    content += `
      <div class="section">
        <h3>Inquiries</h3>
        ${this.inquiriesData.length > 0 ? `
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Call Dates</th>
                <th>Booking Dates</th>
              </tr>
            </thead>
            <tbody>
              ${this.inquiriesData.map(item => `
                <tr>
                  <td>${item.name || ''}</td>
                  <td>${item.callDates || ''}</td>
                  <td>${item.bookingDates || ''}</td>
                </tr>
              `).join('')}
            </tbody>
          </table>
        ` : '<p>No inquiries found for this period</p>'}
      </div>
    `;

    // DE Enrollments Section
    if (this.deEnrollmentsData.length > 0) {
      content += `
        <div class="section">
          <h3>DE Enrollments</h3>
          <table>
            <thead>
              <tr>
                <th>Student Name</th>
                <th>Package</th>
                <th>Date Enrolled</th>
                <th># of Lessons</th>
                <th>Amount ($)</th>
                <th>Teachers</th>
              </tr>
            </thead>
            <tbody>
              ${this.deEnrollmentsData.map(item => `
                <tr>
                  <td>${item.student_name || ''}</td>
                  <td>${item.package_name || ''}</td>
                  <td>${item.date_enrolled ? moment(item.date_enrolled).format('MMM D, YYYY') : ''}</td>
                  <td>${item.lessons_enrolled || ''}</td>
                  <td>${item.amount ? '$' + parseFloat(item.amount).toFixed(2) : ''}</td>
                  <td>${item.teachers || ''}</td>
                </tr>
              `).join('')}
            </tbody>
          </table>
        </div>
      `;
    }

    // Pre Enrollments Section
    if (this.preEnrollmentsData.length > 0) {
      content += `
        <div class="section">
          <h3>Pre Enrollments</h3>
          <table>
            <thead>
              <tr>
                <th>Student Name</th>
                <th>Package</th>
                <th>Date Enrolled</th>
                <th># of Lessons</th>
                <th>Amount ($)</th>
                <th>Teachers</th>
              </tr>
            </thead>
            <tbody>
              ${this.preEnrollmentsData.map(item => `
                <tr>
                  <td>${item.student_name || ''}</td>
                  <td>${item.package_name || ''}</td>
                  <td>${item.date_enrolled ? moment(item.date_enrolled).format('MMM D, YYYY') : ''}</td>
                  <td>${item.lessons_enrolled || ''}</td>
                  <td>${item.amount ? '$' + parseFloat(item.amount).toFixed(2) : ''}</td>
                  <td>${item.teachers || ''}</td>
                </tr>
              `).join('')}
            </tbody>
          </table>
        </div>
      `;
    }

    // ORIs Section
    if (this.oriData.length > 0) {
      content += `
        <div class="section">
          <h3>ORIs</h3>
          <table>
            <thead>
              <tr>
                <th>Student Name</th>
                <th>Package</th>
                <th>Date Enrolled</th>
                <th># of Lessons</th>
                <th>Amount ($)</th>
                <th>Teachers</th>
              </tr>
            </thead>
            <tbody>
              ${this.oriData.map(item => `
                <tr>
                  <td>${item.student_name || ''}</td>
                  <td>${item.package_name || ''}</td>
                  <td>${item.date_enrolled ? moment(item.date_enrolled).format('MMM D, YYYY') : ''}</td>
                  <td>${item.lessons_enrolled || ''}</td>
                  <td>${item.amount ? '$' + parseFloat(item.amount).toFixed(2) : ''}</td>
                  <td>${item.teachers || ''}</td>
                </tr>
              `).join('')}
            </tbody>
          </table>
        </div>
      `;
    }

    // EXTs Section
    if (this.extData.length > 0) {
      content += `
        <div class="section">
          <h3>EXTs</h3>
          <table>
            <thead>
              <tr>
                <th>Student Name</th>
                <th>Package</th>
                <th>Date Enrolled</th>
                <th># of Lessons</th>
                <th>Amount ($)</th>
                <th>Teachers</th>
              </tr>
            </thead>
            <tbody>
              ${this.extData.map(item => `
                <tr>
                  <td>${item.student_name || ''}</td>
                  <td>${item.package_name || ''}</td>
                  <td>${item.date_enrolled ? moment(item.date_enrolled).format('MMM D, YYYY') : ''}</td>
                  <td>${item.lessons_enrolled || ''}</td>
                  <td>${item.amount ? '$' + parseFloat(item.amount).toFixed(2) : ''}</td>
                  <td>${item.teachers || ''}</td>
                </tr>
              `).join('')}
            </tbody>
          </table>
        </div>
      `;
    }

    // RENs Section
    if (this.renData.length > 0) {
      content += `
        <div class="section">
          <h3>RENs</h3>
          <table>
            <thead>
              <tr>
                <th>Student Name</th>
                <th>Package</th>
                <th>Date Enrolled</th>
                <th># of Lessons</th>
                <th>Amount ($)</th>
                <th>Teachers</th>
              </tr>
            </thead>
            <tbody>
              ${this.renData.map(item => `
                <tr>
                  <td>${item.student_name || ''}</td>
                  <td>${item.package_name || ''}</td>
                  <td>${item.date_enrolled ? moment(item.date_enrolled).format('MMM D, YYYY') : ''}</td>
                  <td>${item.lessons_enrolled || ''}</td>
                  <td>${item.amount ? '$' + parseFloat(item.amount).toFixed(2) : ''}</td>
                  <td>${item.teachers || ''}</td>
                </tr>
              `).join('')}
            </tbody>
          </table>
        </div>
      `;
    }

    // Non-Unit Sales Section
    if (this.nonUnitSalesData.length > 0) {
      content += `
        <div class="section">
          <h3>Non-Unit Sales</h3>
          <table>
            <thead>
              <tr>
                <th>Student Name</th>
                <th>Package</th>
                <th>Date Enrolled</th>
                <th># of Lessons</th>
                <th>Amount ($)</th>
                <th>Teachers</th>
              </tr>
            </thead>
            <tbody>
              ${this.nonUnitSalesData.map(item => `
                <tr>
                  <td>${item.student_name || ''}</td>
                  <td>${item.package_name || ''}</td>
                  <td>${item.date_enrolled ? moment(item.date_enrolled).format('MMM D, YYYY') : ''}</td>
                  <td>${item.lessons_enrolled || ''}</td>
                  <td>${item.amount ? '$' + parseFloat(item.amount).toFixed(2) : ''}</td>
                  <td>${item.teachers || ''}</td>
                </tr>
              `).join('')}
            </tbody>
          </table>
        </div>
      `;
    }

    // Miscellaneous Sales Section
    if (this.miscSalesData.length > 0) {
      content += `
        <div class="section">
          <h3>Miscellaneous Sales</h3>
          <table>
            <thead>
              <tr>
                <th>Student Name</th>
                <th>Package</th>
                <th>Date Enrolled</th>
                <th># of Lessons</th>
                <th>Amount ($)</th>
                <th>Teachers</th>
              </tr>
            </thead>
            <tbody>
              ${this.miscSalesData.map(item => `
                <tr>
                  <td>${item.student_name || ''}</td>
                  <td>${item.package_name || ''}</td>
                  <td>${item.date_enrolled ? moment(item.date_enrolled).format('MMM D, YYYY') : ''}</td>
                  <td>${item.lessons_enrolled || ''}</td>
                  <td>${item.amount ? '$' + parseFloat(item.amount).toFixed(2) : ''}</td>
                  <td>${item.teachers || ''}</td>
                </tr>
              `).join('')}
            </tbody>
          </table>
        </div>
      `;
    }

    // Additional styles specific to this report
    const styles = `
      .report-title {
        font-size: 24px;
        text-align: center;
        margin-bottom: 10px;
      }
      .report-period {
        text-align: center;
        font-size: 16px;
        margin-bottom: 30px;
      }
      .section {
        margin-bottom: 30px;
        page-break-inside: avoid;
      }
      .section h3 {
        margin-bottom: 10px;
        color: #333;
        border-bottom: 1px solid #ddd;
        padding-bottom: 5px;
      }
      table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 20px;
      }
      th, td {
        border: 1px solid #ddd;
        padding: 8px;
        text-align: left;
      }
      th {
        background-color: #f5f5f5;
        font-weight: bold;
      }
      tr:nth-child(even) {
        background-color: #f9f9f9;
      }
    `;

    // Create a formatted title
    let title = 'Weekly Enrollment Sales Tracking Report';
    if (this.isDateRangeActive && this.dateTimeRange?.length > 0) {
      const startDate = moment(this.dateTimeRange[0]).format('MMM D, YYYY');
      const endDate = moment(this.dateTimeRange[1]).format('MMM D, YYYY');
      title += ` - ${startDate} to ${endDate}`;
    } else {
      title += ` - Week ${this.week}, ${this.year}`;
    }

    this._printService.print(content, {
      title,
      styles,
      showSchoolName: true,
      promptPrint: true
    });
  }

  override resetForm() {
    this.dateTimeRange = [];
    this.isDateRangeActive = false;
    const currentMoment = moment();
    this.week = this.getCurrentWeek();
    this.year = currentMoment.format("YYYY");
    this.onYearChange(this.year);
    this.loadData();
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgotPasswordComponent } from './authenticate/forgot-password/forgot-password.component';
import { LoginComponent } from './authenticate/login/login.component';
import { LogoutComponent } from './authenticate/logout/logout.component';
import { WeekViewComponent } from './components/appointments/week-view/week-view.component';
import { AuthenticateComponent } from './components/authenticate/authenticate.component';

import { DashboardComponent } from './components/dashboard/dashboard.component'
import { DayViewComponent } from './components/day-view/day-view.component';
import { StudentAccountEntityComponent } from './components/forms/student-account-entity/student-account-entity.component';
import { AmiReportsComponent } from './components/reports/ami-reports/ami-reports.component';
import { AmiStaffPerformanceComponent } from './components/reports/ami-staff-performance/ami-staff-performance.component';
import { CanceledEventsComponent } from './components/reports/canceled-events/canceled-events.component';
import { ChatSuccessRateComponent } from './components/reports/chat-success-rate/chat-success-rate.component';
import { EnrollmentsViewComponent } from './components/reports/enrollments-view/enrollments-view.component';
import { InquiryViewComponent } from './components/reports/inquiry-view/inquiry-view.component';
import { LessonsListReportComponent } from './components/reports/lessons-list-report/lessons-list-report.component';
import { PackageListComponent } from './components/reports/package-list/package-list.component';
import { PaymentsReportViewComponent } from './components/reports/payments-report-view/payments-report-view.component';
import { ProjectionsReportComponent } from './components/reports/projections-report/projections-report.component';
import { RoyaltyReportComponent } from './components/reports/royalty-report/royalty-report.component';
import { StudentsInventoryComponent } from './components/reports/students-inventory/students-inventory.component';
import { StudioBusinessSummaryComponent } from './components/reports/studio-business-summary/studio-business-summary.component';
import { StudentDashboardComponent } from './components/student/student-dashboard/student-dashboard.component';
import { StudentsListComponent } from './components/student/students-list/students-list.component';
import { CashReportComponent } from './components/reports/cash-report/cash-report.component';
import { PaymentsDueComponent } from './components/reports/payments-due/payments-due.component';
import { PaymentsPastDueComponent } from './components/reports/payments-past-due/payments-past-due.component';
import { PaymentsMadeEncompassingComponent } from './components/reports/payments-made-encompassing/payments-made-encompassing.component';
import { PaymentsMadeLessonComponent } from './components/reports/payments-made-lesson/payments-made-lesson.component';
import { PaymentsMadeMiscComponent } from './components/reports/payments-made-misc/payments-made-misc.component';
import { PaymentsMadeSundryComponent } from './components/reports/payments-made-sundry/payments-made-sundry.component';
import { InquiriesComponent } from './components/reports/inquiries/inquiries.component';
import { CancellationsComponent } from './components/reports/cancellations/cancellations.component';
import { ServiceSalesComponent } from './components/reports/service-sales/service-sales.component';
import { CounselorsComponent } from './components/reports/counselors/counselors.component';
import { SupervisorsComponent } from './components/reports/supervisors/supervisors.component';
import { AppointmentSheetComponent } from './components/reports/appointment-sheet/appointment-sheet.component';
import { PackageListRetiredComponent } from './components/reports/package-list-retired/package-list-retired.component';
import { AmiMiscReportComponent } from './components/reports/ami-misc-report/ami-misc-report.component';
import { SplashComponent } from './components/splash/splash.component';
import { ConfigurationComponent } from './components/configuration/configuration.component';
import { EnrollmentTrackingComponent } from './components/reports/enrollment-tracking/enrollment-tracking.component';
import { CustomImportComponent } from './components/configuration/import/custom-import/custom-import.component';
import { FloatingrainImportComponent } from './components/configuration/import/floatingrain-import/floatingrain-import.component';
import { StaffManagementComponent } from './components/configuration/staff-management/staff-management.component';
import { SimpleImportComponent } from './components/configuration/import/simple-import/simple-import.component';
import { StudioInformationComponent } from './components/configuration/studio-information/studio-information.component';
import { IntegrationStripeComponent } from './components/configuration/integration-stripe/integration-stripe.component';
import { EmailConfigurationSettingsComponent } from './components/configuration/email-configuration-settings/email-configuration-settings.component';
import { IntegrationTwilioComponent } from './components/configuration/integration-twilio/integration-twilio.component';
import { GiftCertificatesComponent } from './components/configuration/gift-certificates/gift-certificates.component';
import { SMSConfigurationSettingsComponent } from './components/configuration/smsconfiguration-settings/smsconfiguration-settings.component';
import { UnsavedChangesGuard } from './guards/unsaved-changes.guard';
import { TaxConfigurationComponent } from './components/configuration/tax-configuration/tax-configuration.component';
import { SimpleImportInstructorWrapperComponent } from './components/configuration/import/simple-import-instructor-wrapper/simple-import-instructor-wrapper.component';
import { SimpleImportPackagesWrapperComponent } from './components/configuration/import/simple-import-packages-wrapper/simple-import-packages-wrapper.component';
import { SimpleImportStudentAccountsWrapperComponent } from './components/configuration/import/simple-import-student-accounts-wrapper/simple-import-student-accounts-wrapper.component';
import { SimpleImportHistoryWrapperComponent } from './components/configuration/import/simple-import-history-wrapper/simple-import-history-wrapper.component';
import { SimpleImportSpreadsheetWrapperComponent } from './components/configuration/import/simple-import-spreadsheet-wrapper/simple-import-spreadsheet-wrapper.component';
import { UsSalesTaxReportComponent } from './components/reports/us-sales-tax-report/us-sales-tax-report.component';
import { IntegrationSquareComponent } from './components/configuration/integration-square/integration-square.component';
import { SquareOAuthCallbackComponent } from './components/square/square-oauth-callback-component/square-oauth-callback-component.component';
import { CalendarComponent } from './components/configuration/calendar/calendar.component';
import { WeeklyEnrollmentSalesTrackingReportComponent } from './components/reports/weekly-enrollment-sales-tracking-report/weekly-enrollment-sales-tracking-report.component';
// import { BillingPortalComponent } from './components/configuration/billing-portal/billing-portal.component';
import { PayrollReportSimpleComponent } from './components/reports/payroll-report-simple/payroll-report-simple.component';
import { StaffTrackingReportComponent } from './components/reports/staff-tracking-report/staff-tracking-report.component';
import { StaffSuccessReportComponent } from './components/reports/staff-success-report/staff-success-report.component';
import { TutorialsComponent } from './components/configuration/tutorial/tutorials/tutorials.component';
import { WeeklyStudentActivityComponent } from './components/reports/weekly-student-activity/weekly-student-activity.component';
import { SaleNoSaleReportComponent } from './components/reports/sale-no-sale-report/sale-no-sale-report.component';

const routes: Routes = [
  { path: '', redirectTo: '/splash', pathMatch: 'full' },
  { path: 'dashboard', component: DashboardComponent},
  { path: 'splash', component: SplashComponent},

  // Configuration Pages
  { path: 'configuration', component: ConfigurationComponent},
  { path: 'configuration/studio-information', component: StudioInformationComponent},
  {
    path: 'configuration/simple-import',
    component: SimpleImportComponent,
    canDeactivate: [UnsavedChangesGuard]
  },
  {
    path: 'configuration/simple-import-instructors',
    component: SimpleImportInstructorWrapperComponent,
    canDeactivate: [UnsavedChangesGuard]
  },
  {
    path: 'configuration/simple-import-packages',
    component: SimpleImportPackagesWrapperComponent,
    canDeactivate: [UnsavedChangesGuard]
  },
  {
    path: 'configuration/simple-import-student-accounts',
    component: SimpleImportStudentAccountsWrapperComponent,
    canDeactivate: [UnsavedChangesGuard]
  },
  {
    path: 'configuration/simple-import-student-accounts',
    component: SimpleImportStudentAccountsWrapperComponent,
    canDeactivate: [UnsavedChangesGuard]
  },
  {
    path: 'configuration/simple-import-history',
    component: SimpleImportHistoryWrapperComponent,
    canDeactivate: [UnsavedChangesGuard]
  },
  {
    path: 'configuration/simple-import-spreadsheet',
    component: SimpleImportSpreadsheetWrapperComponent,
    canDeactivate: [UnsavedChangesGuard]
  },
  { path: 'configuration/custom-import', component: CustomImportComponent},
  { path: 'configuration/floatingrain-import', component: FloatingrainImportComponent},
  { path: 'configuration/staff-management', component: StaffManagementComponent},
  { path: 'configuration/stripe-integration', component: IntegrationStripeComponent},
  { path: 'configuration/square-integration', component: IntegrationSquareComponent},
  { path: 'configuration/stripe-twilio', component: IntegrationTwilioComponent},
  { path: 'configuration/email-template-settings', component: EmailConfigurationSettingsComponent},
  { path: 'configuration/sms-template-settings', component: SMSConfigurationSettingsComponent},
  { path: 'configuration/gift-certificates', component: GiftCertificatesComponent},
  { path: 'configuration/tax-configuration', component: TaxConfigurationComponent},
  // { path: 'configuration/billing-portal', component: BillingPortalComponent},
  { path: 'configuration/calendar-configuration', component: CalendarComponent},
  { path: 'configuration/tutorials', component: TutorialsComponent},

  // Authenticate
  { path: 'auth/login', component: LoginComponent },
  { path: 'auth/logout', component: LogoutComponent },
  { path: 'auth/register', component: AuthenticateComponent },
  { path: 'auth/forgot-password', component: ForgotPasswordComponent },

  // Appointments
  { path: 'day-view', component: DayViewComponent },
  { path: 'week-view', component: WeekViewComponent },

  // Reports
  { path: 'inquiry', component: InquiryViewComponent },
  { path: 'projections-report', component: ProjectionsReportComponent },
  { path: 'canceled-views', component: CanceledEventsComponent },
  { path: 'students-inventory', component: StudentsInventoryComponent },
  { path: 'chat-success-rate', component: ChatSuccessRateComponent },
  // { path: 'reports/cash_report', component: CashReportComponent },
  { path: 'lessons-list', component: LessonsListReportComponent },
  { path: 'ami-staff-performance', component: AmiStaffPerformanceComponent },
  { path: 'studio-business-summary', component: StudioBusinessSummaryComponent },
  { path: 'royalty-report', component: RoyaltyReportComponent },
  { path: 'miscellaneous-report', component: AmiMiscReportComponent },

  { path: 'reports/cash-report', component: CashReportComponent },
  { path: 'reports/payments_due', component: PaymentsDueComponent },
  { path: 'reports/payments_past_due', component: PaymentsPastDueComponent },
  { path: 'reports/payments_made_encompassing', component: PaymentsMadeEncompassingComponent },
  { path: 'reports/payments_made_lesson', component: PaymentsMadeLessonComponent },
  { path: 'reports/payments_made_misc', component: PaymentsMadeMiscComponent },
  { path: 'reports/payments_made_sundry', component: PaymentsMadeSundryComponent },
  { path: 'reports/enrollment-tracking', component: EnrollmentTrackingComponent },
  { path: 'reports/inquiries', component: InquiriesComponent },
  { path: 'reports/cancellations', component: CancellationsComponent },
  { path: 'reports/service-sales', component: ServiceSalesComponent },
  { path: 'reports/counselors', component: CounselorsComponent },
  { path: 'reports/supervisors', component: SupervisorsComponent },
  { path: 'reports/appointment-sheet', component: AppointmentSheetComponent },
  { path: 'reports/weekly-enrollment-sales-tracking-report', component: WeeklyEnrollmentSalesTrackingReportComponent },
  { path: 'reports/payroll-report-simple', component: PayrollReportSimpleComponent },
  { path: 'reports/us-sales-tax-report', component: UsSalesTaxReportComponent },
  { path: 'reports/us-sales-tax-report-recent', component: UsSalesTaxReportComponent },
  { path: 'reports/weekly-student-activity', component: WeeklyStudentActivityComponent },
  { path: 'reports/staff-success-report', component: StaffSuccessReportComponent },
  { path: 'reports/sale-no-sale-report', component: SaleNoSaleReportComponent },
  { path: 'reports/staff-tracking-report', component: StaffTrackingReportComponent },

  { path: 'ami-reports', component: AmiReportsComponent },

  // Entities (More Reports)
  { path: 'package-list', component: PackageListComponent },
  { path: 'package-list-retired', component: PackageListRetiredComponent },
  { path: 'packages-list', component: PackageListComponent }, // legacy, delete when ready
  { path: 'packages-list-retired', component: PackageListRetiredComponent }, // legacy, delete when ready
  { path: 'enrollment', component: EnrollmentsViewComponent },
  { path: 'payment-reports', component: PaymentsReportViewComponent },
  { path: 'payment-list', component: PaymentsReportViewComponent },

  // Student Accounts, Dashboards
  { path: 'admin/content/student_accounts/add/student_account', component: StudentAccountEntityComponent},
  { path: 'students-list', component: StudentsListComponent},
  { path: 'students/:id', component: StudentDashboardComponent},


  // Add the new Square OAuth callback route
  { path: 'square-oauth-callback', component: SquareOAuthCallbackComponent },

  // Important: make sure this route is at the end.
  { path: '**', redirectTo: 'splash'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

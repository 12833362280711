<div class="container-fluid">
	<h1 class="page-header">No Sale Report / Enrollment Tracking<!--Sale/No Sale Report--></h1>

	<div class="container-fluid">

		<mat-expansion-panel expanded="true">
			<mat-expansion-panel-header>
				<mat-panel-title>Filter</mat-panel-title>
			</mat-expansion-panel-header>
			<form (ngSubmit)="onSubmit(f)" #f="ngForm" class="form">
				<div class="row">
					<div class="col-3">
						<mat-form-field class="full-width-field">
							<mat-label>Student Account</mat-label>
							<input
								#studentInput
								name="title"
								(input)="onAutocompleteStudent($event)"
								matInput
								ngModel
								[matAutocomplete]="autoCompleteStudent">
							<button (click)="redirectUserProfile(studentInput)" type="button"
								matSuffix mat-icon-button
								aria-label="manage_accounts">
								<mat-icon>manage_accounts</mat-icon>
							</button>
						</mat-form-field>
						<mat-autocomplete #autoCompleteStudent="matAutocomplete"
							(optionSelected)="onSubmit(f)">
							<mat-option
								*ngFor="let option of autoCompleteStudentOptions"
								[value]="option.label">
								{{option.label}} ({{option.value}})
							</mat-option>
						</mat-autocomplete>
					</div>

					<div class="col-3">
						<mat-form-field class="full-width-field">
							<mat-label>Date Range</mat-label>
							<input matInput
								[owlDateTime]="dt"
								[owlDateTimeTrigger]="dt"
								[selectMode]="'range'"
								[(ngModel)]="dateTimeRange"
								placeholder="Select date range"
								readonly
								name="dateRange"
								(dateTimeChange)="dateRangeChange($event)">
							<span class="cursor-pointer" matSuffix [owlDateTimeTrigger]="dt">
								<mat-icon>calendar_today</mat-icon>
							</span>
							<owl-date-time #dt [pickerType]="'calendar'"></owl-date-time>
						</mat-form-field>
					</div>

					<div class="col-3">
						<mat-form-field class="full-width-field">
							<mat-label>Week</mat-label>
							<mat-select [(ngModel)]="week" name="week"
								(selectionChange)="loadData()"
								[disabled]="isDateRangeActive">
								<mat-option *ngFor="let element of WeeksForYearData"
									[value]="element.key">
									{{ element.value }}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>

					<div class="col-3">
						<mat-form-field class="full-width-field">
							<mat-label>Year</mat-label>
							<mat-select [(ngModel)]="year" name="year"
								(selectionChange)="onYearChange(year); loadData()"
								[disabled]="isDateRangeActive">
								<mat-option>None</mat-option>
								<mat-option *ngFor="let element of LastFourYearsData"
									[value]="element.key">
									{{ element.value }}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
				</div>

				<div class="row">
					<section class="section">
						<div class="float-end">
							<button (click)="resetForm()" color="primary" mat-flat-button
								class="me-1" mat-button>Clear</button>
						</div>
					</section>
				</div>
			</form>
		</mat-expansion-panel>

		<mat-progress-bar *ngIf="ShowProgressBar"
			mode="indeterminate"></mat-progress-bar>

		<div class="row mt-3 mb-3">
			<div class="col-12 text-end">
				<button (click)="onPrint()" mat-flat-button color="primary" [disabled]="isPrinting">
					<!-- <mat-icon>print</mat-icon> -->
					<span *ngIf="!isPrinting">Print</span>
					<span *ngIf="isPrinting">Preparing Print...</span>
				</button>
				<mat-progress-bar *ngIf="isPrinting" mode="indeterminate" class="mt-2" style="max-width: 200px; margin-left: auto;"></mat-progress-bar>
			</div>
		</div>
	</div>

	<div class="container-fluid">
		<!-- Sales Data Table -->
		<h2>Sales</h2>
		<table mat-table [dataSource]="dataSource" multiTemplateDataRows
			class="mat-elevation-z8 mat-table w-100">
			<!-- Expand Column -->
			<ng-container matColumnDef="expand">
				<th mat-header-cell *matHeaderCellDef></th>
				<td mat-cell *matCellDef="let element">
					<button style="color:#000; background-color: unset !important;"
						mat-icon-button (click)="toggleRow(element)"
						[attr.aria-expanded]="expandedElements[element.student_id] === true"
						[class.expanded]="expandedElements[element.student_id] === true"
						aria-label="expand row">
						<mat-icon>expand_more</mat-icon>
					</button>
				</td>
			</ng-container>

			<!-- Sale Date Column -->
			<ng-container matColumnDef="saleDate">
				<th mat-header-cell *matHeaderCellDef>Sale Date</th>
				<td mat-cell *matCellDef="let row">{{ row.sale_date | date:'MMM d, yyyy'
					}}</td>
			</ng-container>

			<!-- Student Name Column -->
			<ng-container matColumnDef="studentName">
				<th mat-header-cell *matHeaderCellDef>Student Name</th>
				<td mat-cell *matCellDef="let row">
					<a class="cursor-pointer"
					   (click)="openDialog(StudentDashboardWrapperComponent, 'largerScreenDashboard', {
					   student_id: row.contact_id || row.student_contact_id
					   })">
						{{ row.student_name }}
					</a>
				</td>
			</ng-container>

			<!-- Type Column -->
			<ng-container matColumnDef="categoryType">
				<th mat-header-cell *matHeaderCellDef>Type (Category)</th>
				<td mat-cell *matCellDef="let row">{{ row.category_type }}</td>
			</ng-container>

			<!-- Package Name Column -->
			<ng-container matColumnDef="packageName">
				<th mat-header-cell *matHeaderCellDef>Package Name/ID</th>
				<td mat-cell *matCellDef="let row">{{ row.package_name }}</td>
			</ng-container>

			<!-- Units Column -->
			<ng-container matColumnDef="units">
				<th mat-header-cell *matHeaderCellDef>Units</th>
				<td mat-cell *matCellDef="let row">{{ row.units }}</td>
			</ng-container>

			<!-- Enrollment Amount Column -->
			<ng-container matColumnDef="enrollmentAmount">
				<th mat-header-cell *matHeaderCellDef>Enrollment Amount</th>
				<td mat-cell *matCellDef="let row">{{ row.enrollment_amount | currency
					}}</td>
			</ng-container>

			<!-- Paid Amount Column -->
			<ng-container matColumnDef="paidAmount">
				<th mat-header-cell *matHeaderCellDef>$ Paid</th>
				<td mat-cell *matCellDef="let row">{{ row.paid_amount | currency }}</td>
			</ng-container>

			<!-- Teachers Column -->
			<ng-container matColumnDef="teachers">
				<th mat-header-cell *matHeaderCellDef>Teacher(s)</th>
				<td mat-cell *matCellDef="let row">{{ row.teachers }}</td>
			</ng-container>

			<!-- Closer Column -->
			<ng-container matColumnDef="closer">
				<th mat-header-cell *matHeaderCellDef>Closer</th>
				<td mat-cell *matCellDef="let row">{{ row.closer }}</td>
			</ng-container>

			<!-- Status Column -->
			<ng-container matColumnDef="status">
				<th mat-header-cell *matHeaderCellDef>Status</th>
				<td mat-cell *matCellDef="let row">No Sale</td>
			</ng-container>

			<!-- Outcome Column -->
			<ng-container matColumnDef="potentialOutcome">
				<th mat-header-cell *matHeaderCellDef>Potential Outcome</th>
				<td mat-cell *matCellDef="let row">{{ row.potential_outcome }}</td>
			</ng-container>

			<!-- Expanded Detail Column -->
			<ng-container matColumnDef="expandedDetail">
				<td mat-cell *matCellDef="let element"
					[attr.colspan]="displayedColumns.length">
					<div class="element-detail"
						[@detailExpand]="expandedElements[element.student_id] ? 'expanded' : 'collapsed'">
						<!-- <div class="row">
							<div class="col-md-12">
								<h5>Student Journey</h5>
								<p><strong>Student ID:</strong> {{ element.student_id }}</p>
							</div>
						</div> -->
						<div class="row mt-3">
							<div class="col-md-12">
								<h6>Enrollment History</h6>
								<ng-container *ngIf="getStudentJourney(element.student_id) as journey">
									<table class="table table-bordered">
										<thead>
											<tr>
												<th>Enrollment Type</th>
												<th>ID</th>
												<th>Date</th>
												<th>Package</th>
												<th>Amount</th>
												<!-- <th>Status</th> -->
												<th>Teachers</th>
											</tr>
										</thead>
										<tbody>
											<tr *ngIf="journey.de_enrollment">
												<td><strong>Dance Evaluation</strong></td>
												<td>{{ journey.de_enrollment }}</td>
												<td>{{ getEnrollmentDetails(journey.de_enrollment)?.date || 'N/A'
													}}</td>
												<td>{{ getEnrollmentDetails(journey.de_enrollment)?.package || 'N/A'
													}}</td>
												<td>{{ getEnrollmentDetails(journey.de_enrollment)?.amount ||
													'$0.00' }}</td>
												<!-- <td>{{ getEnrollmentDetails(journey.de_enrollment)?.status || 'N/A' }}</td> -->
												<td>{{ getEnrollmentDetails(journey.de_enrollment)?.teacher || 'N/A'
													}}</td>
											</tr>
											<tr *ngIf="journey.pre_ori_enrollment">
												<td><strong>Pre-Original</strong></td>
												<td>{{ journey.pre_ori_enrollment }}</td>
												<td>{{ getEnrollmentDetails(journey.pre_ori_enrollment)?.date ||
													'N/A' }}</td>
												<td>{{ getEnrollmentDetails(journey.pre_ori_enrollment)?.package ||
													'N/A' }}</td>
												<td>{{ getEnrollmentDetails(journey.pre_ori_enrollment)?.amount ||
													'$0.00' }}</td>
												<!-- <td>{{ getEnrollmentDetails(journey.pre_ori_enrollment)?.status || 'N/A' }}</td> -->
												<td>{{ getEnrollmentDetails(journey.pre_ori_enrollment)?.teacher ||
													'N/A' }}</td>
											</tr>
											<tr *ngIf="journey.ori_enrollment">
												<td><strong>Original</strong></td>
												<td>{{ journey.ori_enrollment }}</td>
												<td>{{ getEnrollmentDetails(journey.ori_enrollment)?.date || 'N/A'
													}}</td>
												<td>{{ getEnrollmentDetails(journey.ori_enrollment)?.package ||
													'N/A' }}</td>
												<td>{{ getEnrollmentDetails(journey.ori_enrollment)?.amount ||
													'$0.00' }}</td>
												<!-- <td>{{ getEnrollmentDetails(journey.ori_enrollment)?.status || 'N/A' }}</td> -->
												<td>{{ getEnrollmentDetails(journey.ori_enrollment)?.teacher ||
													'N/A' }}</td>
											</tr>
											<tr *ngIf="journey.ext_enrollment">
												<td><strong>Extension</strong></td>
												<td>{{ journey.ext_enrollment }}</td>
												<td>{{ getEnrollmentDetails(journey.ext_enrollment)?.date || 'N/A'
													}}</td>
												<td>{{ getEnrollmentDetails(journey.ext_enrollment)?.package ||
													'N/A' }}</td>
												<td>{{ getEnrollmentDetails(journey.ext_enrollment)?.amount ||
													'$0.00' }}</td>
												<!-- <td>{{ getEnrollmentDetails(journey.ext_enrollment)?.status || 'N/A' }}</td> -->
												<td>{{ getEnrollmentDetails(journey.ext_enrollment)?.teacher ||
													'N/A' }}</td>
											</tr>
											<tr *ngIf="journey.ren_enrollment">
												<td><strong>Renewal</strong></td>
												<td>{{ journey.ren_enrollment }}</td>
												<td>{{ getEnrollmentDetails(journey.ren_enrollment)?.date || 'N/A'
													}}</td>
												<td>{{ getEnrollmentDetails(journey.ren_enrollment)?.package ||
													'N/A' }}</td>
												<td>{{ getEnrollmentDetails(journey.ren_enrollment)?.amount ||
													'$0.00' }}</td>
												<!-- <td>{{ getEnrollmentDetails(journey.ren_enrollment)?.status || 'N/A' }}</td> -->
												<td>{{ getEnrollmentDetails(journey.ren_enrollment)?.teacher ||
													'N/A' }}</td>
											</tr>
											<!-- <tr *ngIf="journey.current_enrollment">
												<td><strong>Current</strong></td>
												<td>{{ journey.current_enrollment }}</td>
												<td>{{ getEnrollmentDetails(journey.current_enrollment)?.date ||
													'N/A' }}</td>
												<td>{{ getEnrollmentDetails(journey.current_enrollment)?.package ||
													'N/A' }}</td>
												<td>{{ getEnrollmentDetails(journey.current_enrollment)?.amount ||
													'$0.00' }}</td>
												<td>{{ getEnrollmentDetails(journey.current_enrollment)?.teacher ||
													'N/A' }}</td>
											</tr> -->
										</tbody>
									</table>
								</ng-container>
								<p *ngIf="!getStudentJourney(element.student_id)">No enrollment history
									found for this student.</p>
							</div>
						</div>

						<!-- Quick Service Creation Form -->
						<mat-accordion class="mt-3">
							<mat-expansion-panel>
								<mat-expansion-panel-header>
									<mat-panel-title>Quick Log Service Attempt</mat-panel-title>
								</mat-expansion-panel-header>

								<form #quickServiceForm="ngForm"
									(ngSubmit)="createQuickServiceAttempt(quickServiceForm, element.student_id)">
									<div class="row mb-3">
										<div class="col-md-2">
											<mat-form-field class="full-width-field">
												<mat-label>Service Type</mat-label>
												<mat-select required name="serviceType" ngModel
													#serviceTypeField="ngModel">
													<mat-option *ngFor="let type of serviceTypeOptions"
														[value]="type.id">
														{{ type.name }}
													</mat-option>
												</mat-select>
												<mat-error *ngIf="serviceTypeField.invalid">Service type is
													required</mat-error>
											</mat-form-field>
										</div>

										<div class="col-md-2">
											<mat-form-field class="full-width-field">
												<mat-label>Executive</mat-label>
												<mat-select required name="executive"
													[(ngModel)]="currentExecutive">
													<mat-option *ngFor="let exec of executiveOptions"
														[value]="exec.id">
														{{ exec.name }}
														<ng-container
															*ngIf="exec.id === currentUserId">(You)</ng-container>
													</mat-option>
												</mat-select>
											</mat-form-field>
										</div>

										<!-- Hidden Status field -->
										<div class="d-none">
											<mat-form-field class="full-width-field">
												<mat-label>Status</mat-label>
												<mat-select required name="status" [(ngModel)]="selectedStatus">
													<mat-option *ngFor="let status of serviceStatusOptions"
														[value]="status.id">
														{{ status.name }}
													</mat-option>
												</mat-select>
											</mat-form-field>
										</div>

										<div class="col-md-2">
											<mat-form-field class="full-width-field">
												<mat-label>Date and Time</mat-label>
												<input matInput name="dateTime" [owlDateTime]="dt2"
													[owlDateTimeTrigger]="dt2" [(ngModel)]="currentDateTime">
												<owl-date-time #dt2></owl-date-time>
											</mat-form-field>
										</div>

										<div class="col-md-4">
											<mat-form-field class="full-width-field">
												<mat-label>Notes</mat-label>
												<textarea matInput name="notes" ngModel rows="1"></textarea>
											</mat-form-field>
										</div>

										<div class="col-md-2">
											<div class="d-flex h-100 align-items-center">
												<button type="button"
													mat-raised-button
													[color]="selectedStatus === '106' ? 'primary' : ''"
													(click)="setQuickStatus('106')"
													class="flex-grow-1 me-1">
													<mat-icon>check_circle</mat-icon> Sale
												</button>
												<button type="button"
													mat-raised-button
													[color]="selectedStatus === '107' ? 'warn' : ''"
													(click)="setQuickStatus('107')"
													class="flex-grow-1">
													<mat-icon>cancel</mat-icon> No Sale
												</button>
											</div>
										</div>

										<div class="col-auto ms-auto d-flex align-items-center mt-3">
											<button mat-flat-button
												color="primary"
												type="submit"
												class="btn-sm"
												[disabled]="!serviceTypeField.valid">
												<mat-icon class="small-icon">save</mat-icon> Save
											</button>
										</div>
									</div>
								</form>
							</mat-expansion-panel>
						</mat-accordion>

						<!-- Service Attempts Section -->
						<div class="row mt-4">
							<div class="col-md-12">
								<h6>Service Attempts</h6>
								<ng-container
									*ngIf="getServiceAttempts(element.student_id) as serviceAttempts">
									<table class="table table-bordered"
										*ngIf="serviceAttempts.length > 0; else noServiceAttempts">
										<thead>
											<tr>
												<th>ID</th>
												<th>Date</th>
												<th>Type</th>
												<th>Status</th>
												<th>Executive</th>
												<th>Notes</th>
												<th>Actions</th>
											</tr>
										</thead>
										<tbody>
											<tr *ngFor="let attempt of serviceAttempts">
												<td>{{ attempt.id }}</td>
												<td>{{ attempt.date | date:'MMM d, yyyy h:mm a' }}</td>
												<td>{{ attempt.serviceType }}</td>
												<td>{{ attempt.status }}</td>
												<td>{{ attempt.executive }}</td>
												<td>{{ attempt.notes }}</td>
												<td>
													<mat-icon (click)="editServiceAttempt(attempt.id)"
														class="cursor-pointer">edit</mat-icon>
												</td>
											</tr>
										</tbody>
									</table>
									<ng-template #noServiceAttempts>
										<p>No service attempts recorded for this student.</p>
									</ng-template>
								</ng-container>
							</div>
						</div>
					</div>
				</td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let element; columns: displayedColumns;"
				class="element-row"
				[class.expanded]="expandedElements[element.student_id]">
			</tr>
			<tr mat-row
				*matRowDef="let row; columns: ['expandedDetail']; when: isExpansionDetailRow"
				class="detail-row"
				[class.expanded]="expandedElements[row.student_id]">
			</tr>
			<tr class="mat-row" *matNoDataRow>
				<td class="mat-cell" colspan="12">No sales data found for this period</td>
			</tr>
		</table>

		<!-- No Sales Data Table section removed -->
	</div>
</div>

<div id="enrollment-agreement">
    <div class="page1">

        <div id="enrollment-agreement-new">
            <p class="text-center"><img style="max-width:350px;" src="assets/images/AMDCBlackHorizontalJPG.jpg" /></p>
            <!-- Student Enrollment Agreement Section -->
            <div class="container border p-3 bg-white text-dark">
                <h4 class="fw-bold text-center">STUDENT ENROLLMENT AGREEMENT</h4>
                <div class="d-flex justify-content-between">
                    <div>
                        <p class="mb-1">{{
                            this.studioInformationConfig?.field_studio_address?.[0]?.address_line1
                            }},
                            {{
                            this.studioInformationConfig?.field_studio_address?.[0]?.locality
                            }},
                            {{
                            this.studioInformationConfig?.field_studio_address?.[0]?.administrative_area
                            }}
                            {{
                            this.studioInformationConfig?.field_studio_address?.[0]?.postal_code
                            }}</p>
                        <p class="mb-1">{{
                            this.studioInformationConfig?.field_studio_phone_number?.[0]?.value
                            }}</p>
                        <p class="mb-1">{{
                            this.studioInformationConfig?.field_studio_email?.[0]?.value
                            }}</p>
                    </div>
                    <div class="text-end">
                        <p class="fw-bold mb-1">AGREEMENT DATE: <span
                                class="text-success">{{
                                this.entityData?.['field_sale_date'] | date
                                }}</span></p>
                        <p class="fw-bold">TOTAL FEES: <span
                                class="text-success">${{ calculateTotalTuition()
                                | number:'1.2-2' }}</span></p>
                    </div>
                </div>
            </div>

            <h4>1. PARTIES TO THE AGREEMENT</h4>
            <div class="row mb-3">
                <div class="col-12">
                    <div class="mb-3">
                        <p class="d-flex">
                            <span class="fw-bold me-2">STUDIO OWNER
                                INFORMATION:</span>
                            <span class="flex-grow-1 mx-1 border-bottom"
                                style="min-width: 250px;">{{
                                this.studioInformationConfig?.field_studio_display_name?.[0].value
                                }}</span>
                            <span>("us")</span>
                        </p>
                    </div>

                    <div class="mb-3">
                        <p class="d-flex">
                            <span class="fw-bold me-2">STUDIO ADDRESS:</span>
                            <span class="flex-grow-1 mx-1 border-bottom"
                                style="min-width: 250px;">{{
                                this.studioInformationConfig?.field_studio_address?.[0]?.address_line1
                                }},
                                {{
                                this.studioInformationConfig?.field_studio_address?.[0]?.locality
                                }},
                                {{
                                this.studioInformationConfig?.field_studio_address?.[0]?.administrative_area
                                }}
                                {{
                                this.studioInformationConfig?.field_studio_address?.[0]?.postal_code
                                }}</span>
                            <span>("the Studio")</span>
                        </p>
                    </div>

                    <h6 class="fw-bold">Buyer Information:</h6>
                    <div class="border p-2">
                        <table class="table table-borderless mb-0">
                            <tr>
                                <td class="fw-bold"
                                    style="width: 10%">Name:</td>
                                <td style="width: 40%">{{
                                    entityData?.['field_student']?.['title'] ||
                                    'Not specified' }} ("you")</td>
                                <td class="fw-bold" style="width: 15%">Email
                                    Address:</td>
                                <td style="width: 35%">{{
                                    this.studentAccountEntityData?.field_contacts?.field_email
                                    || 'Not specified' }}</td>
                            </tr>
                            <tr>
                                <td class="fw-bold">Cell phone#:</td>
                                <td>{{
                                    this.studentAccountEntityData?.field_contacts?.field_cell_phone
                                    || 'Not specified' }}</td>
                                <td class="fw-bold">Address:</td>
                                <td>
                                    <ng-container
                                        *ngIf="this.studentAccountEntityData?.field_contacts?.field_address">
                                        {{
                                        this.studentAccountEntityData?.field_contacts?.field_address?.address_line1
                                        || '' }}
                                        <ng-container
                                            *ngIf="this.studentAccountEntityData?.field_contacts?.field_address?.locality">
                                            , {{
                                            this.studentAccountEntityData?.field_contacts?.field_address?.locality
                                            }}
                                        </ng-container>
                                        <ng-container
                                            *ngIf="this.studentAccountEntityData?.field_contacts?.field_address?.administrative_area">
                                            , {{
                                            this.studentAccountEntityData?.field_contacts?.field_address?.administrative_area
                                            }}
                                        </ng-container>
                                        <ng-container
                                            *ngIf="this.studentAccountEntityData?.field_contacts?.field_address?.postal_code">
                                            {{
                                            this.studentAccountEntityData?.field_contacts?.field_address?.postal_code
                                            }}
                                        </ng-container>
                                    </ng-container>
                                    <ng-container
                                        *ngIf="!this.studentAccountEntityData?.field_contacts?.field_address">
                                        Not specified
                                    </ng-container>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>

            <h4>2. SUMMARY OF CHARGES</h4>

            <div class="row mb-3">
                <div
                    class="col-12 d-flex justify-content-between align-items-center">
                    <span class="fw-semibold">Enrollment Type:</span>
                    <span>
                        <input type="checkbox" id="enrollmentOriginal"
                            class="form-check-input me-1" [disabled]="true" [checked]="entityData?.field_category == '50'">
                        <label for="enrollmentOriginal"
                            class="me-2">Original</label>
                        <input type="checkbox" id="enrollmentExtension"
                            class="form-check-input me-1" [disabled]="true" [checked]="entityData?.field_category == '51'">
                        <label for="enrollmentExtension"
                            class="me-2">Extension</label>
                        <input type="checkbox" id="enrollmentRenewal"
                            class="form-check-input me-1" [disabled]="true" [checked]="entityData?.field_category == '65'">
                        <label for="enrollmentRenewal"
                            class="me-2">Renewal</label>
                        <input type="checkbox" id="enrollmentService"
                            class="form-check-input me-1" [disabled]="true" [checked]="entityData?.field_category == '5'">
                        <label for="enrollmentService">Service</label>
                    </span>
                </div>
            </div>

            <div class="row mb-3">
                <div
                    class="col-12 d-flex justify-content-between align-items-center">
                    <span class="fw-semibold">Lesson Type:</span>
                    <span>
                        <input type="checkbox" id="lessonPersonal"
                            class="form-check-input me-1" [disabled]="true" [checked]="true">
                        <label for="lessonPersonal" class="me-2">Personal
                            Lessons</label>
                        <input type="checkbox" id="lessonClass"
                            class="form-check-input me-1" [disabled]="true" [checked]="false">
                        <label for="lessonClass" class="me-2">Class
                            Lessons</label>
                        <input type="checkbox" id="lessonPractice"
                            class="form-check-input me-1" [disabled]="true" [checked]="false">
                        <label for="lessonPractice" class="me-2">Practice
                            Lessons</label>
                        <input type="checkbox" id="lessonVideo"
                            class="form-check-input me-1" [disabled]="true" [checked]="false">
                        <label for="lessonVideo">Video Studies</label>
                    </span>
                </div>
            </div>

            <div class="mb-3 d-flex justify-content-between align-items-center">
                <span class="fw-semibold">Tuition Per Lesson:</span>
                <span>
                    $ <span class="border-bottom px-2 mx-1">_______</span> per
                    hour &nbsp;
                    $ <span class="border-bottom px-2 mx-1">_______</span> per
                    hour &nbsp;
                    $ <span class="border-bottom px-2 mx-1">_______</span> per
                    hour &nbsp;
                    $ <span class="border-bottom px-2 mx-1">_______</span> per
                    hour
                </span>
            </div>

            <div class="mb-3 d-flex">
                <span class="fw-semibold me-2">Number of Lessons
                    Purchased:</span>
                <span class="flex-grow-1 border-bottom">{{
                    entityData?.['field_enrollment_lesson_count'] }}</span>
            </div>

            <div class="mb-3 d-flex">
                <span class="fw-semibold me-2">Total Tuition/Cost:</span>
                <span class="flex-grow-1 border-bottom">${{
                    getOriginalPackageCost() }}</span>
            </div>

            <div class="mb-3 d-flex">
                <span class="fw-semibold me-2">Other Services:</span>
                <span class="flex-grow-1 border-bottom"></span>
            </div>

            <div class="mb-3 d-flex">
                <span class="fw-semibold me-2">Discount:</span>
                <span class="flex-grow-1 border-bottom">
                    <ng-container
                        *ngIf="this.entityData?.field_discount_type == 'flat'">
                        ${{ entityData?.field_discount ?
                        this.entityData?.field_discount : '0' }}
                    </ng-container>
                    <ng-container
                        *ngIf="this.entityData?.field_discount_type == 'percentage'">
                        {{ entityData?.field_discount ?
                        this.entityData?.field_discount : '0' }}%
                    </ng-container>
                    <ng-container
                        *ngIf="this.entityData?.field_discount_type != 'percentage' && this.entityData?.field_discount_type != 'flat'">
                        ${{ entityData?.field_discount ?
                        this.entityData?.field_discount : '0' }}
                    </ng-container>
                </span>
            </div>

            <div class="mb-3 d-flex">
                <span class="fw-semibold me-2">Sales Tax:</span>
                <span class="flex-grow-1 border-bottom">{{
                    entityData?.field_tax_percentage ?
                    this.entityData?.field_tax_percentage : '0' }}%</span>
            </div>

            <div class="mb-3 d-flex">
                <span class="fw-bold me-2">Total Fees Due & Payable <span
                        class="text-primary">By</span> You ("Fees"):</span>
                <span class="flex-grow-1 border-bottom">${{
                    entityData?.field_enrollment_total_price ?
                    this.entityData?.field_enrollment_total_price : '0'
                    }}</span>
            </div>

            <div class="row print-d-none">
                <p class="text-center">
                    <small><a class="cursor-pointer"
                            (click)="requestPrint(true, false)">Click here to view back
                            of agreement (fully included and opened when printed or
                            emailed)</a></small>
                </p>
            </div>

            <div
                *ngIf="enrollmentForm?.get('field_payments_structure')?.value !== 'paid_in_full'">
                <h4>3. Lending Disclosures for Budget Courses or Studio Payment
                    Programs</h4>
                <div class="row mb-3">
                    <div class="col-12">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-6">
                                    <table class="table table-bordered">
                                        <tr>
                                            <td>1. Total Fees under this
                                                agreement...................</td>
                                            <td>${{ calculateTotalTuition() |
                                                number:'1.2-2' }}</td>
                                        </tr>
                                        <tr>
                                            <td>2. Outstanding Balance of
                                                Previous Agreement...</td>
                                            <td>${{
                                                entityData?.field_outstanding_balance_pc
                                                ?
                                                entityData?.field_outstanding_balance_pc
                                                : '0' | number:'1.2-2' }}</td>
                                        </tr>
                                        <tr>
                                            <td>3. Unearned Finance
                                                Charge.....................</td>
                                            <td>${{
                                                entityData?.field_unearned_finance_charge
                                                ?
                                                entityData?.field_unearned_finance_charge
                                                : '0' | number:'1.2-2' }}</td>
                                        </tr>
                                        <tr>
                                            <td>4. Previous Balance to be
                                                Refinanced (2-3), if any..</td>
                                            <td>${{
                                                (entityData?.field_outstanding_balance_pc
                                                -
                                                (entityData?.field_unearned_finance_charge
                                                || 0)) | number:'1.2-2' }}</td>
                                        </tr>
                                        <tr>
                                            <td>5. Consolidated Cash Price
                                                (1+4).......................</td>
                                            <td>${{ (calculateTotalTuition() +
                                                (entityData?.field_outstanding_balance_pc
                                                -
                                                (entityData?.field_unearned_finance_charge
                                                || 0))) | number:'1.2-2' }}</td>
                                        </tr>
                                        <tr>
                                            <td>6. Cash Down
                                                Payment.............................</td>
                                            <td>${{
                                                entityData?.field_payment_amount
                                                ?
                                                entityData?.field_payment_amount
                                                : '0' | number:'1.2-2' }}</td>
                                        </tr>
                                    </table>

                                    <div class="p-3 mb-2"
                                        style="border: 1px solid #000; background-color: #f2f2f2;">
                                        <strong>AMOUNT FINANCED:</strong><br>
                                        The amount of credit that is provided to
                                        you or on your
                                        behalf...................................
                                        <span style="float: right;">${{
                                            entityData?.field_enrollment_total_price
                                            }}</span>
                                    </div>

                                    <div class="p-3 mb-2"
                                        style="border: 1px solid #000; background-color: #f2f2f2;">
                                        <strong>FINANCE CHARGE:</strong><br>
                                        The dollar amount the credit will cost
                                        you......
                                        <span style="float: right;">${{
                                            entityData?.field_finance_charge ?
                                            entityData?.field_finance_charge :
                                            '0' }}</span>
                                    </div>

                                    <div class="p-3 mb-2"
                                        style="border: 1px solid #000; background-color: #f2f2f2;">
                                        <strong>TOTAL OF PAYMENTS</strong><br>
                                        The amount you will have paid after you
                                        have made all payments as
                                        scheduled........................
                                        <span style="float: right;">${{
                                            calculateTotalTuition() |
                                            number:'1.2-2' }}</span>
                                    </div>

                                    <div class="p-3 mb-2"
                                        style="border: 1px solid #000; background-color: #f2f2f2;">
                                        <strong>TOTAL SALE PRICE</strong><br>
                                        The total cost of your purchase on
                                        credit including the down payment of ${{
                                        entityData?.field_payment_amount ?
                                        entityData?.field_payment_amount : '0' |
                                        number:'1.2-2' }}...........
                                        <span style="float: right;">${{
                                            calculateTotalTuition() |
                                            number:'1.2-2' }}</span>
                                    </div>

                                    <div class="p-3 mb-2"
                                        style="border: 1px solid #000; background-color: #f2f2f2;">
                                        <strong>ANNUAL PERCENTAGE
                                            RATE</strong><br>
                                        The cost of your credit as a yearly
                                        rate............
                                        <span style="float: right;">{{
                                            entityData?.field_finance_apr ?
                                            entityData?.field_finance_apr : '0'
                                            }}%</span>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <strong>YOUR PAYMENT SCHEDULE WILL
                                        BE:</strong>
                                    <table class="table table-bordered mt-2">
                                        <tr>
                                            <th class="text-center">No. of
                                                Payments</th>
                                            <th class="text-center">Amount of
                                                Payments</th>
                                            <th class="text-center">{{
                                                entityData?.field_payments_structure_type
                                                === 'weekly' ? 'Weekly' :
                                                'Monthly' }} Beginning</th>
                                        </tr>
                                        <tr>
                                            <td class="text-center">{{
                                                (entityData?.field_scheduled_payments?.length)
                                                ||
                                                (entityData?.field_scheduled_payments?.['0']
                                                ? 1 : 0) }}</td>
                                            <td class="text-center">${{
                                                (entityData?.field_scheduled_payments?.[0]?.['0']?.field_payment_amount)
                                                ||
                                                (entityData?.field_scheduled_payments?.['0']?.field_payment_amount)
                                                || '0' | number:'1.2-2' }}</td>
                                            <td class="text-center">{{
                                                entityData?.field_next_scheduled_payment
                                                ||
                                                (entityData?.field_scheduled_payments?.[0]?.['0']?.field_payment_date
                                                | date) ||
                                                (entityData?.field_scheduled_payments?.['0']?.field_payment_date
                                                | date) || 'Not specified'
                                                }}</td>
                                        </tr>
                                    </table>
                                    <p>and on the same {{
                                        entityData?.field_payments_structure_type
                                        === 'weekly' ? 'day each week' :
                                        'date each month' }} thereafter until
                                        paid in full.</p>

                                    <div class="mt-4">

                                        <div class="p-3"
                                            style="border: 1px solid #000; background-color: #f8f9fa;">
                                            <strong>NOTICE TO YOU:</strong> DO
                                            NOT SIGN THIS AGREEMENT BEFORE YOU
                                            READ IT OR IF IT CONTAINS ANY BLANK
                                            SPACES INTENDED FOR YOU. AGREED
                                            TERMS TO THE EXTENT OF THEN
                                            AVAILABLE INFORMATION ARE LEFT
                                            BLANK. YOU ARE ENTITLED TO A COPY OF
                                            THE AGREEMENT AT THE TIME YOU SIGN
                                            IT. KEEP THIS AGREEMENT TO PROTECT
                                            YOUR LEGAL RIGHT.
                                        </div>

                                        <div class="mt-3">
                                            <p>If you pay off early, you:</p>
                                            <div class="ms-4">
                                                <div class="d-flex align-items-center mb-2">
                                                    <div class="form-check me-2 mb-0">
                                                        <input class="form-check-input" type="checkbox" id="penaltyYes">
                                                        <label class="form-check-label" for="penaltyYes">may</label>
                                                    </div>
                                                    <div class="form-check me-2 mb-0">
                                                        <input class="form-check-input" type="checkbox" id="penaltyNo" checked>
                                                        <label class="form-check-label" for="penaltyNo" [checked]="!entityData?.field_early_payment_penalty">will not</label>
                                                    </div>
                                                    <span class="ms-4">have to pay a penalty</span>
                                                </div>
                                                <div class="d-flex align-items-center">
                                                    <div class="form-check me-2 mb-0">
                                                        <input class="form-check-input" type="checkbox" id="refundYes" [checked]="entityData?.field_early_payment_refund">
                                                        <label class="form-check-label" for="refundYes">may</label>
                                                    </div>
                                                    <div class="form-check me-2 mb-0">
                                                        <input class="form-check-input" type="checkbox" id="refundNo" [checked]="!entityData?.field_early_payment_refund">
                                                        <label class="form-check-label" for="refundNo">will not</label>
                                                    </div>
                                                    <span class="ms-4">be entitled to a refund or part of the finance charge, under rule of 78, pro-rata or actuarial method, whichever, is applicable in your state.</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>

        <div class="row">
            <div class="col-6">
                <!-- Studio Information -->
                <div class="text-center">
                    <p>{{
                        this.studioInformationConfig?.field_studio_display_name?.[0].value
                        }}</p>
                    <p>
                        {{
                        this.studioInformationConfig?.field_studio_address?.[0]?.address_line1
                        }}
                        {{
                        this.studioInformationConfig?.field_studio_address?.[0]?.locality
                        }}
                        {{
                        this.studioInformationConfig?.field_studio_address?.[0]?.administrative_area
                        }}.
                        {{
                        this.studioInformationConfig?.field_studio_address?.[0]?.postal_code
                        }}
                    </p>
                    <p>Phone: {{
                        this.studioInformationConfig?.field_studio_phone_number?.[0]?.value
                        }}</p>
                    <p>Email: {{
                        this.studioInformationConfig?.field_studio_email?.[0]?.value
                        }}</p>
                    <p>{{
                        this.studioInformationConfig?.field_studio_website?.[0]?.title
                        }}</p>
                </div>
            </div>

            <div class="col-6">
                <div class="row">
                    <p>Signed on: {{ this.entityData?.['field_sale_date'] | date
                        }}</p>

                    <div
                        *ngIf="displaySignatures && this._enrollmentEntityData?.['field_representative_signature']?.[0]?.['url']"
                        class="col-6">
                        <p><strong>Executive Approval:</strong></p>
                        <img class="img-fluid"
                            [src]="this._enrollmentEntityData?.['field_representative_signature'][0]?.['url']" />
                    </div>
                    <div
                        *ngIf="displaySignatures && this._enrollmentEntityData?.['field_signature']?.[0]?.['url']"
                        class="col-6">
                        <p><strong>Student Signature:</strong></p>
                        <img class="img-fluid"
                            [src]="this._enrollmentEntityData?.['field_signature']?.[0]?.['url']" />
                    </div>
                    <div
                        *ngIf="displaySignatures && this._enrollmentEntityData?.['field_parent_guardian_signature']?.[0]?.['url']"
                        class="col-12 mt-3">
                        <p><strong>Parent/Guardian Signature:</strong></p>
                        <img class="img-fluid"
                            [src]="this._enrollmentEntityData?.['field_parent_guardian_signature'][0]?.['url']" />
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 d-none print-d-none">
            <button *ngIf="displayPrintButton" (click)="printPage(false, true)"
                class="me-1" type="button" mat-stroked-button color="primary">
                Print2123
            </button>
            <button *ngIf="displayEmailButton" class="me-1" type="button"
                mat-stroked-button color="primary"
                (click)="sendEmailNewEnrollment(this.enrollmentID)">
                Email2
            </button>
        </div>

        <div class="mt-3"></div>
    </div>
</div>

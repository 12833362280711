import { Component, Input, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { BaseEnrollmentComponent } from '../base-enrollment/base-enrollment.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DialogService } from 'src/app/services/dialog.service';
import { EntityRESTService } from 'src/app/services/entity-rest.service';
import { UtilityService } from 'src/app/services/utility.service';
import { FieldsService } from 'src/app/services/fields.service';
import { DrupalRESTService } from 'src/app/services/drupal-rest.service';
import { TaxonomyService } from 'src/app/services/taxonomy.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthRESTService } from 'src/app/services/auth-rest.service';
import { AuthService } from 'src/app/services/auth.service';
import { MenuRESTService } from 'src/app/services/menu-rest.service';
import { RouteEventsService } from 'src/app/route-events.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { Renderer2, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ConfigPagesService } from 'src/app/services/config-pages.service';
import { PackagesService } from 'src/app/services/packages.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from 'src/app/services/user.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RefreshService } from 'src/app/services/refresh.service';

@Component({
  selector: 'app-enrollment-agreement-display',
  templateUrl: './enrollment-agreement-display.component.html',
  styleUrls: ['./enrollment-agreement-display.component.css']
})
export class EnrollmentAgreementDisplayComponent extends BaseEnrollmentComponent implements OnInit {
  @Input() override entityData: any;
  @Input() override studentAccountEntityData: any;
  @Input() override studioInformationConfig: any;
  @Input() override enrollmentForm: any;
  @Input() displaySignatures = true;
  @Input() override _enrollmentEntityData: any;
  @Input() displayPrintButton = true;
  @Input() displayEmailButton = true;

  @Output() printRequested = new EventEmitter<{ showBackOnly: boolean; showFrontOnly: boolean; }>();

  constructor(
    public override _dialogRef: MatDialogRef<unknown>,
    public override _dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public override _injectedDialogData: any,
    public override _refreshService: RefreshService,
    public override _dialogService: DialogService,
    public override _entityRESTService: EntityRESTService,
    public override _utilityService: UtilityService,
    public override _fieldsService: FieldsService,
    public override _drupalRESTService: DrupalRESTService,
    public override _taxonomyService: TaxonomyService,
    public override _router: Router,
    public override _activatedRoute: ActivatedRoute,
    public override _authRESTService: AuthRESTService,
    public override _authService: AuthService,
    public override _menuRESTService: MenuRESTService,
    public override _formBuilder: FormBuilder,
    public override _routeEventsService: RouteEventsService,
    public override _permissionsService: PermissionsService,
    public override _renderer2: Renderer2,
    public override _configPages: ConfigPagesService,
    public override _packagesService: PackagesService,
    public override _snackBar: MatSnackBar,
    public override _userRESTService: UserService,
    public override _el: ElementRef,
    public override _changeDetectorRef: ChangeDetectorRef,
    public override _sanitizer: DomSanitizer
  ) {
    super(
      _dialogRef,
      _dialog,
      _injectedDialogData,
      _refreshService,
      _dialogService,
      _entityRESTService,
      _utilityService,
      _fieldsService,
      _drupalRESTService,
      _taxonomyService,
      _router,
      _activatedRoute,
      _authRESTService,
      _authService,
      _menuRESTService,
      _formBuilder,
      _routeEventsService,
      _permissionsService,
      _renderer2,
      _configPages,
      _packagesService,
      _snackBar,
      _userRESTService,
      _el,
      _changeDetectorRef,
      _sanitizer
    );
  }

  /**
   * Calculate the original package cost
   */
  getOriginalPackageCost(): number {
    if (this.entityData?.field_enrollment_lesson_price && this.entityData?.field_enrollment_lesson_count) {
      return this.entityData.field_enrollment_lesson_price * this.entityData.field_enrollment_lesson_count;
    }
    return 0;
  }

  /**
   * Calculate the total tuition
   */
  calculateTotalTuition(): number {
    if (this.entityData?.field_enrollment_total_price) {
      return this.entityData.field_enrollment_total_price;
    }
    return 0;
  }

  /**
   * Emits an event requesting the parent component to print.
   * @param showBackOnly Whether to show only the back page.
   * @param showFrontOnly Whether to show only the front page.
   */
  requestPrint(showBackOnly: boolean, showFrontOnly: boolean): void {
    this.printRequested.emit({ showBackOnly, showFrontOnly });
  }
}

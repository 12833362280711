import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatTabGroup } from '@angular/material/tabs';
import * as moment from 'moment';
import { Moment } from 'moment';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';
import { BaseComponent } from '../base-form/base-form.component';

@Component({
  selector: 'app-event-services-entity',
  templateUrl: './event-services-entity.component.html',
  styleUrls: ['./event-services-entity.component.css']
})
export class EventServicesEntityComponent extends BaseDialogComponent implements OnInit {
  override eckType = "events";
  override bundle = "services";
  override entityId = this._injectedDialogData['data']['EntityID'];

  field_executive: any = null;

  @ViewChild('picker_field_date_time') picker: any;

  override ngAfterViewInit() {
    // Set default value(s) for services.
    setTimeout(() => {
      this.f.form.patchValue({
        field_status: "113" // "Pending Status"
      });
    });

    // Special handling for loading an attendee.
    if (typeof(this.injectedDialogData?.['data']?.['fieldsData']?.['___field_student']) != 'undefined') {
      this.field_student.push(this.injectedDialogData['data']['fieldsData']['___field_student']);
    }

    // console.log('this.fieldstudent')
    // console.log(this.field_student)
  }

  entityType = "attendees";
  entityBundle = "attendance";

  isFormValid(): boolean {
    if (!this.f?.value) return false;

    const requiredFields = {
      field_type: this.f.value.field_type,
      field_executive: this.f.value.field_executive,
      field_status: this.f.value.field_status,
      field_date_and_time: this.f.value.field_date_and_time
    };

    // Check if all required fields have values
    const hasRequiredFields = Object.values(requiredFields).every(value =>
      value !== null && value !== undefined && value !== '');

    // Check if we have at least one attendee
    const hasAttendees = Array.isArray(this.field_student) && this.field_student.length > 0;

    // If in edit mode for attendee, check if the inline form is valid
    if (this.editMode) {
      const inlineForm = this.f.controls['__field_students_inline_form'];
      if (inlineForm && inlineForm.value) {
        const hasRequiredAttendeeFields =
          inlineForm.value.field_student_account &&
          inlineForm.value.field_students;
        return hasRequiredFields && hasAttendees && hasRequiredAttendeeFields;
      }
      // If in edit mode but inline form is not properly initialized, form is not valid
      return false;
    }

    return hasRequiredFields && hasAttendees;
  }

  override onSubmit(form: NgForm) {
    // Remove the inline form values before submission
    const formValues = { ...form.value };
    delete formValues.__field_students_inline_form;

    if (!this.isFormValid()) {
      this.errorMessage = "Please fill in all required fields and add at least one attendee";
      return;
    }

    // Continue with existing submit logic
    super.onSubmit(form);
  }

  studentIncrement(attendee: any) {
    // Append type & bundle to model.
    attendee = {
      ...attendee,
      type: 'attendees',
      bundle: 'attendance',
    }

    console.log('attendee', attendee);

    // If our data binding fails us (API needs to give an array), set as an empty array.
    if (!Array.isArray(this.field_student)) { this.field_student = [] }

    this.field_student.push(attendee);
    this.resetFieldStudent();

    // Force form validation update
    this.f.form.updateValueAndValidity();
  }

  studentRemove = (index: number): void => {
    this.f.form.controls['__field_students_inline_form'].reset();
    this.editMode = false;
    this.field_student.splice(index, 1);

    this.resetFieldStudent();
  }

  studentUpdateLoad = (index: number): void => {
    this.resetFieldStudent();

    if (!this.field_student || !Array.isArray(this.field_student) || !this.field_student[index]) {
      console.error('Invalid field_student array or index', {
        fieldStudent: this.field_student,
        index: index
      });
      return;
    }

    const studentAccount = this.field_student[index].field_student_account;
    if (typeof studentAccount !== 'string' || studentAccount.trim() === '') {
      console.error('Invalid field_student_account', {
        index: index,
        studentAccount: studentAccount
      });
      return;
    }

    // Load the student enrollments again.
    this.lookupStudentAccountContact({ option: { value: studentAccount } });
    // Load the student contacts again.
    this.onStudentNameSelectThenLoadEnrollmentName({ option: { value: studentAccount } });

    // Append type & bundle to model.
    this.field_student[index]['type'] = 'attendees';
    this.field_student[index]['bundle'] = 'attendance';

    this.editIndex = index;
    this.editMode = true;

    // If field_student is a couple.
    if (this.field_student[index]['field_students'] !== undefined) {
      this.field_student[index]['field_students'] = this.field_student[index]['field_students'].toString();
    }

    Promise.resolve().then(() => {
      if (this.f.controls['__field_students_inline_form']) {
        this.f.controls['__field_students_inline_form'].patchValue(this.field_student[index]);
      } else {
        console.error('__field_students_inline_form control is not available');
      }
    });
  }

  studentUpdateSubmit(number: any, attendee: any) {
    this.field_student[this.editIndex] = attendee;
    this.f.form.controls['__field_students_inline_form'].reset();
    this.editMode = false;
    this.resetFieldStudent();

    // Force form validation update after updating attendee
    this.f.form.updateValueAndValidity();
  }

  resetFieldStudent() {
    this.enrollmentMessage = "";
    this.autoCompleteStudentContactOptions = null;
    this.enrollmentsForUser = null;
    this.f.form.controls['__field_students_inline_form'].reset();
    this.f.form.controls['__field_students_inline_form'].setErrors(null);

    // Force form validation update
    this.f.form.updateValueAndValidity();
  }

  openDateTimePicker() {
    // Set to current time rounded to nearest 15 minutes
    const currentDate = moment();
    const minutes = Math.floor(currentDate.minutes() / 15) * 15;
    const roundedDate = currentDate.clone()
      .minutes(minutes)
      .seconds(0)
      .milliseconds(0);

    // Update the field and trigger change handlers
    this.field_date_and_time = roundedDate.format('YYYY-MM-DD HH:mm:ss');
    this.onDateChange(roundedDate);
    this.uiUpdateDate(roundedDate);

    // Force change detection if needed
    this._changeDetectorRef.detectChanges();

    // Open the picker
    this.picker.open();
  }

  setCurrentRoundedTime() {
    // Only set current time if no date is already selected. Also check the form value.
    if (!this.field_date_and_time && !this.f.value.field_date_and_time) {
      console.log('setCurrentRoundedTime??', this.field_date_and_time, this.f.value.field_date_and_time);
      const currentDate = moment();
      const minutes = Math.floor(currentDate.minutes() / 15) * 15;
      const roundedDate = currentDate.clone()
        .minutes(minutes)
        .seconds(0)
        .milliseconds(0);

      // Update the field and trigger change handlers
      this.field_date_and_time = roundedDate.format('YYYY-MM-DD HH:mm:ss');
      this.onDateChange(roundedDate);
      this.uiUpdateDate(roundedDate);

      // Force change detection if needed
      this._changeDetectorRef.detectChanges();
    }
  }

  onPickerClosed(picker: any) {
    // Get the selected value from the picker
    const selectedDate = picker.selected;
    if (selectedDate) {
      const momentDate = moment(selectedDate);
      // Round to nearest 15 minutes
      const minutes = Math.floor(momentDate.minutes() / 15) * 15;
      const roundedDate = momentDate.clone()
        .minutes(minutes)
        .seconds(0)
        .milliseconds(0);

      // Update the field and trigger change handlers
      this.field_date_and_time = roundedDate.format('YYYY-MM-DD HH:mm:ss');
      this.onDateChange(roundedDate);
      this.uiUpdateDate(roundedDate);

      this._changeDetectorRef.detectChanges();
    }
  }
}


import { Component, OnInit, ElementRef, ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import moment from 'moment';
import { ViewTableComponent } from '../../base/view-table/view-table.component';
import { HttpParameter } from 'src/app/types/http-parameter';
import { PrintService } from 'src/app/services/print.service';
import { DialogService } from 'src/app/services/dialog.service';
import { EntityRESTService } from 'src/app/services/entity-rest.service';
import { UtilityService } from 'src/app/services/utility.service';
import { FieldsService } from 'src/app/services/fields.service';
import { DrupalRESTService } from 'src/app/services/drupal-rest.service';
import { TaxonomyService } from 'src/app/services/taxonomy.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthRESTService } from 'src/app/services/auth-rest.service';
import { AuthService } from 'src/app/services/auth.service';
import { MenuRESTService } from 'src/app/services/menu-rest.service';
import { FormBuilder } from '@angular/forms';
import { RouteEventsService } from 'src/app/route-events.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { ConfigPagesService } from 'src/app/services/config-pages.service';
import { PackagesService } from 'src/app/services/packages.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from 'src/app/services/user.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Renderer2 } from '@angular/core';
import { RefreshService } from 'src/app/services/refresh.service';
@Component({
  selector: 'app-payments-due',
  templateUrl: './payments-due.component.html',
  styleUrls: ['./payments-due.component.css']
})
export class PaymentsDueComponent extends ViewTableComponent implements OnInit {

  currentPage: number;

  override displayedColumns: string[] = [
    'field_payment_date_value',
    // 'id',
    'field_student_target_id',
    'title',
    'field_balance_due_value',
    'field_payment_amount_value',
    // 'field_payment_interval',
    'field_lesson_available_value',
    // 'field_enrollment_lesson_count',
    // 'field_enrollment_lesson_price',
    // 'field_lesson_used',
    // 'field_lessons_paid',
    'field_instructor_target_id',
    // 'field_lesson_type_label',
    // 'field_sps_code_label',
  ];

  constructor(
    private _printService: PrintService,
    public override _refreshService: RefreshService,
    public override _dialogService: DialogService,
    public override _entityRESTService: EntityRESTService,
    public override _utilityService: UtilityService,
    public override _fieldsService: FieldsService,
    public override _drupalRESTService: DrupalRESTService,
    public override _taxonomyService: TaxonomyService,
    public override _router: Router,
    public override _activatedRoute: ActivatedRoute,
    public override _authRESTService: AuthRESTService,
    public override _authService: AuthService,
    public override _menuRESTService: MenuRESTService,
    public override _formBuilder: FormBuilder,
    public override _routeEventsService: RouteEventsService,
    public override _permissionsService: PermissionsService,
    public override _renderer2: Renderer2,
    public override _configPages: ConfigPagesService,
    public override _packagesService: PackagesService,
    public override _snackBar: MatSnackBar,
    public override _userRESTService: UserService,
    public override _el: ElementRef,
    public override _changeDetectorRef: ChangeDetectorRef,
    public override _sanitizer: DomSanitizer
  ) {
    super(
      _refreshService,
      _dialogService,
      _entityRESTService,
      _utilityService,
      _fieldsService,
      _drupalRESTService,
      _taxonomyService,
      _router,
      _activatedRoute,
      _authRESTService,
      _authService,
      _menuRESTService,
      _formBuilder,
      _routeEventsService,
      _permissionsService,
      _renderer2,
      _configPages,
      _packagesService,
      _snackBar,
      _userRESTService,
      _el,
      _changeDetectorRef,
      _sanitizer
    );
  }

  // Helper method to format currency values
  private formatCurrency(value: number | string | null | undefined): string {
    if (value === null || value === undefined) {
      return '0.00';
    }

    const numValue = typeof value === 'string' ? parseFloat(value) : value;
    return numValue.toFixed(2);
  }

  // Format date for display
  private formatDate(dateString: string | null | undefined): string {
    if (!dateString) return 'N/A';
    return moment(dateString).format('MM/DD/YYYY');
  }

  override onPrint() {
    // Build the HTML content for printing
    let content = `
      <div class="report-container">
        <h1 class="report-title">Payments Due Report</h1>

        <div class="report-section">
          <table class="report-table">
            <thead>
              <tr>
                <th>Due Date</th>
                <th>Student</th>
                <th>Enrollment</th>
                <th>Amount Due</th>
                <th>Remaining Balance</th>
                <th>Lessons Remaining</th>
                <th>Teacher</th>
              </tr>
            </thead>
            <tbody>`;

    // Add rows for each payment due
    if (this.dataSource && this.dataSource.data && this.dataSource.data.length > 0) {
      this.dataSource.data.forEach((payment: any) => {
        content += `
          <tr>
            <td>${this.formatDate(payment.field_payment_date)}</td>
            <td>${payment.title_2 || 'N/A'}</td>
            <td>${payment.enrollment_title || 'N/A'}</td>
            <td>$${this.formatCurrency(payment.field_payment_amount)}</td>
            <td>$${this.formatCurrency(payment.field_balance_due)}</td>
            <td>${payment.field_lesson_available || '0'}</td>
            <td>${payment.field_first_name || ''} ${payment.field_last_name || ''}</td>
          </tr>`;
      });
    } else {
      content += `
        <tr>
          <td colspan="7" class="no-data">No payments due found for the selected criteria</td>
        </tr>`;
    }

    content += `
            </tbody>
          </table>
        </div>
      </div>`;

    // Define custom styles for the report
    const styles = `
      .report-container {
        font-family: Arial, sans-serif;
        margin: 0 auto;
        padding: 20px;
        max-width: 100%;
      }
      .report-title {
        font-size: 24px;
        text-align: center;
        margin-bottom: 20px;
        color: #333;
      }
      .report-section {
        margin-bottom: 30px;
        page-break-inside: avoid;
      }
      .report-table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 20px;
      }
      .report-table th, .report-table td {
        border: 1px solid #ddd;
        padding: 8px;
        text-align: left;
      }
      .report-table th {
        background-color: #f2f2f2;
        font-weight: bold;
      }
      .report-table tr:nth-child(even) {
        background-color: #f9f9f9;
      }
      .no-data {
        text-align: center;
        font-style: italic;
        color: #999;
      }
      @media print {
        .report-section {
          page-break-inside: avoid;
        }
      }
    `;

    // Use the PrintService to print the report
    this._printService.print(content, {
      title: 'Payments Due Report',
      styles: styles,
      showSchoolName: true
    });
  }

  override resetForm() {
    // Clear all stored params
    this.queryParams = [];
    this.sortParams = undefined;

    // Reset pagination
    if (this.paginator) {
      this.paginator.pageIndex = 0;
      this.paginationIndex = 0;  // Reset the pagination index
      this.pageSize = 10;  // Reset page size
    }

    // Clear the form
    this.form.resetForm();

    // Clear any autocomplete options
    this.autoCompleteStudentAccountOptions = [];

    // Make a fresh request with explicitly empty params
    this.getData([]);

    // Update router last
    this.updateRouterParams({});
  }

  override pageChanged(event: any) {
    // Update pagination state
    this.paginationIndex = event.pageIndex;
    this.pageSize = event.pageSize;

    // Always include pagination parameters
    const paginationParams: HttpParameter[] = [
      { parameter: 'page', value: this.paginationIndex.toString() },
      { parameter: 'items_per_page', value: this.pageSize.toString() }
    ];

    // Filter out any existing pagination params from queryParams
    const filteredQueryParams = this.queryParams.filter(param =>
      param.parameter !== 'page' && param.parameter !== 'items_per_page'
    );

    // Combine filtered query params with new pagination params
    const combinedParams = [...filteredQueryParams, ...paginationParams];
    this.getData(combinedParams);
  }

  override getData(params: HttpParameter[]) {
    console.log('getData called with params:', params);

    this.ShowProgressBar = true;

    // Store only non-pagination params in queryParams
    this.queryParams = params.filter(param =>
      param.parameter !== 'page' && param.parameter !== 'items_per_page'
    );

    // Add current pagination state to params if not present
    let finalParams = [...params];
    if (!params.some(p => p.parameter === 'page')) {
      finalParams.push(
        { parameter: 'page', value: this.paginationIndex.toString() },
        { parameter: 'items_per_page', value: this.pageSize.toString() }
      );
    }

    // Convert params array to object for date processing
    const paramsObj = finalParams.reduce((obj, item) => {
      obj[item.parameter] = item.value;
      return obj;
    }, {});

    // Process the params for start and end dates
    const processedDateParams = this.processParams(paramsObj);

    // Combine all params
    finalParams = [...finalParams, ...processedDateParams];

    // Add sorting parameters if available
    if (this.sortParams !== undefined) {
      finalParams.push(this.sortParams);
    }

    let endpoint: string = "/api/v1/reports/enrollments-payments-due";

    this._drupalRESTService.httpGET(endpoint, finalParams)
      .subscribe({
        next: (data) => {
          this.ShowProgressBar = false;
          this.dataSource = new MatTableDataSource(data['results']);

          if (data['results']) {
            this.paginator.length = data['count'];
            this.paginator.pageSize = this.pageSize ?? 0;
            this.paginator.pageIndex = this.paginationIndex;
          }
        },
        error: (error) => this.handleError(error)
      });
  }

  override ngOnInit() {
    // Initialize pagination values
    this.paginationIndex = 0;
    this.pageSize = 10;  // Set default page size

    // Call parent ngOnInit if it exists
    super.ngOnInit();

    // Initial data load
    this.getData([
      { parameter: 'page', value: this.paginationIndex.toString() },
      { parameter: 'items_per_page', value: this.pageSize.toString() }
    ]);
  }
}

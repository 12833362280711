import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormArray, NgForm, AbstractControl } from '@angular/forms';
import _ from 'lodash';
import moment from 'moment';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';
import { CreditCardReadInputComponent } from '../credit-card-read-input/credit-card-read-input.component';
import { BehaviorSubject } from 'rxjs';
import { SquareWebPaymentComponent } from '../square-web-payment/square-web-payment.component';
import { SquareTerminalPaymentComponent } from '../square-terminal-payment/square-terminal-payment.component';
import { v4 as uuidv4 } from 'uuid';
import { SquareTerminalSaveCardComponent } from '../square-terminal-save-card-component/square-terminal-save-card-component.component';

@Component({
  selector: 'app-payments-entity-form',
  templateUrl: './payments-entity-form.component.html',
  styleUrls: ['./payments-entity-form.component.css']
})
export class PaymentsEntityFormComponent extends BaseDialogComponent implements OnInit {

  // @ViewChild(SquareWebPaymentComponent) squareWebPayment: SquareWebPaymentComponent;

  override eckType = "payments";
  override bundle = "payment";
  override entityId = this._injectedDialogData['data']['EntityID'];

  private _studentData = new BehaviorSubject<any>(null);
  _enrollmentData = new BehaviorSubject<any>(null);

  @Input() isNested: boolean = false;
  @Input() set studentData(value: any) {
    this._studentData.next(value);
  }
  @Input() set enrollmentData(value: any) {
    this._enrollmentData.next(value);
  }

  @Output() paymentSubmitted = new EventEmitter<any>();

  CreditCardReadInputComponent = CreditCardReadInputComponent;
  SquareTerminalPaymentComponent = SquareTerminalPaymentComponent;

  paymentDeleteForm: FormGroup;
  field_enrollment_name: any;
  __field_enrollment_name: any;
  isSubmitting: boolean = false;

  vatTaxRate = 0;
  gstTaxRate = 0;
  salesTaxRate = 0;

  instructorPercentageEditMode: boolean = false;
  enrollmentPaymentError: string = '';

  taxBreakdownSummary: string = '';
  isUpdatingTaxes = false;
  showTaxBreakdown: boolean = false; // To toggle visibility of tax breakdown


  toggleTaxBreakdown() {
    this.showTaxBreakdown = !this.showTaxBreakdown;
  }

  override ngOnInit(): void {
    this.initActionType = this._injectedDialogData['data']['action'];
    this.actionType = this._injectedDialogData['data']['action'];

    let formGroup = {
      field_payment_id: [this.paymentID, Validators.required],
      field_date_and_time: [this._enrollmentData?.['field_date_and_time'] || '', Validators.required], // [this.todaysDate, Validators.required],
      field_gross_tuition: [null, Validators.required],
      field_student_name: [null, Validators.required],
      field_scheduled_payment: this._formBuilder.group({
        'target_id': [null]
      }),
      field_payment_type: [null, Validators.required],
      cc_number: [null],
      cc_cvv: [null],
      cc_expiration: [null],
      cc_expiration_month: [null],
      cc_expiration_year: [null],
      cc_postal_code: [null],
      field_stripe_charge_id: [this.entityData?.field_stripe_charge_id],
      reason_to_refund: [null],
      field_payments_use_card: [null],
      field_tax_rate_at_time_of_pay: [null],
      field_total_payment_amount: [null],
      field_tax_breakdown: this.initActionType === 'create' ? this._formBuilder.array([]) : this._formBuilder.control(''),
      field_tax_collected: [null],
      field_sales_tax_amount: [null],
      field_notes: [null],
    }

    let formGroupEdit;

    if (this.initActionType == "create") {
      formGroupEdit = {
        ...formGroup,
        field_enrollment_name: new FormArray([
          this._formBuilder.group({
            'target_id': [null, Validators.required],
            'payment_amount': [0, Validators.required],
          })
        ]),
      }
      this.loadTaxRatesFromConfig();
    } else {
      formGroupEdit = {
        ...formGroup,
        field_enrollment_name: this._formBuilder.group({
          'target_id': [null, Validators.required]
        }),
        field_tax_collected: [null], // Add field_tax_collected here for non-create states
      }
      this.loadTaxRatesFromEntity();
    }

    this.paymentForm = this._formBuilder.group(formGroupEdit);

    // Subscribe to changes in field_gross_tuition
    this.paymentForm.get('field_gross_tuition').valueChanges.subscribe(value => {
      this.updateTotalPaymentAmount();
      this.updateTaxesAndTotal();
    });

    // Subscribe to changes in field_tax_rate_at_time_of_pay
    this.paymentForm.get('field_tax_rate_at_time_of_pay').valueChanges.subscribe(value => {
      this.updateTaxesAndTotal();
    });

    this.paymentDeleteForm = this._formBuilder.group({
      field_reason_to_delete: [null, Validators.required],
    });

    this.paymentForm.get('field_payment_type').valueChanges.subscribe(value => {
      if (value == 1407 /* Stripe */ && this.initActionType == 'create') {
        this.paymentForm.controls['field_payments_use_card'].setValidators([Validators.required]);
      } else {
        this.paymentForm.controls['field_payments_use_card'].clearValidators();
      }
      this.paymentForm.controls['field_payments_use_card'].updateValueAndValidity();
    });

    if (this.initActionType === 'create') {
      this.paymentForm.get('field_gross_tuition').valueChanges.subscribe(value => {
        this.distributeGrossTuitionEqually(value);
      });

      this.paymentForm.get('field_gross_tuition').valueChanges.subscribe(() => {
        setTimeout(() => this.checkEnrollmentPayments(), 0);
      });

      this.paymentForm.get('field_enrollment_name').valueChanges.subscribe(() => {
        setTimeout(() => this.checkEnrollmentPayments(), 0);
      });

      if (Array.isArray(this.paymentForm.get('field_enrollment_name').value)) {
        this.paymentForm.get('field_enrollment_name').valueChanges.subscribe(() => {
          const enrollmentFormArray = this.paymentForm.get('field_enrollment_name') as FormArray;
          enrollmentFormArray.controls.forEach((group: FormGroup, index: number) => {
            if (group.dirty) {
              this.enrollmentTouched = index;
            }
          });
        });
      }

      this.checkEnrollmentPayments();
    }

    if (this.initActionType == 'create') {
      this._drupalRESTService.httpGET('/api_rest/v1/loadStudioConfig?config_field_name=field_tax_ref').subscribe((response: any[]) => {
        this.setTaxBreakdown(response, true);
      });
    } else if (this.entityData && this.entityData.field_tax_breakdown) {
      this.setTaxBreakdown(this.entityData.field_tax_breakdown, true);
    }


    if (this.isNested) {
      this._studentData.subscribe(student => {
        if (student) {
          const studentName = `${student.title} (${student.id})`;
          this.paymentForm.patchValue({
            field_student_name: studentName
          });
          this.onStudentNameSelectThenLoadEnrollmentName(student.id, false, null, true);
        }
      });

      this._enrollmentData.subscribe(enrollment => {
        if (enrollment) {
          console.log('Enrollment data:', enrollment);

          // Update the payment date using the enrollment's sale date
          if (enrollment.field_sale_date) {
            this.paymentForm.patchValue({
              field_date_and_time: enrollment.field_sale_date
            });
          }
          // this.paymentForm.patchValue({
          //   field_enrollment_name: {
          //     target_id: enrollment.id
          //   }
          // });

          const fieldEnrollmentName = this.paymentForm.get('field_enrollment_name') as FormArray;

          // Clear the existing array
          fieldEnrollmentName.clear();

          // Add the new enrollment
          fieldEnrollmentName.push(this._formBuilder.group({
            target_id: enrollment.id,
            payment_amount: 0
          }));
        }
      });
    }

    this.paymentForm.get('field_payments_use_card')?.valueChanges.subscribe(value => {
      if (value === 'add_new_card' && this.paymentForm.get('field_payment_type')?.value === '1422') {
        // Reset the value to prevent immediate re-triggering
        this.paymentForm.get('field_payments_use_card')?.setValue(null, { emitEvent: false });
        // Open the save card dialog
        this.openSquareTerminalSaveCard();
      }
    });
  }


  override getEntity(eckType: string, bundle: string, EntityID: number, formRef?) {
    this.displayProgressSpinner(true);

    this._entityRESTService.getEntity(eckType, bundle, EntityID)
      .subscribe((data: any) => {
        // If initActionType is refund, parse field_stripe_charge_object as json. TODO: this should be differently.
        if (this.initActionType == 'refund') {
          try {
            data.field_stripe_charge_object = JSON.parse(data?.['field_stripe_charge_object']);
          } catch (e) {
            // Do nothing.
          }
        }
        if (this.initActionType == 'edit') {
          data.field_tax_breakdown = data.field_tax_breakdown || '[]'; // Ensure it's initialized as a string
          console.log('data.field_tax_breakdown:', data.field_tax_breakdown);

          // Create a summary of the tax breakdown
          this.taxBreakdownSummary = this.createTaxBreakdownSummary(data.field_tax_breakdown);
        }

        this.entityData = data;

        // Progress spinner.
        this.displayProgressSpinner(false);

        // Update form values
        this.setFormValues(formRef);
      });
  }

  createTaxBreakdownSummary(taxBreakdown: string): string {
    let parsedTaxBreakdown: any[] = [];
    try {
      parsedTaxBreakdown = JSON.parse(taxBreakdown);
    } catch (e) {
      console.error('Error parsing tax breakdown JSON:', e);
    }

    if (!Array.isArray(parsedTaxBreakdown)) {
      return '';
    }

    return parsedTaxBreakdown.map(tax => {
      return `${tax.field_tax_type}: ${tax.field_tax_value}${tax.field_calculation_method === 'PERCENTAGE' ? '%' : ''}`;
    }).join(', ');
  }

  setTaxRates(response: any) {
    this.salesTaxRate = Number(response?.['field_sales_tax_rate']?.[0]?.['value']) || 0;
    this.gstTaxRate = Number(response?.['field_gst_tax_rate']?.[0]?.['value']) || 0;
    this.vatTaxRate = Number(response?.['field_vat_tax_rate']?.[0]?.['value']) || 0;
  }

  private handleNoTaxConfig(isEditMode: boolean) {
    console.log('Handling no tax configuration');
    const taxBreakdownArray = this.paymentForm.get('field_tax_breakdown') as FormArray;
    taxBreakdownArray.clear();

    if (!isEditMode) {
      this.paymentForm.patchValue({
        field_tax_rate_at_time_of_pay: 0,
        field_tax_collected: 0
      });
      this.updateTaxesAndTotal();
    }
  }

  setTaxBreakdown(taxConfig: any[] | null, isEditMode: boolean) {
    console.log('Setting tax breakdown:', taxConfig);

    const taxBreakdownArray = this.paymentForm.get('field_tax_breakdown') as FormArray;
    if (!taxBreakdownArray) {
      console.error('field_tax_breakdown FormArray is not initialized');
      return;
    }
    taxBreakdownArray.clear();

    if (!taxConfig || !Array.isArray(taxConfig) || taxConfig.length === 0) {
      console.warn('Invalid or empty tax configuration received');
      this.handleNoTaxConfig(isEditMode);
      return;
    }

    const currentDate = moment();

    taxConfig.forEach(tax => {
      if (!tax || typeof tax !== 'object') {
        console.warn('Invalid tax object in configuration, skipping');
        return;
      }

      const startDate = this.getTaxDate(tax, 'field_effective_tax_start_date');
      const endDate = this.getTaxDate(tax, 'field_effective_tax_end_date');

      if (this.isTaxEffective(startDate, endDate, currentDate)) {
        const taxGroup = this.createTaxGroup(tax);
        taxBreakdownArray.push(taxGroup);

        // Subscribe to changes in this tax group
        taxGroup.valueChanges.subscribe(() => {
          this.updateTaxesAndTotal();
        });
      }
    });

    this.updateFormValuesAfterTaxBreakdown(isEditMode);
  }

  private getTaxDate(tax: any, fieldName: string): moment.Moment | null {
    const dateValue = tax[fieldName]?.[0]?.value;
    return dateValue ? moment(dateValue) : null;
  }

  private isTaxEffective(startDate: moment.Moment | null, endDate: moment.Moment | null, currentDate: moment.Moment): boolean {
    return (!startDate || currentDate.isSameOrAfter(startDate)) && (!endDate || currentDate.isSameOrBefore(endDate));
  }

  private createTaxGroup(tax: any) {
    return this._formBuilder.group({
      field_tax_type: [this.getTaxValue(tax, 'field_tax_type'), Validators.required],
      field_tax_value: [this.getTaxValue(tax, 'field_tax_value'), Validators.required],
      field_calculation_method: [this.getTaxValue(tax, 'field_calculation_method'), Validators.required],
      field_rounding_rule: [this.getTaxValue(tax, 'field_rounding_rule'), Validators.required],
    });
  }

  private updateFormValuesAfterTaxBreakdown(isEditMode: boolean) {
    if (isEditMode && this.entityData) {
      // Preserve existing tax-related values in edit mode
      this.paymentForm.patchValue({
        field_tax_rate_at_time_of_pay: this.entityData.field_tax_rate_at_time_of_pay,
        field_tax_collected: this.entityData.field_tax_collected,
        field_total_payment_amount: this.entityData.field_total_payment_amount
      });
    } else {
      this.updateTaxesAndTotal();
    }
  }

  private getTaxValue(tax: any, field: string): string | null {
    return tax[field]?.[0]?.value || null;
  }

  updateTaxesAndTotal() {
    if (this.isUpdatingTaxes || this.initActionType === 'edit') {
      return;
    }

    this.isUpdatingTaxes = true;

    try {
      const grossTuition = Number(this.paymentForm.get('field_gross_tuition').value) || 0;
      const taxBreakdownArray = this.paymentForm.get('field_tax_breakdown') as FormArray;

      let totalTaxAmount = 0;
      let effectiveTaxRate = 0;

      if (taxBreakdownArray.length > 0) {
        taxBreakdownArray.controls.forEach((taxControl: AbstractControl) => {
          const tax = taxControl.value;
          if (!tax.field_tax_value || !tax.field_calculation_method) {
            return; // Skip incomplete tax entries
          }

          let taxAmount = 0;

          if (tax.field_calculation_method === 'PERCENTAGE') {
            taxAmount = grossTuition * (parseFloat(tax.field_tax_value) / 100);
          } else if (tax.field_calculation_method === 'FLAT_RATE') {
            taxAmount = parseFloat(tax.field_tax_value);
          }

          if (tax.field_rounding_rule) {
            taxAmount = this.applyRoundingRule(taxAmount, tax.field_rounding_rule);
          }

          totalTaxAmount += taxAmount;
        });

        effectiveTaxRate = grossTuition > 0 ? (totalTaxAmount / grossTuition) * 100 : 0;
      }

      const totalPaymentAmount = grossTuition + totalTaxAmount;

      this.paymentForm.patchValue({
        field_total_payment_amount: totalPaymentAmount.toFixed(2),
        field_tax_rate_at_time_of_pay: effectiveTaxRate.toFixed(2),
        field_tax_collected: totalTaxAmount.toFixed(2)
      }, { emitEvent: false });

    } finally {
      this.isUpdatingTaxes = false;
    }
  }

  private applyRoundingRule(amount: number, rule: string): number {
    switch (rule) {
      case 'ROUND_UP':
        return Math.ceil(amount * 100) / 100;
      case 'ROUND_DOWN':
        return Math.floor(amount * 100) / 100;
      case 'ROUND_NEAREST':
        return Math.round(amount * 100) / 100;
      case 'NO_ROUNDING':
      default:
        return parseFloat(amount.toFixed(2));
    }
  }

  addTaxBreakdown() {
    const taxBreakdownArray = this.paymentForm.get('field_tax_breakdown') as FormArray;
    const newTaxGroup = this._formBuilder.group({
      field_tax_type: [null, Validators.required],
      field_tax_value: [null, Validators.required],
      field_calculation_method: [null, Validators.required],
      field_rounding_rule: [null, Validators.required],
    });

    // Subscribe to changes in the new tax group
    newTaxGroup.valueChanges.subscribe(() => {
      this.updateTaxesAndTotal();
    });

    taxBreakdownArray.push(newTaxGroup);
  }

  removeTaxBreakdown(index: number) {
    const taxBreakdownArray = this.paymentForm.get('field_tax_breakdown') as FormArray;
    taxBreakdownArray.removeAt(index);

    // Trigger tax recalculation after removing a tax breakdown entry
    this.updateTaxesAndTotal();
  }

  getTaxBreakdownControls() {
    return (this.paymentForm.get('field_tax_breakdown') as FormArray).controls;
  }

  override ngAfterViewInit() {
    setTimeout(() => {
      this._loadFieldsData(this.injectedDialogData['data']['fieldsData'], this.paymentForm);

      if (this.initActionType == 'create' || this.initActionType == 'delete' || this.initActionType == 'refund') {
        this.getUniquePaymentID().subscribe(data => {
          this.paymentID = data;
          if (this.initActionType == 'refund') {
            this.paymentSaleDate = moment();
            this._loadFieldsData(this.injectedDialogData['data']['fieldsData'], this.paymentForm.value);

            if (this.paymentForm.value.field_payment_type != 1407 && this.paymentForm.value.field_status != 'Refund') {
              this.paymentForm.controls['field_date_and_time'].reset();
              this.paymentForm.controls['field_payment_type'].reset();
            }

            this.paymentForm.controls['reason_to_refund'].setValue('requested_by_customer');
          }
        });
      }
    }, 500);
  }

  checkEnrollmentPayments() {
    const enrollmentPayments = this.paymentForm.get('field_enrollment_name').value || [];
    let grossTuition = Number(this.paymentForm.get('field_gross_tuition').value) || 0;

    // Handle refund case by taking the absolute value of grossTuition
    if (this.initActionType === 'refund') {
      return;
    }

    let totalEnrollmentPayments = 0;

    if (Array.isArray(enrollmentPayments)) {
      totalEnrollmentPayments = enrollmentPayments.reduce((sum, enrollment) => sum + (Number(enrollment.payment_amount) || 0), 0);
    } else {
      totalEnrollmentPayments = Number(enrollmentPayments.payment_amount) || 0;
    }

    if (totalEnrollmentPayments !== grossTuition) {
      this.enrollmentPaymentError = "The total of all enrollment payments must match the gross tuition.";
    } else {
      this.enrollmentPaymentError = '';
    }
  }

  onSubmitPayment(f: NgForm, drop = false) {
    let idempotencyKey = `payment_${new Date().getTime()}`;
    this.isSubmitting = true;
    this.displayProgressSpinner(true);

    this.checkEnrollmentPayments();
    if (this.enrollmentPaymentError) {
      this.displayProgressSpinner(false);
      this.isSubmitting = false;
      return;
    }

    if (this.initActionType == "create" || this.initActionType == "refund") {
      this.errorMessage = "";
      let values = _.cloneDeep(f.value);
      this.__alterValues(values);

      if (this.initActionType == "create") {
        values.enrollment_ids = values.field_enrollment_name;
        values.enrollment_payments = values.field_enrollment_name.map(enrollment => {
          return {
            enrollment_id: enrollment.target_id,
            payment_amount: enrollment.payment_amount
          };
        });
      } else {
        values.enrollment_ids = [values.field_enrollment_name];
        values.field_gross_tuition = -(Math.abs(values.field_gross_tuition));
        values.field_stripe_charge_id = this.entityData?.field_stripe_charge_id;
      }

      if (this.initActionType == "refund") {
        this.paymentForm.value.field_gross_tuition = -(Math.abs(this.paymentForm.value.field_gross_tuition));
      }

      let body = {
        ...values,
        idempotency_key: idempotencyKey
      };

      // Handle different payment types
      switch (this.paymentForm.get('field_payment_type').value) {
        case '4': // 4 is the ID for Square payments
          // this.squareWebPayment.processPayment();
          this.processDefaultPayment(body, drop);
          break;
        case 1407: // Assuming 1407 is the ID for Stripe payments
          this.processStripePayment(body, drop);
          break;
        default:
          this.processDefaultPayment(body, drop);
          break;
      }
    } else {
      this.onSubmit(this.paymentForm);
    }
  }

  private processDefaultPayment(body: any, drop: boolean) {
    this._drupalRESTService.httpPOST('/api_rest/v1/postPayment', body).subscribe(
      data => this.handlePaymentSuccess(data, drop),
      error => this.handlePaymentError(error)
    );
  }

  private handlePaymentError(error: any) {
    console.error("Payment error:", error);

    // Default values
    let errorMessage = "Unknown error occurred";
    let duration = 5000;

    // Handle HttpErrorResponse
    if (error && error.error && error.error.message) {
      // API returned error message
      errorMessage = error.error.message;
    } else if (error && error.message) {
      // Direct error message
      errorMessage = error.message;
    }

    // Special handling for specific error messages
    if (errorMessage === "Enrollment has already been paid for.") {
      errorMessage = "This enrollment has already been paid for. Please refresh the page to see the updated status.";
      duration = 8000;
    } else if (errorMessage === "Payment amount cannot be more than total scheduled payments for all posted enrollments.") {
      errorMessage = "The payment amount exceeds the total amount due. Please verify the payment amount matches the scheduled payments.";
      duration = 8000;
    } else if (error && error.status === 406) {
      errorMessage = "The payment request was not accepted. Please verify all payment details are correct and try again.";
      duration = 8000;
    }

    console.log('errorMessage', errorMessage);
    this.errorMessage = errorMessage;
    this.isSubmitting = false;
    this.displayProgressSpinner(false);

    this._snackBar.open(`Payment failed: ${errorMessage}`, 'Close', { duration: duration });
  }

  private handlePaymentSuccess(data: any, drop: boolean) {
    this.isSubmitting = false;
    this.displayProgressSpinner(false);

    if (drop) {
      this.handleEnrollmentDrop();
    } else {
      if (this.isNested) {
        this.paymentSubmitted.emit(data);
      } else {
        this.closeDialog();
      }
    }
  }

  private handleEnrollmentDrop() {
    let params = [
      { parameter: 'enrollment_id', value: this.paymentForm.value.field_enrollment_name.target_id },
      { parameter: 'drop_date', value: moment(this.paymentForm.value.field_date_and_time).format("YYYY/MM/DD") },
    ];
    this._drupalRESTService.httpGET('/api_rest/v1/dropEnrollment', params).subscribe(
      data => {
        this.displayProgressSpinner(false);
        if (data['success']) {
          this.closeDialog();
        }
      },
      error => {
        this.isSubmitting = false;
        this.errorMessage = "There was a problem dropping this enrollment.";
        this.handleError(error);
        this.displayProgressSpinner(false);
      }
    );
  }

  private processStripePayment(body: any, drop: boolean) {
    // Implement Stripe-specific payment logic here
    // This should be similar to the existing logic in your current onSubmitPayment method
    this.createStripePayment(body, drop);
  }

  private createStripePayment(body: any, drop: boolean) {
    // Implement Stripe payment creation logic
    // This should be similar to your existing Stripe payment creation process
    this._drupalRESTService.httpPOST('/api_rest/v1/createStripePayment', body).subscribe(
      data => this.handlePaymentSuccess(data, drop),
      error => this.handlePaymentError(error)
    );
  }

  override onDeleteSubmit(form: NgForm) {
    this.displayProgressSpinner(true);
    this._entityRESTService.getEntity(this.eckType, this.bundle, this.entityId).subscribe(data => {
      let body = data;
      body = {
        field_status: 'Deleted',
        field_reason_to_delete: this.paymentDeleteForm.value.field_reason_to_delete,
        field_enrollment_name: { target_id: data?.['field_enrollment_name']?.['id'] },
        field_student_name: data?.['field_student_name']?.['id'],
      };

      this._entityRESTService.patchEntity(this.eckType, this.bundle, this.entityId, body).subscribe(data => {
        this.displayProgressSpinner(false);
        this.closeDialog();

        this._entityRESTService.deleteEntity(this.eckType, this.bundle, this.entityId).subscribe(data => {
          this.displayProgressSpinner(false);
          this.closeDialog();
        }), error => this.handleError(error)

      }, error => this.handleError(error));
    });
    let body = {
      ...this._entityData,
      field_status: 'Deleted'
    };
    let type = this.eckType;
    let bundle = this.bundle;
    let id = this._injectedDialogData['data']['EntityID'];
  }

  splitAmountValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const grossTuition = this.paymentForm?.get('field_gross_tuition')?.value;
    if (control.value > grossTuition) {
      return { 'exceedsGrossTuition': true };
    }
    return null;
  }

  getIconName(paymentType: string): string {
    switch (paymentType) {
      case 'AMEX':
        return 'credit_card'; // or another relevant icon
      case 'Cash Payment':
        return 'attach_money';
      case 'Check':
        return 'check';
      case 'Credit':
      case 'Credit Card':
        return 'credit_card';
      case 'Discover':
        return 'credit_card'; // or another relevant icon
      case 'Exempt Adjustment':
        return 'money_off';
      case 'MasterCard':
        return 'credit_card'; // or another relevant icon
      case 'Other':
        return 'more_horiz';
      case 'PayPal':
        return 'account_balance_wallet';
      case 'Square':
        return 'crop_square';
      case 'Stripe':
        return 'stripe'; // Custom icon or suitable alternative
      case 'Venmo':
        return 'payments';
      case 'Visa':
        return 'credit_card'; // or another relevant icon
      default:
        return 'payment';
    }
  }

  loadTaxRatesFromConfig() {
    if (this.initActionType === 'edit') {
      console.log('Edit mode: Preserving existing tax values');
      return;
    }

    this._drupalRESTService.httpGET('/api_rest/v1/loadStudioConfig?config_field_name=field_tax_ref').subscribe(
      (response: any) => {
        console.log('Tax configuration response:', response);

        if (!response || (Array.isArray(response) && response.length === 0)) {
          console.log('No tax configuration found');
          this.handleNoTaxConfig(false);
          return;
        }

        const taxConfig = Array.isArray(response) ? response : [response];
        this.setTaxBreakdown(taxConfig, false);
      },
      error => {
        console.error('Error loading tax rates:', error);
        this.handleNoTaxConfig(false);
      }
    );
  }

  loadTaxRatesFromEntity() {
    if (!this.entityData || this.initActionType !== 'edit') {
      console.log('Not in edit mode or no entity data available');
      return;
    }

    console.log('Loading tax rates from entity:', this.entityData);

    if (this.entityData.field_tax_breakdown) {
      try {
        let taxBreakdown = JSON.parse(this.entityData.field_tax_breakdown);
        this.setTaxBreakdown(taxBreakdown, true); // true indicates it's edit mode
      } catch (e) {
        console.error('Error parsing tax breakdown from entity:', e);
        this.handleNoTaxConfig(true); // true indicates it's edit mode
      }
    } else {
      console.warn('No tax breakdown found in entity data');
      this.handleNoTaxConfig(true); // true indicates it's edit mode
    }

    // Set other tax-related fields
    this.paymentForm.patchValue({
      field_tax_rate_at_time_of_pay: this.entityData.field_tax_rate_at_time_of_pay,
      field_tax_collected: this.entityData.field_tax_collected,
      field_total_payment_amount: this.entityData.field_total_payment_amount
    });
  }

  updateTotalPaymentAmount() {
    const grossTuition = Number(this.paymentForm.get('field_gross_tuition').value) || 0;
    const taxRate = Number(this.paymentForm.get('field_tax_rate_at_time_of_pay').value) || 0;
    const flatRateTax = Number(this.paymentForm.get('field_tax_collected').value) || 0;

    const percentageTaxAmount = grossTuition * (taxRate / 100);
    const totalTaxAmount = percentageTaxAmount + flatRateTax;
    const totalPaymentAmount = grossTuition + totalTaxAmount;

    this.paymentForm.patchValue({
      field_total_payment_amount: totalPaymentAmount.toFixed(2)
    });

    console.log('Gross Tuition:', grossTuition);
    console.log('Tax Rate:', taxRate);
    console.log('Flat Rate Tax:', flatRateTax);
    console.log('Total Tax Amount:', totalTaxAmount);
    console.log('Total Payment Amount:', totalPaymentAmount);
  }

  createSquarePayment(token: string) {
    const paymentData = {
      sourceId: token,
      amount: this.paymentForm.get('field_total_payment_amount').value,
      // Add other necessary payment details
    };

    this._drupalRESTService.httpPOST('/api_rest/v1/createSquarePayment', paymentData).subscribe(
      (response) => {
        console.log('Square payment created:', response);
        this.closeDialog();
      },
      (error) => {
        console.error('Error creating Square payment:', error);
        this.handleError(error);
      }
    );
  }

  onSquarePaymentComplete(token: string) {
    const paymentData = {
      sourceId: token,
      amount: this.paymentForm.get('field_total_payment_amount').value,
      idempotency_key: `payment_${new Date().getTime()}`,
      // Add other necessary payment details from this.paymentForm
    };

    this._drupalRESTService.httpPOST('/api_rest/v1/createSquarePayment', paymentData).subscribe(
      data => this.handlePaymentSuccess(data, false),
      error => this.handlePaymentError(error)
    );
  }

  onSquarePaymentError(error: any) {
    console.error('Square Terminal payment error:', error);

    // Handle specific error messages
    if (!error) {
      this.handlePaymentError({ message: "An unknown error occurred" });
    } else if (error.error?.error === 'No device ID configured for this studio.') {
      // Special handling for no device ID error
      this._snackBar.open(
        'Square Terminal payment failed: No device configured for this studio. Please contact your administrator.',
        'Close',
        { duration: 8000 }
      );
      this.handlePaymentError({
        message: "No Square Terminal device is configured for this studio. Please contact your administrator."
      });
    } else {
      this.handlePaymentError(error);
    }
  }

  getCustomerInfo(): any {
    return {
      firstName: "John",
      lastName: "Doe",
      email: "john.doe@example.com",
      phone: "5551234567",
      address1: "123 Maple Street",
      address2: "Apt 4B",
      city: "Springfield",
      state: "IL",
      country: "US",
    };
    // return {
    //   firstName: this.paymentForm.get('field_first_name')?.value,
    //   lastName: this.paymentForm.get('field_last_name')?.value,
    //   email: this.paymentForm.get('field_email')?.value,
    //   phone: this.paymentForm.get('field_phone')?.value,
    //   address1: this.paymentForm.get('field_address_1')?.value,
    //   address2: this.paymentForm.get('field_address_2')?.value,
    //   city: this.paymentForm.get('field_city')?.value,
    //   state: this.paymentForm.get('field_state')?.value,
    //   country: this.paymentForm.get('field_country')?.value,
    // };
  }

  onSquareTerminalPaymentComplete(event: any) {
    console.log('Square Terminal payment completed:', event);

    if (event?.error) {
      console.error("Square Terminal error:", event.error);
      this.handlePaymentError(event.error);
    } else {
      this.handlePaymentSuccess(event, false);
    }
  }

  onSquareTerminalPaymentError(error: any) {
    console.error('Square Terminal payment error:', error);

    if (!error) {
      this.handlePaymentError({ message: "An unknown error occurred" });
    } else if (error.error?.error === 'No device ID configured for this studio.') {
      // Special handling for no device ID error
      this._snackBar.open(
        'Square Terminal payment failed: No device configured for this studio. Please contact your administrator.',
        'Close',
        { duration: 8000 }
      );
      this.handlePaymentError({
        message: "No Square Terminal device is configured for this studio. Please contact your administrator."
      });
    } else {
      this.handlePaymentError(error);
    }
  }

  openSquareTerminalPayment() {
    const amount = this.paymentForm.get('field_total_payment_amount').value;
    const selectedCard = this.paymentForm.get('field_payments_use_card').value;
    const studentId = this.regexStudentName(this.paymentForm.get('field_student_name')?.value);

    // Basic validation
    if (!amount || amount <= 0) {
      this._snackBar.open('Invalid payment amount. Please enter a valid amount.', 'Close', { duration: 5000 });
      return;
    }

    const dialogData = {
      amount: amount,
      currency: 'USD',
      enrollmentIds: this.paymentForm.get('field_enrollment_name')?.value,
      studentAccountId: studentId,
      studioId: this._authService.studios?.[0]?.id,
      paymentForm: this.paymentForm,
      savedCardId: selectedCard === 'terminal_payment' ? null : selectedCard,
      idempotencyKey: uuidv4(),
    };

    console.log('Opening Square Terminal Payment dialog with data:', dialogData);

    const dialogRef = this._dialogService.openDialog(
      SquareTerminalPaymentComponent,
      'fullScreenCreditCardInput',
      dialogData
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.status === 'SUCCESS') {
          this.onSquareTerminalPaymentComplete(result);
        } else if (result.status === 'FAILURE') {
          this.onSquareTerminalPaymentError(result.error);
        } else if (result.status === 'CANCELED') {
          this._snackBar.open('Payment was canceled', 'Close', { duration: 5000 });
        }
      }
    });
  }

  getEnrollmentIds(): any[] {
    const enrollmentArray = this.paymentForm.get('field_enrollment_name').value;
    if (Array.isArray(enrollmentArray)) {
      return enrollmentArray.map(enrollment => enrollment.target_id);
    }
    return [enrollmentArray.target_id];
  }

  saveCard() {
    const endpoint = `/api/square/terminal/save-card/${this.data.studioId}`;
    const body = {
      studentAccountId: this.data.studentAccountId,
      shouldSaveCard: true
    };

    this._drupalRESTService.httpPOST(endpoint, body).subscribe(
      (response: any) => {
        if (response.success) {
          this._dialogRef.close({
            status: 'SUCCESS',
            action: response.action
          });
        } else {
          this._dialogRef.close({
            status: 'FAILURE',
            error: response.error || 'Failed to save card'
          });
        }
      },
      error => {
        this._dialogRef.close({
          status: 'FAILURE',
          error: error.message || 'Failed to save card'
        });
      }
    );
  }

  openSquareTerminalSaveCard() {
    const studentId = this.regexStudentName(this.paymentForm.get('field_student_name')?.value);

    // Basic validation
    if (!studentId) {
      this._snackBar.open('Please select a student first.', 'Close', { duration: 5000 });
      return;
    }

    const dialogData = {
      studentAccountId: studentId,
      studioId: this._authService.studios?.[0]?.id,
      shouldSaveCard: true,
      idempotencyKey: uuidv4(),
    };

    console.log('Opening Square Terminal Save Card dialog with data:', dialogData);

    const dialogRef = this._dialogService.openDialog(
      SquareTerminalSaveCardComponent,
      'fullScreenCreditCardInput',
      dialogData
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.status === 'SUCCESS') {
          this.onSquareTerminalSaveCardComplete(result);
          // Refresh the saved cards list
          this.loadSquareSavedCards();
        } else if (result.status === 'FAILURE') {
          this.onSquareTerminalSaveCardError(result.error);
        } else if (result.status === 'CANCELED') {
          this._snackBar.open('Card saving was canceled', 'Close', { duration: 5000 });
        }
      }
    });
  }

  onSquareTerminalSaveCardComplete(event: any) {
    console.log('Square Terminal card saving completed:', event);
    this._snackBar.open('Card saved successfully', 'Close', { duration: 5000 });
  }

  onSquareTerminalSaveCardError(error: any) {
    console.error('Square Terminal card saving error:', error);
    this._snackBar.open('Failed to save card: ' + (error.message || 'Unknown error'), 'Close', { duration: 5000 });
  }

  loadSquareSavedCards() {
    const studentId = this.regexStudentName(this.paymentForm.get('field_student_name')?.value);
    const studioId = this._authService.studios?.[0]?.id;

    if (!studentId || !studioId) {
      console.error('Student ID and Studio ID are required');
      return;
    }

    const endpoint = `/api/square/terminal/saved-cards/${studioId}`;
    const params = [
      { parameter: "field_student_name", value: studentId }
    ];

    this._drupalRESTService.httpGET(endpoint, params).subscribe({
      next: (response: any) => {
        console.log('Saved cards loaded:', response);
        this.square_payment_methods = response.cards || [];
      },
      error: (error) => {
        console.error('Error loading saved cards:', error);
        this._snackBar.open('Failed to load saved cards', 'Close', { duration: 5000 });
      }
    });
  }

  isSquarePaymentMethodSelected(): boolean {
    const paymentType = this.paymentForm.get('field_payment_type')?.value;
    const selectedPaymentMethod = this.paymentForm.get('field_payments_use_card')?.value;

    if (paymentType !== '1422') {
      return true;
    }

    // Check if either terminal_payment or a saved card ID is selected
    return selectedPaymentMethod === 'terminal_payment' ||
           (selectedPaymentMethod && this.square_payment_methods?.some(card => card.id === selectedPaymentMethod));
  }

}

import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, NgForm, ValidationErrors, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { DialogService } from 'src/app/services/dialog.service';
import { DrupalRESTService } from 'src/app/services/drupal-rest.service';
import { EntityRESTService } from 'src/app/services/entity-rest.service';
import { FieldsService } from 'src/app/services/fields.service';
import { TaxonomyService } from 'src/app/services/taxonomy.service';
import { UtilityService } from 'src/app/services/utility.service';
import { BaseComponent } from '../base-form/base-form.component';
import _, { split } from 'lodash';
import moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthRESTService } from 'src/app/services/auth-rest.service';
import { AuthService } from 'src/app/services/auth.service';
import { MenuRESTService } from 'src/app/services/menu-rest.service';
import { ComponentType } from '@angular/cdk/overlay';
import { RouteEventsService } from 'src/app/route-events.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { ConfigPagesService } from 'src/app/services/config-pages.service';
import { PackagesService } from 'src/app/services/packages.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from 'src/app/services/user.service';
import { DomSanitizer } from '@angular/platform-browser';
import { RefreshService } from 'src/app/services/refresh.service';

@Component({
  selector: 'app-base-dialog',
  template: '',
})
export class BaseDialogComponent extends BaseComponent implements OnInit {

  constructor(
    public _dialogRef: MatDialogRef<unknown>,
    public _dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public _injectedDialogData: any,
    _refreshService: RefreshService,
    _dialogService: DialogService,
    _entityRESTService: EntityRESTService,
    _utilityService: UtilityService,
    _fieldsService: FieldsService,
    _drupalRESTService: DrupalRESTService,
    _taxonomyService: TaxonomyService,
    _router: Router,
    _activatedRoute: ActivatedRoute,
    _authRESTService: AuthRESTService,
    _authService: AuthService,
    _menuRESTService: MenuRESTService,
    _formBuilder: FormBuilder,
    _routeEventsService: RouteEventsService,
    _permissionsService: PermissionsService,
    _renderer2: Renderer2,
    _configPages: ConfigPagesService,
    _packagesService: PackagesService,
    _snackBar: MatSnackBar,
    _userRESTService: UserService,
    _el: ElementRef,
    _changeDetectorRef: ChangeDetectorRef,
    _sanitizer: DomSanitizer,
  ) {
    super(_refreshService, _dialogService, _entityRESTService, _utilityService, _fieldsService, _drupalRESTService, _taxonomyService, _router, _activatedRoute, _authRESTService, _authService, _menuRESTService, _formBuilder, _routeEventsService, _permissionsService, _renderer2, _configPages, _packagesService, _snackBar, _userRESTService, _el, _changeDetectorRef, _sanitizer);
  }

  @ViewChild('attendee') ngModelGroup: any;
  injectedDialogData = this._injectedDialogData;
  __field_students_inline_form: any;
  enrollmentMessage: any;
  _enrollmentEntityData: any;
  eckType: string = "";
  bundle: string = "";
  entityId: number = this._injectedDialogData?.['data']?.['EntityID'];
  paymentID: any;
  paymentSaleDate;
  payment_methods: any;
  square_payment_methods: any;
  isEventPassed: boolean;
  enrollmentTouched: boolean | number = false; // New property to track if enrollment amount has been touched


  ngOnInit(): void {
    let action = this._injectedDialogData?.['data']?.['action'];

    if (action) {
      this.initActionType = this._injectedDialogData['data']['action'];
      this.actionType = this._injectedDialogData['data']['action'];
    }

    console.log('this._injectedDialogData', this._injectedDialogData);
    this.checkIfEventPassedCurrentTime();
  }

  init() {
    let action = this._injectedDialogData?.['data']?.['action'];

    if (action) {
      this.initActionType = this._injectedDialogData['data']['action'];
      this.actionType = this._injectedDialogData['data']['action'];
    }

    // console.log('this._injectedDialogData', this._injectedDialogData);
  }

  override ngAfterContentInit(): void {
    let action = this._injectedDialogData?.['data']?.['action'];
    console.log('ACTION: ', action)
    if (action) {
      if (this._injectedDialogData['data']['action'] == 'view' || this._injectedDialogData['data']['action'] == 0) {
        this.getEntity(this._injectedDialogData['data']['eckType'], this._injectedDialogData['data']['bundle'], this._injectedDialogData['data']['EntityID']);
        this.setFormMode("view");
      }

      if (this._injectedDialogData['data']['action'] == 'create') {
        this.setFormMode("create");
      }

      if (this._injectedDialogData['data']['action'] == 'edit') {
        this.getEntity(this._injectedDialogData['data']['eckType'], this._injectedDialogData['data']['bundle'], this._injectedDialogData['data']['EntityID']);
        this.setFormMode("edit");
      }

      if (this._injectedDialogData['data']['action'] == 'delete') {
        this.setFormMode("delete")
      }

      if (this._injectedDialogData['data']['action'] == 'refund') {
        // Support for refunds without loading in a payment entity.
        if (!(this._injectedDialogData?.['data']?.['EntityID'])) {
        } else {
          this.getEntity(this._injectedDialogData['data']['eckType'], this._injectedDialogData['data']['bundle'], this._injectedDialogData['data']['EntityID']);
        }

        this.setFormMode("refund");
      }

      // Special case for editing an enrollment.
      if (this._injectedDialogData['data']['action'] == 'editEnrollment') {
        this.actionType = 'edit';
        this.getEntity(this._injectedDialogData['data']['eckType'], this._injectedDialogData['data']['bundle'], this._injectedDialogData['data']['EntityID']);
        this.setFormMode(1);
      }

      // Special case for for signing an enrollment.
      if (this._injectedDialogData['data']['action'] == 'signEnrollment') {
        this.actionType = 'signEnrollment';
        this.initActionType = 'signEnrollment';
        this.getEntity(this._injectedDialogData['data']['eckType'], this._injectedDialogData['data']['bundle'], this._injectedDialogData['data']['EntityID']);
        // this.setFormMode(1);
        // this.selectFormIndex(1);
      }
      // Special case for creating an enrollment.
      if (this._injectedDialogData['data']['action'] == 'create' && this._injectedDialogData['data']['bundle'] == 'enrollment') {
        this._loadFieldsData(this.injectedDialogData?.['data']?.['fieldsData'], this.enrollmentForm);
      }
    }

    if (this.injectedDialogData?.['data']?.['fieldsData']) {
      console.log('loading fields data...', this.injectedDialogData?.['data']?.['fieldsData']);
      this._loadFieldsData(this.injectedDialogData?.['data']?.['fieldsData']);
    }
  }

  ngAfterViewInit() {
  }

  /**
   * Handle data passed from openEntityComponent here.
   *
   * Check for fields data, then patch form values if available.
   *
   * @param fieldsData
   */
  _loadFieldsData(fieldsData, formRef = null) {
    if (this.injectedDialogData?.['data']?.['fieldsData']) {
      let fieldsData = this.injectedDialogData?.['data']?.['fieldsData'];

      setTimeout(() => {
        if (!formRef) {
          this?.f?.form?.patchValue({
            ...fieldsData,
          });
        } else {
          formRef.patchValue({
            ...fieldsData,
          })
        }

      });
    }
  }

  override onSubmit(form): void {
    this.errorMessage = "";
    this.displayProgressSpinner(true);

    // Copy the object to not modify binded data.
    let values;
    if (this.eckType == 'packages' && this.bundle == 'package') {
      values = form.value;
    } else {
      values = _.cloneDeep(form.value);
      this.__alterValues(values);
    }

    // Check for recurring event.
    if (!this.recurringEvent) {
      values.field_repetition_units = null;
      values.field_repetition_frequency = null;
      values.field_repetition_end_date = null;
    }

    // Check for create mode.
    if (this.actionType == "create") {

      this._entityRESTService.createEntity(this.eckType, this.bundle, values)
        .subscribe(data => {
          // TODO: Respond to successful update message...
          if (this.eckType == 'packages' && this.bundle == 'enrollment') {
            this._entityRESTService.getEntity('packages', 'enrollment', data['id'][0].value)
              .subscribe(data => {
                console.log('packages enrollment data', data);
                this.entityData = data;
              });
            this._entityRESTService.getEntity('student_accounts', 'student_account', data['field_student'][0].target_id)
              .subscribe(data => {
                console.log('student_accounts student_account data');
                console.log(data);
                this.studentAccountEntityData = data;
              });
          } else {
            this.closeDialog();
          }

          this.closeDialog();
          this.displayProgressSpinner(false);
        },
          error => this.handleError(error)
        )
    }

    // Check for edit mode.
    if (this.actionType == "edit") {
      this.displayProgressSpinner(true);

      this._entityRESTService.patchEntity(this.eckType, this.bundle, this.entityId, values)
        .subscribe(data => {
          // TODO: Respond to successful update message...
          console.log('data')
          console.log(data)
          this.closeDialog();
          this.displayProgressSpinner(false);
        },
          error => this.handleError(error)
        )
    }

    // Check for edit mode.
    if (this.actionType == "refund") {
      this.displayProgressSpinner(true);

      this._entityRESTService.createEntity(this.eckType, this.bundle, values)
        .subscribe(data => {
          // TODO: Respond to successful update message...
          console.log('data')
          console.log(data)
          this.closeDialog();
          this.displayProgressSpinner(false);
        },
          error => this.handleError(error)
        )
    }
  }


  filterPhoneNumber(phoneNumber: string): string {
    return phoneNumber.replace(/[^0-9]/g, '');
  }

  __alterValues(values) {
    // Alter field_package_name to just send the ID.
    if (values?.field_enrollment_package_name && values.field_enrollment_package_name != "" && /^\d+$/.test(values.field_enrollment_package_name) != true) {
      values.field_enrollment_package_name = this.regexParamID(values.field_enrollment_package_name);
    }

    // Alter student name to just send the ID.
    if (values?.field_student_name && values.field_student_name != "" && /^\d+$/.test(values.field_student_name) != true) {
      values.field_student_name = this.regexStudentName(values.field_student_name);
    }
    if (values?.field_student && typeof values.field_student != 'object' && values.field_student != "" && /^\d+$/.test(values.field_student) != true) {
      values.field_student = this.regexStudentName(values.field_student);
    }

    // Alter nested student fields to just send the ID.
    if (values?.field_students && typeof values.field_students == 'object') {
      values.field_students.forEach((value: any, index: string | number) => {
        if (values.field_students[index]?.field_student_account) {
          values.field_students[index].field_student_account = this.regexStudentName(values.field_students[index].field_student_account);
          values.field_students[index].field_students_contact = this.regexStudentName(values.field_students[index].field_students_contact);
        }
      });
    }

    // Alter nested student fields to just send the ID.
    if (values?.field_student && typeof values.field_student == 'object') {
      values.field_student.forEach((value: any, index: string | number) => {
        if (values.field_student[index]?.field_student_account) {
          values.field_student[index].field_student_account = this.regexStudentName(values.field_student[index].field_student_account);
          values.field_student[index].field_students_contact = this.regexStudentName(values.field_student[index].field_students_contact);
        }
      });
    }

    // Alter field_enrollment_package_name to just send the ID.
    if (values?.field_enrollment_package_name && values.field_enrollment_package_name != "" && /^\d+$/.test(values.field_enrollment_package_name) != true) {
      values.field_enrollment_package_name = this.regexParamID(values.field_enrollment_package_name);
    }

    // [field_student] Split out multiple attendees (support for couples) here.
    if (values?.field_student && typeof values.field_student != 'string') {
      values.field_student.forEach(function (student) {
        if (student?.field_student && student.field_student.indexOf(',') != -1) {
          console.log('Couple detected...')
          student.field_student = student.field_student.split(',')
        }
      });
    }

    // [field_student - variation] Split out multiple attendees (support for couples) here.
    if (values?.field_student && typeof values.field_student != 'string') {
      values.field_student.forEach(function (student) {
        if (student?.field_students && student.field_students.indexOf(',') != -1) {
          console.log('Couple detected...')
          student.field_students = student.field_students.split(',')
        }
      });
    }

    // [field_students] Split out multiple attendees (support for couples) here.
    if (values?.field_students && typeof values.field_students != 'string') {
      values.field_students.forEach(function (student) {
        if (student?.field_students && student.field_students.indexOf(',') != -1) {
          console.log('Couple detected...')
          student.field_students = student.field_students.split(',')
        }
      });
    }

    // Alter certain date fields to match what Drupal is looking for.
    if (values?.field_sale_date) { values.field_sale_date = moment(values.field_sale_date).format('YYYY-MM-DDTHH:mm:ss'); }
    if (values?.field_date_and_time) { values.field_date_and_time = moment(values.field_date_and_time.toString()).format('YYYY-MM-DD[\T]HH:mm:ss'); }
    if (values?.field_inquired) { values.field_inquired = moment(values.field_inquired).format('YYYY-MM-DD'); }
    if (values?.field_showed) { values.field_showed = moment(values.field_showed).format('YYYY-MM-DD'); }
    if (values?.field_booked_on) { values.field_booked_on = moment(values.field_booked_on).format('YYYY-MM-DD'); }
    if (values?.field_expiration_date) { values.field_expiration_date = moment(values.field_expiration_date).format('YYYY-MM-DD'); }
    if (values?.field_scheduled_payments) {
      values.field_scheduled_payments.forEach(function (payments) {
        if (payments?.['field_payment_date']) { payments.field_payment_date = moment(payments.field_payment_date).format('YYYY-MM-DD'); }
      });
    }

    // If any fields are blank (but not falsy values like 0 or false), set them to null.
    for (const element in values) {
      if (values[element] === undefined || values[element] === "") {
        values[element] = null;
      }
    }

    // Fix for packages when package code is blank.
    for (const element in values) {
      if (element === 'field_package_code' && values[element] === '') {
        // delete values[element];
      }
      if (element === 'use_alternating_instructor' && (values[element] === '' || values[element] === undefined || values[element] === false)) {
        delete values[element];
      }
      if (element === 'instructor_alternate_list' && (values[element] === '' || values[element] === undefined || values[element] === false)) {
        delete values[element];
      }
    }
  }

  override onDeleteSubmit(form: NgForm) {
    let values = form.value;

    let body = values;
    let type = this.eckType;
    let bundle = this.bundle;
    let id = this._injectedDialogData['data']['EntityID'];

    console.log('delete called...', id);

    this.displayProgressSpinner(true);
    this._entityRESTService.deleteEntity(type, bundle, id)
      .subscribe(data => {
        // TODO: Respond to successful delete message...
        this.closeDialog();
        this._dialog.closeAll();

        // If deleting a student, redirect to student list.
        if (type == 'contacts' && bundle == 'student_record') {
          this._router.navigate(['/students-list']);
        }

        this.displayProgressSpinner(false);
      },
        error => this.handleError(error)
      );
  }

  override handleError(error: any) {
    console.log("error", error);
    this.displayProgressSpinner(false);

    this.errorMessage = "There was an error.";

    // Handle authentication errors.
    if (error.status == 403 || error.status == 400) {
      this.errorMessage = error.error.message;

      // TODO: this should save state

      // User needs to login.
      if (error.error.message == "This route can only be accessed by anonymous users.") {
        // User is already logged in, forward to the dashboard.
        this._authService.set_user_authenticated(true);
        this._router.navigate(['/app/splash'], { queryParams: { logged_out_inactive: true } })
      } else {
        this._authService.set_user_authenticated(false);
        this._router.navigate(['/auth/login'], { queryParams: { logged_out_inactive: true } })
      }
    }

    if (error.status == 405) {
      this.closeDialog();
    }

    // Handle validation errors.
    if (error.status == 406) {
      let betterErrorMessage = '';
      betterErrorMessage = error.error.message.split(`\n`)
      this.errorMessage = betterErrorMessage;
    }

    // More serious error.
    if (error.status == 500) {
      this.errorMessage = error.error;
      this.closeDialog();
    }

    // 0 isn't an error, so just do what we can.
    if (error.status == 0) {
      // this.errorMessage = error.error;
      this.closeDialog();
    }

    // Emit the data since API doesn't give us correct validation response.
    this.emitDataChangedEvent();

    // Close the dialog
    if (this.actionType == "delete") {
      // this.closeDialog();
    }
  }

  closeDialog(data?) {
    this._dialogRef.close(data);
  }

  validateEnrollment(lessontype: any, student_account_id: string, enrollment_id: any) {
    console.log("validateEnrollment called...")

    this.enrollmentMessage = "";

    student_account_id = this._filterDigits(student_account_id);
    enrollment_id = enrollment_id;

    // All options must be selected.
    if (!lessontype || !student_account_id || !enrollment_id) { return; }

    let params = [
      { parameter: 'lessontype', value: lessontype },
      { parameter: 'studentaccountid', value: student_account_id },
      { parameter: 'enrollmentid', value: enrollment_id },
    ]
    this._drupalRESTService.httpGET('/api_rest/v1/enrollmentcheck', params)
      .subscribe(data => {
        this.enrollmentMessage = '';
        console.log(data)
        if (data['success']) {

        }
        if (data['message']) {
          // this.errorMessage = data['message'];
          this.enrollmentMessage = data['message'];
        }
      });
  }

  updateLessonDefaultTimes(lessonTypeSelected, eventType?) {
    // // If value is already set, don't continue.
    // if (this.f.form.value?.['field_duration'] != '') {
    //   return;
    // }
    let duration = '';
    switch (lessonTypeSelected) {
      case '560': // "DE"
      case '1393': // "Pre"
        duration = '00:30';
        break;
      case '600': // "Back Department"
      case '548': // "Front Department"
        duration = '00:45';
        break;
      default:
        duration = '00:45';
    }

    // All services should default to 15.
    if (eventType == 'service') {
      duration = '00:15';
    }

    if (this?.f?.form) {
      this.f.form.patchValue({
        field_duration: duration
      })
    }

    return duration;
  }

  loadLessonData(entityID: string) {
    return this._drupalRESTService.httpGET('/events/' + entityID, [{ parameter: '_format', value: 'json' }]);
  }

  loadEnrollmentData(entityID: string) {
    return this._drupalRESTService.httpGET('/packages/' + entityID, [{ parameter: '_format', value: 'json' }]);
  }

  recurringMode: "Days" | "Weeks" | "Months" | "yearly" | "" = "Days";
  interval: number = 1;
  field_expiration_date: string = "";
  field_recurrent_weekdays: string = "";
  valueEOM = moment().endOf('month').format('YYYY-MM-DD');
  valueEOY = moment().endOf('year').format('YYYY-MM-DD');
  valueSpecificExpirationDate

  field_recurrent_weekday_su = false;
  field_recurrent_weekday_mo = false;
  field_recurrent_weekday_tu = false;
  field_recurrent_weekday_we = false;
  field_recurrent_weekday_th = false;
  field_recurrent_weekday_fr = false;
  field_recurrent_weekday_sa = false;

  field_use_alternating_instructor = false;
  field_instructor_alternate_list = [];

  uiUpdateExpirationDate($event: { target: { value: any; }; }) {
    // console.log('$event');
    // console.log($event.target.value.format('MM/DD/YYYY'));
    this.valueSpecificExpirationDate = ($event.target.value).format('YYYY-MM-DD')
    this.field_expiration_date = ($event.target.value).format('YYYY-MM-DD')
  }

  calcDateEOM() {
    let date = moment().endOf('month').format('YYYY-MM-DD');
    this.field_expiration_date = date;
    return date;
  }

  calcDateEOY() {
    let date = moment().endOf('year').format('YYYY-MM-DD');
    this.field_expiration_date = date;
    return date;
  }

  openCreditCardInputComponent(component) {
    this._dialogService.openDialog(component, "fullScreenCreditCardInput", {
      data: {
        'helloWorld': "helloWorld"
      },
    }).afterClosed().subscribe(data => {
      console.log('returned cc data', data)
      this.cc_data = data;
    });
  }

  openEntityComponent(component: ComponentType<unknown>, eckType: any, bundle: any, action: any, EntityID?: any, fieldsData?: {}, callback?: () => void) {
    console.log('fieldsData', fieldsData)
    console.log('action', action)
    this._dialogService.openDialog(component, "defaultWithData", {
      data: {
        EntityID: EntityID,
        eckType: eckType,
        bundle: bundle,
        action: action,
        fieldsData: fieldsData ?? '',
      },
    }).afterClosed().subscribe(data => {

    });
  }

  onStudentNameSelectThenLoadSquareSavedCards($studentID: { option: { value: any; }; }) {
    console.log('onStudentNameSelectThenLoadSquareSavedCards called...', $studentID);

    this.square_payment_methods = [];
    let studentID = ($studentID?.option?.value ? $studentID.option.value : $studentID);

    // Extract the student ID from parenthesis
    let regExp = /\(([^)]+)\)(?!.*\()/;
    let matches = regExp.exec(studentID);
    if (!studentID || !matches) { return; }

    // Get the studio ID from the form or injected data
    const studioId = this._authService.studios?.[0]?.id;
    if (!studioId) {
      console.error('Studio ID is required');
      return;
    }

    let endpoint = `/api/square/terminal/saved-cards/${studioId}`;
    let params = [
      { parameter: "field_student_name", value: matches[1] },
    ];

    this._drupalRESTService.httpGET(endpoint, params)
      .subscribe({
        next: (response: any) => {
          this.square_payment_methods = response.cards || [];
        },
        error: (error) => {
        }
      });
  }

  onStudentNameSelectThenLoadStripePaymentMethods($studentID: { option: { value: any; }; }) {
    console.log('onStudentNameSelectThenLoadStripePaymentMethods called...', $studentID)

    this.payment_methods = [];
    let studentID = ($studentID?.option?.value ? $studentID.option.value : $studentID);
    let endpoint = "/api_rest/v1/stripe_get_payment_methods";
    // Extract the student ID from parenthesis.
    let regExp = /\(([^)]+)\)(?!.*\()/;
    let matches = regExp.exec(studentID);
    if (!studentID) { return };
    if (matches == null) { return };

    switch (this.initActionType) {
      case 'create':
        break;
      default:
        break;
    }

    let params = [
      { parameter: "field_student_name", value: matches[1] },
    ]

    this._drupalRESTService.httpGET(endpoint, params)
      .subscribe(data => {
        let results = data;

        this.payment_methods = results;
      })
  }


  enrollmentPaymentIncrement() {
    let control = this._formBuilder.group({
      'target_id': [null, Validators.required],
      'payment_amount': [0, Validators.required]
    });

    const enrollmentFormArray = this.paymentForm.get('field_enrollment_name') as FormArray;

    enrollmentFormArray.controls.forEach((group: FormGroup, index: number) => {
      if (group.dirty) {
        this.enrollmentTouched = index;
      }
    });

    if (this.enrollmentTouched !== false) {
      const touchedEnrollmentGroup = enrollmentFormArray.at(this.enrollmentTouched as number) as FormGroup;
      control.get('payment_amount').setValue(touchedEnrollmentGroup.get('payment_amount').value);
      this.enrollmentTouched = false;
    }

    enrollmentFormArray.push(control);
    this.distributeGrossTuitionEqually(this.paymentForm.get('field_gross_tuition').value);
  }

  enrollmentPaymentRemove(index: number) {
    const enrollmentFormArray = this.paymentForm.get('field_enrollment_name') as FormArray;
    enrollmentFormArray.removeAt(index);
    this.distributeGrossTuitionEqually(this.paymentForm.get('field_gross_tuition').value);
  }

  /**
 * Distributes the given gross tuition amount equally among all enrollments.
 * @param grossTuition The total gross tuition amount to distribute.
 */
  distributeGrossTuitionEqually(grossTuition: number) {
    const enrollmentPayments = this.paymentForm.get('field_enrollment_name').value || [];

    if (!Array.isArray(enrollmentPayments) || enrollmentPayments.length === 0) {
      return;
    }

    const enrollmentFormArray = this.paymentForm.get('field_enrollment_name') as FormArray;
    const numEnrollments = enrollmentFormArray.length;

    // Calculate the total touched amount
    let totalTouchedAmount = 0;
    let untouchedEnrollments = 0;

    enrollmentFormArray.controls.forEach((group: FormGroup) => {
      const paymentAmountControl = group.get('payment_amount');
      if (paymentAmountControl.touched) {
        totalTouchedAmount += paymentAmountControl.value;
      } else {
        untouchedEnrollments++;
      }
    });

    // Calculate the default amount for untouched enrollments
    const remainingAmount = grossTuition - totalTouchedAmount;
    const defaultAmount = untouchedEnrollments > 0 ? remainingAmount / untouchedEnrollments : 0;

    enrollmentFormArray.controls.forEach((group: FormGroup) => {
      const paymentAmountControl = group.get('payment_amount');
      if (!paymentAmountControl.touched) {
        paymentAmountControl.setValue(defaultAmount);
      }
    });
  }

  delete_stripe_payment_method(stripe_card_id) {
    this.displayProgressSpinner(true);
    console.log('delete_stripe_payment_method called...', stripe_card_id);

    // Extract the student ID from parenthesis.
    let regExp = /\(([^)]+)\)/;
    let matches = regExp.exec(this.paymentForm.value.field_student_name);

    let endpoint = "/api_stripe/v1/stripe_remove_payment_method";
    let body = {
      "stripe_card_id": stripe_card_id,
      "field_student_name": matches[1]
    }

    this._drupalRESTService.httpPOST(endpoint, body)
      .subscribe(data => {
        let results = data;

        // Get the updated list of payment methods.
        this.onStudentNameSelectThenLoadStripePaymentMethods(this.paymentForm.value.field_student_name);
        this.displayProgressSpinner(false);
      }, error => {
        this.handleError(error);
      })
  }

  checkIfEventPassedCurrentTime() {
    const startDateArray = this._injectedDialogData?.event?._def?.extendedProps?.eventDetails?.startDate;

    if (startDateArray) {
      // Interpret the event time as local time
      const eventStartDateTimeLocal = moment(startDateArray.join('T'));
      const currentLocalTime = moment();

      this.isEventPassed = !eventStartDateTimeLocal.isBefore(currentLocalTime);

      return this.isEventPassed;
    } else {
      console.log('Event data not available, unable to determine if event has passed.');
      return false;
    }
  }
}

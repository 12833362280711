import { CommonModule } from "@angular/common";
import { Component, OnInit, AfterViewInit, ViewChild, VERSION, Directive, Input, ElementRef, HostListener, ComponentFactoryResolver, Injector, ApplicationRef, EmbeddedViewRef, ComponentRef, Renderer2, ChangeDetectorRef } from '@angular/core';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UtilityService } from 'src/app/services/utility.service';
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { FormControl, FormGroup, NgForm } from "@angular/forms";
import { DrupalRESTService } from "src/app/services/drupal-rest.service";
import { ActivatedRoute, Event, Params, Router } from "@angular/router";
import { saveAs } from 'file-saver';
import moment from "moment";
import { ViewTableComponent } from "../../base/view-table/view-table.component";
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AuthService } from 'src/app/services/auth.service';
import { DialogService } from 'src/app/services/dialog.service';
import { EntityRESTService } from 'src/app/services/entity-rest.service';
import { FieldsService } from 'src/app/services/fields.service';
import { TaxonomyService } from 'src/app/services/taxonomy.service';
import { AuthRESTService } from 'src/app/services/auth-rest.service';
import { MenuRESTService } from 'src/app/services/menu-rest.service';
import { FormBuilder } from '@angular/forms';
import { RouteEventsService } from 'src/app/route-events.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { ConfigPagesService } from 'src/app/services/config-pages.service';
import { PackagesService } from 'src/app/services/packages.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from 'src/app/services/user.service';
import { RefreshService } from 'src/app/services/refresh.service';
// Custom HTML Tooltip Component
@Component({
  selector: 'app-html-tooltip',
  template: `
    <div class="html-tooltip-content" [innerHTML]="content"></div>
  `,
  styles: [`
    .html-tooltip-content {
      background-color: white;
      color: black;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
      padding: 10px;
      max-width: 800px;
      max-height: 400px;
      overflow: auto;
      position: fixed;
      z-index: 1000;
    }
  `]
})
export class HtmlTooltipComponent {
  content: SafeHtml;
}

// Custom HTML Tooltip Directive
@Directive({
  selector: '[htmlTooltip]'
})
export class HtmlTooltipDirective {
  @Input('htmlTooltip') tooltipContent: string;
  private tooltipElement: HTMLElement | null = null;

  constructor(
    private el: ElementRef
  ) { }

  @HostListener('mouseenter')
  onMouseEnter(): void {
    if (this.tooltipContent) {
      this.show();
    }
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    this.hide();
  }

  private show(): void {
    console.log('Showing tooltip with content:', this.tooltipContent);

    // Remove any existing tooltip
    this.hide();

    // Create tooltip container
    this.tooltipElement = document.createElement('div');

    // Apply inline styles directly to ensure they take precedence
    const style = this.tooltipElement.style;
    style.position = 'absolute';
    style.backgroundColor = '#ffffff';
    style.color = '#000000';
    style.border = '2px solid #0066cc';
    style.borderRadius = '6px';
    style.boxShadow = '0 6px 16px rgba(0, 0, 0, 0.25)';
    style.padding = '0';
    style.maxWidth = '850px';
    style.maxHeight = '450px';
    style.overflow = 'auto';
    style.zIndex = '99999';
    style.fontSize = '14px';
    style.opacity = '1';

    // Initially position off-screen to measure size
    style.top = '-9999px';
    style.left = '-9999px';

    // Set content
    this.tooltipElement.innerHTML = this.tooltipContent;

    // Add to DOM
    document.body.appendChild(this.tooltipElement);

    // Use setTimeout to ensure the element is rendered before measuring
    setTimeout(() => {
      if (!this.tooltipElement) return;

      // Position the tooltip
      const rect = this.el.nativeElement.getBoundingClientRect();
      const tooltipRect = this.tooltipElement.getBoundingClientRect();

      let top = rect.bottom + window.scrollY + 5;
      let left = rect.left + window.scrollX;

      // Adjust position if needed
      if (left + tooltipRect.width > window.innerWidth) {
        left = window.innerWidth - tooltipRect.width - 10;
      }

      if (top + tooltipRect.height > window.innerHeight + window.scrollY) {
        top = rect.top + window.scrollY - tooltipRect.height - 5;
      }

      // Update position
      style.top = `${top}px`;
      style.left = `${left}px`;

      console.log('Tooltip positioned at:', top, left, 'with dimensions:', tooltipRect.width, tooltipRect.height);
    }, 0);
  }

  private hide(): void {
    if (this.tooltipElement && this.tooltipElement.parentNode) {
      this.tooltipElement.parentNode.removeChild(this.tooltipElement);
      this.tooltipElement = null;
    }
  }
}

@Component({
  selector: 'app-studio-business-summary',
  templateUrl: './studio-business-summary.component.html',
  styleUrls: ['./studio-business-summary.component.css']
})
export class StudioBusinessSummaryComponent extends ViewTableComponent implements OnInit {

  override displayedColumns: string[] = [
    'column_1',
    'column_2',
  ];

  consumable: any;

  currentReport: string = 'studioBusiness';
  week: string;
  year: string;

  Math: Math = Math;

  constructor(
    public override _refreshService: RefreshService,
    public override _dialogService: DialogService,
    public override _entityRESTService: EntityRESTService,
    public override _utilityService: UtilityService,
    public override _fieldsService: FieldsService,
    public override _drupalRESTService: DrupalRESTService,
    public override _taxonomyService: TaxonomyService,
    public override _router: Router,
    public override _activatedRoute: ActivatedRoute,
    public override _authRESTService: AuthRESTService,
    public override _authService: AuthService,
    public override _menuRESTService: MenuRESTService,
    public override _formBuilder: FormBuilder,
    public override _routeEventsService: RouteEventsService,
    public override _permissionsService: PermissionsService,
    public override _renderer2: Renderer2,
    public override _configPages: ConfigPagesService,
    public override _packagesService: PackagesService,
    public override _snackBar: MatSnackBar,
    public override _userRESTService: UserService,
    public override _el: ElementRef,
    public override _changeDetectorRef: ChangeDetectorRef,
    public override _sanitizer: DomSanitizer
  ) {
    super(
      _refreshService,
      _dialogService,
      _entityRESTService,
      _utilityService,
      _fieldsService,
      _drupalRESTService,
      _taxonomyService,
      _router,
      _activatedRoute,
      _authRESTService,
      _authService,
      _menuRESTService,
      _formBuilder,
      _routeEventsService,
      _permissionsService,
      _renderer2,
      _configPages,
      _packagesService,
      _snackBar,
      _userRESTService,
      _el,
      _changeDetectorRef,
      _sanitizer
    );
  }

  // Get the current studio name from AuthService
  get currentStudio(): string {
    return this._authService.studios?.[0]?.name || this._authService.studios?.[0]?.key || 'Unknown Studio';
  }

  onYearChange(event: any) {
    this.year = event.value;
    console.log('year', this.year)
    this.WeeksForYearData = this._fieldsService.WeeksForYearData(Number.parseInt(this.year));
  }

  override ngAfterContentInit(): void {
    this._activatedRoute.queryParams.subscribe(data => {
      // console.log('subscribed data', data)
      this.ShowProgressBar = false;

      if (data['year']) {
        this.year = data['year']
      } else {
        this.year = moment().format("YYYY");
      }
      if (data['week']) {
        this.week = data['week']
      } else {
        this.week = moment().add(0, 'week').format("w");
      }

      this.getData([
        { parameter: 'week', value: this.week },
        { parameter: 'year', value: this.year },
      ]);
    })
  }

  override getData(params) {
    // Create a moment object for the selected week/year
    let selectedDate = moment().year(+this.year).week(+this.week);
    // Get end of selected week (Sunday)
    let weekEnd = selectedDate.clone().endOf('isoWeek');
    // Create current date for comparison
    let currentDate = moment();

    // Compare the dates - disable if current date hasn't passed Sunday of the selected week
    this.isDisabled = !weekEnd.isBefore(currentDate);

    this.clearFormTableData();

    // Show Progress Bar (Loading indicator)
    this.ShowProgressBar = true;
    this.queryParams = params;

    // Add sorting paramaters, if available.
    if (this.sortParams !== undefined) {
      this.queryParams.push(this.sortParams)
    }

    // Load the new student list with the filtering parameters.
    this._utilityService.getStudioBusinessReport(
      this.queryParams
    )
      .subscribe((data) => {
        // ShowProgressBar
        this.ShowProgressBar = false;
        // console.log(data['#studio_content_table']['studio_content_table']['#rows']);
        let rows = data['#studio_content_table']['studio_content_table']['#rows'];
        this.consumable = data['#studio_content_table']['studio_content_table']['#consumable'];

        // Modify data to work in Angular.
        rows.forEach((element, index) => {
          // rows[index]['name'] = element.name.replace(/<[^>]*>?/gm, '');
        });

        this.data = data;
        this.dataSource = new MatTableDataSource(rows);

        this.pageSize = rows.length;

        // Pagination configuration.
        this.updatePaginationConfig(rows);
      },
        error => this.handleError(error)
      )
  }

  /**
   * Generates tooltip content for dropped enrollment details
   * @param type The type of enrollment (pre_original, original, renewal, extension)
   * @param isYtd Whether to use year-to-date data
   * @returns Formatted HTML string for tooltip display
   */
  getDroppedEnrollmentTooltip(type: string, isYtd: boolean = false): string {
    // Get the appropriate array based on type and whether it's YTD
    const arrayName = isYtd ? `${type}_sales_dropped_ytd_ui` : `${type}_sales_dropped_ui`;
    const dropsArray = this.consumable?.[arrayName];

    console.log('Tooltip data for', arrayName, dropsArray);

    if (!dropsArray || dropsArray.length === 0) {
      return '<div style="background-color: #ffffff; padding: 15px;">No dropped enrollments to display</div>';
    }

    let tooltipContent = '<div class="tooltip-table" style="background-color: #ffffff; padding: 15px;">';
    tooltipContent += '<table style="background-color: #ffffff; width: 100%; border-collapse: collapse; margin-bottom: 0;">';
    tooltipContent += '<thead><tr>';
    tooltipContent += '<th style="background-color: #f2f2f2; padding: 8px 12px; text-align: left; font-weight: bold; border: 1px solid #ddd;">Student</th>';
    tooltipContent += '<th style="background-color: #f2f2f2; padding: 8px 12px; text-align: left; font-weight: bold; border: 1px solid #ddd;">Package</th>';
    tooltipContent += '<th style="background-color: #f2f2f2; padding: 8px 12px; text-align: left; font-weight: bold; border: 1px solid #ddd;">Date Enrolled</th>';
    tooltipContent += '<th style="background-color: #f2f2f2; padding: 8px 12px; text-align: left; font-weight: bold; border: 1px solid #ddd;">Date Dropped</th>';
    tooltipContent += '<th style="background-color: #f2f2f2; padding: 8px 12px; text-align: center; font-weight: bold; border: 1px solid #ddd;">Total</th>';
    tooltipContent += '<th style="background-color: #f2f2f2; padding: 8px 12px; text-align: center; font-weight: bold; border: 1px solid #ddd;">Used</th>';
    tooltipContent += '<th style="background-color: #f2f2f2; padding: 8px 12px; text-align: center; font-weight: bold; border: 1px solid #ddd;">Unused</th>';
    tooltipContent += '<th style="background-color: #f2f2f2; padding: 8px 12px; text-align: right; font-weight: bold; border: 1px solid #ddd;">Amount</th>';
    tooltipContent += '</tr></thead><tbody>';

    dropsArray.forEach((drop, index) => {
      const rowStyle = index % 2 === 0 ? 'background-color: #ffffff;' : 'background-color: #f9f9f9;';
      tooltipContent += `<tr style="${rowStyle}">`;
      tooltipContent += `<td style="padding: 8px 12px; border: 1px solid #ddd;">${drop.student_name}</td>`;
      tooltipContent += `<td style="padding: 8px 12px; border: 1px solid #ddd;">${drop.package_name}</td>`;
      tooltipContent += `<td style="padding: 8px 12px; border: 1px solid #ddd;">${this.formatDate(drop.date_enrolled)}</td>`;
      tooltipContent += `<td style="padding: 8px 12px; border: 1px solid #ddd;">${this.formatDate(drop.date_dropped)}</td>`;
      tooltipContent += `<td style="padding: 8px 12px; text-align: center; border: 1px solid #ddd;">${drop.lessons_total}</td>`;
      tooltipContent += `<td style="padding: 8px 12px; text-align: center; border: 1px solid #ddd;">${drop.lessons_used}</td>`;
      tooltipContent += `<td style="padding: 8px 12px; text-align: center; border: 1px solid #ddd;">${drop.lessons_unused}</td>`;
      tooltipContent += `<td style="padding: 8px 12px; text-align: right; border: 1px solid #ddd;">$${drop.amount}</td>`;
      tooltipContent += '</tr>';
    });

    tooltipContent += '</tbody></table></div>';
    return tooltipContent;
  }

  /**
   * Format date for tooltip display
   * @param dateString Date string to format
   * @returns Formatted date string (MM/DD/YYYY)
   */
  private formatDate(dateString: string): string {
    if (!dateString) return '';
    const date = new Date(dateString);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  }
}

<app-entity-mat-dialog-title title="Drop Enrollment"></app-entity-mat-dialog-title>

<!-- <app-success-message [message]=this.successMessage></app-error-message> -->
<app-error-message [message]=this.errorMessage></app-error-message>

<div style="min-height:150px" *ngIf="showProgressSpinner">
    <mat-progress-spinner class="matProgressSpinnerCentered"
        *ngIf="showProgressSpinner" mode="indeterminate">
    </mat-progress-spinner>
</div>

<ng-container *ngIf="droppedData?.allLessonsTakenAndPaid">
	<app-warning-message [message]="'This enrollment can\'t be dropped because the enrollment has been fulfilled.'"></app-warning-message>
</ng-container>

<ng-container *ngIf="this.enrollmentEntityData?.field_dropped_date != ''">
	<app-warning-message [message]="'This enrollment has already been dropped.'"></app-warning-message>
</ng-container>

<form (ngSubmit)="onSubmit(f)" #f="ngForm" class="form">
  <div class="container">
      <div class="row">
					<div class="col-12">
						<mat-form-field>
							<!-- [ngModel]="now" -->
						<input
						ngModel
						required
						name="drop_date" matInput [ngxMatDatetimePicker]="field_dropped_date" placeholder="Drop Date">
						<mat-datepicker-toggle matSuffix [for]="field_dropped_date">
						</mat-datepicker-toggle>
						<ngx-mat-datetime-picker #field_dropped_date
								[hideTime]="true"
								[enableMeridian]="true"
								[showSpinners]="true"
								[showSeconds]="false"
								[stepHour]="1" [stepMinute]="15"
								[touchUi]="false">
						</ngx-mat-datetime-picker>
						</mat-form-field>
				</div>

				<div class="col-12">
					<mat-form-field class="full-width-field">
							<mat-label>Total Price</mat-label>
							<input
								required
								readonly
								name="field_drop"
								matInput
								type="number"
								ngModel="{{droppedData?.['total_price']}}">
					</mat-form-field>
			</div>

			<div class="col-12">
				<mat-form-field *ngIf="droppedData?.['total_dropped']" class="full-width-field">
						<mat-label>Total Dropped</mat-label>
						<input
							required
							readonly
							name="field_total_dropped"
							matInput
							type="number"
							ngModel="{{ droppedData?.['total_dropped'] }}">
				</mat-form-field>
			</div>

    </div>

  </div>
</form>

<!-- Refund calculation -->
<ng-container *ngIf="this.enrollmentEntityData">
	<mat-card>
		<mat-card-title>
				Drop & Refund Calculations
		</mat-card-title>

		<!-- Simple visual explanation -->
		<div class="refund-visual-container" style="background-color: #f8f9fa; padding: 15px; border-radius: 8px; margin-bottom: 20px;">
			<h3 style="margin-top: 0; color: #3f51b5; text-align: center;">Refund Calculation</h3>

			<!-- Calculator-like visual -->
			<div style="border: 1px solid #ddd; border-radius: 8px; padding: 15px; background-color: white; max-width: 400px; margin: 0 auto;">
				<div style="display: flex; align-items: center; margin: 10px 0;">
					<div style="min-width: 160px; font-weight: bold;">Amount Paid:</div>
					<div style="flex-grow: 1; text-align: right; font-size: 18px;">${{ formatCurrency(getTotalAmountPaid()) }}</div>
				</div>

				<div style="display: flex; align-items: center; margin: 10px 0; color: #d32f2f;">
					<div style="min-width: 160px; font-weight: bold;">- Lessons Taken:</div>
					<div style="flex-grow: 1; text-align: right; font-size: 18px;">${{ formatCurrency(calculateLessonsTakenValue()) }}</div>
				</div>

				<div style="display: flex; align-items: center; margin: 10px 0; color: #d32f2f;" *ngIf="parseFloat(this.enrollmentEntityData?.field_taxes) > 0">
					<div style="min-width: 160px; font-weight: bold;">- Taxes:</div>
					<div style="flex-grow: 1; text-align: right; font-size: 18px;">${{ formatCurrency(this.enrollmentEntityData?.field_taxes) }}</div>
				</div>

				<div style="display: flex; align-items: center; margin: 15px 0; border-top: 2px solid #ccc; padding-top: 10px; background-color: #e8f5e9; padding: 10px; border-radius: 4px;">
					<div style="min-width: 160px; font-weight: bold;">= Refund Amount:</div>
					<div style="flex-grow: 1; text-align: right; font-weight: bold; font-size: 22px; color: #4caf50;">${{ formatCurrency(estimatedRefund) }}</div>
				</div>
			</div>

			<!-- Simple explanation of the calculation in words -->
			<div style="margin-top: 15px; text-align: center; font-size: 14px; color: #555; max-width: 500px; margin: 15px auto 0;">
				<p *ngIf="getTotalAmountPaid() > 0 && estimatedRefund > 0" style="margin-bottom: 5px;">
					<strong>Explanation:</strong> You will receive a refund of ${{ formatCurrency(estimatedRefund) }} after dropping this enrollment.
				</p>
				<p *ngIf="getTotalAmountPaid() > 0 && estimatedRefund <= 0" style="margin-bottom: 5px;">
					<strong>Note:</strong> No refund is available because the value of lessons taken equals or exceeds the amount paid.
				</p>
				<p *ngIf="getTotalAmountPaid() <= 0" style="margin-bottom: 5px; color: #d32f2f;">
					<strong>Note:</strong> No refund is available because no payments have been made.
				</p>
			</div>
		</div>

		<!-- Enrollment details section - now always visible -->
		<div style="margin-top: 20px; border: 1px solid #e0e0e0; border-radius: 8px; padding: 15px;">
			<h3 style="margin-top: 0; color: #3f51b5; margin-bottom: 15px; font-size: 16px;">Enrollment Details</h3>

			<div style="display: grid; grid-template-columns: 1fr 1fr; gap: 15px;">
				<!-- Left column -->
				<div>
					<div style="margin-bottom: 16px; background-color: #f5f5f5; padding: 12px; border-radius: 6px;">
						<div style="font-weight: 500; color: #555; margin-bottom: 6px;">Payment Status</div>
						<div style="font-size: 15px; font-weight: 500;">{{ this.enrollmentEntityData?.field_payments_structure === 'paid_in_full' ? 'Paid in Full' : 'Installments' }}</div>
					</div>

					<div style="margin-bottom: 16px; background-color: #f5f5f5; padding: 12px; border-radius: 6px;">
						<div style="font-weight: 500; color: #555; margin-bottom: 6px;">Price Information</div>
						<div style="font-size: 15px; display: flex; justify-content: space-between; margin-bottom: 4px;">
							<span>Total Price:</span>
							<span style="font-weight: 500;">${{ formatCurrency(this.enrollmentEntityData?.field_enrollment_total_price) }}</span>
						</div>
						<div style="font-size: 15px; display: flex; justify-content: space-between; margin-bottom: 4px;">
							<span>Per Lesson:</span>
							<span style="font-weight: 500;">${{ formatCurrency(this.enrollmentEntityData?.field_enrollment_lesson_price) }}</span>
						</div>
						<div style="font-size: 15px; display: flex; justify-content: space-between;" *ngIf="parseFloat(this.enrollmentEntityData?.field_taxes) > 0">
							<span>Taxes:</span>
							<span style="font-weight: 500;">${{ formatCurrency(this.enrollmentEntityData?.field_taxes) }}</span>
						</div>
					</div>
				</div>

				<!-- Right column -->
				<div>
					<div style="margin-bottom: 16px; background-color: #f5f5f5; padding: 12px; border-radius: 6px;">
						<div style="font-weight: 500; color: #555; margin-bottom: 6px;">Lesson Information</div>
						<div style="font-size: 15px; display: flex; justify-content: space-between; margin-bottom: 4px;">
							<span>Total Lessons:</span>
							<span style="font-weight: 500;">{{ this.enrollmentEntityData.field_enrollment_lesson_count }}</span>
						</div>
						<div style="font-size: 15px; display: flex; justify-content: space-between; margin-bottom: 4px;">
							<span>Lessons Taken:</span>
							<span style="font-weight: 500;">{{ this.enrollmentEntityData?.field_lesson_used || 0 }}</span>
						</div>
						<div style="font-size: 15px; display: flex; justify-content: space-between;">
							<span>Lessons Available:</span>
							<span style="font-weight: 500;">{{ this.enrollmentEntityData.field_enrollment_lesson_count - this.enrollmentEntityData.field_lesson_used }}</span>
						</div>
					</div>

					<div style="margin-bottom: 16px; background-color: #f5f5f5; padding: 12px; border-radius: 6px;">
						<div style="font-weight: 500; color: #555; margin-bottom: 6px;">Payment Information</div>
						<div style="font-size: 15px; display: flex; justify-content: space-between;">
							<span>Total Paid:</span>
							<span style="font-weight: 500;">${{ formatCurrency(this.enrollmentEntityData?.field_total_payments_made) }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</mat-card>
</ng-container>

<mat-dialog-actions align="end">
        <button [disabled]="!this.f.valid || droppedData?.allLessonsTakenAndPaid || (this.enrollmentEntityData?.field_dropped_date != '')" type="button" (click)="onSubmit(f);" mat-raised-button
            color="accent">Drop</button>
				<button [disabled]="!this.f.valid || this.estimatedRefund <= 0 || droppedData?.allLessonsTakenAndPaid || (this.enrollmentEntityData?.field_dropped_date != '')" type="button" (click)="onSubmit(f, true);" mat-raised-button
            color="accent">Drop & Refund</button>
      <button mat-dialog-close type="submit" mat-raised-button
            color="accent">Cancel</button>
</mat-dialog-actions>

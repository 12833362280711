import { ComponentType } from '@angular/cdk/portal/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { InquiryComponent } from 'src/app/components/forms/inquiry/inquiry.component';
import { ServicesComponent } from 'src/app/components/forms/services/services.component';
import { LessonComponent } from 'src/app/components/forms/lesson/lesson.component';
import { GroupLessonComponent } from '../components/forms/group-lesson/group-lesson.component';
import { SchedulesComponent } from '../components/forms/schedules/schedules.component';
import { StudentAccountComponent } from '../components/forms/student-account/student-account.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(
    public dialog: MatDialog
  ) { }

  /**
   * Utility to open dialogs app wide with a default configuration.
   *
   * @param formName
   * @param dialogConfig
   */
   public openDialog(formName: ComponentType<unknown>, configName?, data?) {
    let dialogConfig = this.dialogConfig(configName, data);

    let dialogRef = this.dialog.open(formName, dialogConfig);
    return dialogRef;
  }

  dialogConfig(configName: string = 'default', data?: any) {
    let config: {};

    switch (configName) {
      case 'default': {
        config = {
          disableClose: true,
          height: 'auto',
          width: 640,
          closeOnNavigation: true,
          data: {
            height: "auto",
            closeOnNavigation: true
          }
        }
        return config;
        break;
      }
      case 'defaultWithData': {
        config = {
          disableClose: true,
          height: 'auto',
          width: 680,
          closeOnNavigation: true,
          data: data
        }
        return config;
        break;
      }
      case 'enrollmentFormWithData': {
        config = {
          disableClose: true,
          height: 'auto',
          width: 880,
          closeOnNavigation: true,
          data: data
        }
        return config;
        break;
      }
      case 'paymentFormWithData': {
        config = {
          disableClose: true,
          height: 'auto',
          width: 800,
          closeOnNavigation: true,
          data: data
        }
        return config;
        break;
      }
      case 'statusFormWithData': {
        config = {
          disableClose: true,
          height: 'auto',
          width: 630,
          closeOnNavigation: true,
          data: data
        }
        return config;
        break;
      }
      case 'enrollmentListFormWithData': {
        config = {
          disableClose: true,
          height: 'auto',
          width: 680,
          closeOnNavigation: true,
          data: data
        }
        return config;
        break;
      }
      case 'fullScreenCreditCardInput': {
        config = {
          maxWidth: '100vw',
          maxHeight: '100vh',
          height: '100%',
          width: '100%',
          panelClass: 'full-screen-modal',
          closeOnNavigation: true,
          data: data
        }
        return config;
        break;
      }
      case 'largerScreenDashboard': {
        config = {
          height: 'auto',
          width: 1400,
          panelClass: 'full-screen-modal',
          closeOnNavigation: true,
          data: data
        }
        return config;
        break;
      }
      case 'defaultXL': {
        config = {
          disableClose: true,
          height: 'auto',
          width: 740,
          closeOnNavigation: true,
          data: data
        }
        return config;
        break;
      }
      case 'defaultXL+': {
        config = {
          disableClose: true,
          height: 'auto',
          width: 760,
          closeOnNavigation: true,
          data: data
        }
        return config;
        break;
      }
      case 'confirm': {
        config = {
          width: '550px',
          height: '400px',
          closeOnNavigation: true,
          disableClose: false,
          data: data
        }
        return config;
        break;
      }
    }
  }

  closeAll() {
    this.dialog.closeAll();
  }

  /**
   * Utility method specifically for confirmation dialogs
   */
  public open(component: ComponentType<unknown>, config?: any) {
    const dialogConfig = config || this.dialogConfig('confirm');
    return this.dialog.open(component, dialogConfig);
  }

}

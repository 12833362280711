import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import moment from 'moment';
import { BaseSimpleDialogComponent } from '../base-simple-dialog/base-simple-dialog.component';

@Component({
  selector: 'app-enrollment-drop',
  templateUrl: './enrollment-drop.component.html',
  styleUrls: ['./enrollment-drop.component.css']
})
export class EnrollmentDropComponent extends BaseSimpleDialogComponent {

  estimatedRefund;
  droppedData;
  now = moment()

  // Helper method to make parseFloat available in the template
  parseFloat(value: any): number {
    return parseFloat(value) || 0;
  }

  // Helper method to format currency values
  formatCurrency(value: any): string {
    const amount = parseFloat(value) || 0;
    return amount.toFixed(2);
  }

  // Helper to calculate the value of lessons taken
  calculateLessonsTakenValue(): number {
    if (!this.enrollmentEntityData) return 0;

    const lessonsTaken = parseFloat(this.enrollmentEntityData.field_lesson_used) || 0;
    const lessonPrice = parseFloat(this.enrollmentEntityData.field_enrollment_lesson_price) || 0;
    return lessonsTaken * lessonPrice;
  }

  // Helper to get the total amount paid based on payment structure
  getTotalAmountPaid(): number {
    if (!this.enrollmentEntityData) return 0;

    if (this.enrollmentEntityData.field_payments_structure === 'paid_in_full') {
      return parseFloat(this.enrollmentEntityData.field_enrollment_total_price) || 0;
    } else {
      return parseFloat(this.enrollmentEntityData.field_total_payments_made) || 0;
    }
  }

  override ngAfterContentInit(): void {
    console.log('this._injectedDialogData["data"]', this._injectedDialogData['data']);
    this.getDroppedData()

    // Load the enrollment. TODO: Maybe pass the enrollment in. I'm not sure if the view loading has all the data.
    this._entityRESTService.getEntity('packages', 'enrollment', this._injectedDialogData['data']['EntityID'])
    .subscribe(data => {
      console.log('enrollmentEntityData', data);
      this.enrollmentEntityData = data;
      this.estimateRefund();
    })
  }

  estimateRefund() {
    // Fix: Use total_payments_made instead of total_paid for refund calculation
    // field_total_payments_made appears to be the more accurate payment tracking field
    const totalPayments = parseFloat(this.enrollmentEntityData.field_total_payments_made) || 0;
    const lessonsTaken = parseFloat(this.enrollmentEntityData.field_lesson_used) || 0;
    const lessonPrice = parseFloat(this.enrollmentEntityData.field_enrollment_lesson_price) || 0;
    const taxes = parseFloat(this.enrollmentEntityData.field_taxes) || 0;

    // Calculate the value of lessons taken
    const valueOfLessonsTaken = lessonsTaken * lessonPrice;

    // Calculate refund: amount paid minus value of lessons taken and taxes
    this.estimatedRefund = totalPayments - valueOfLessonsTaken - taxes;

    // For accounting consistency, if the student has paid in full (indicated by field_payments_structure),
    // make sure we use the total amount they've paid as the starting point
    if (this.enrollmentEntityData.field_payments_structure === 'paid_in_full') {
      const totalPrice = parseFloat(this.enrollmentEntityData.field_enrollment_total_price) || 0;
      // If they've paid in full but totalPayments shows a different value, use the total price
      if (totalPayments < totalPrice && totalPrice > 0) {
        this.estimatedRefund = totalPrice - valueOfLessonsTaken - taxes;
      }
    }

    // If the refund calculation is negative, set it to 0 (they don't owe any money)
    if (this.estimatedRefund < 0) {
      this.estimatedRefund = 0;
    }

    return this.estimatedRefund;
  }

  getDroppedData() {
    let body = [
      { parameter: 'enrollment_id', value: this._injectedDialogData['data']['EntityID'] },
    ];
    this._drupalRESTService.httpGET('/api_rest/v1/enrollmentTotalPriceTotalDropAmounts', body).subscribe(data => {
      this.droppedData = data;
      console.log('droppedData', data);
    })
  }

  override onSubmit(form: NgForm, refund = false) {
    this.displayProgressSpinner(true);

    let values = form.value;

    let params = [
      { parameter: 'enrollment_id', value: this._injectedDialogData['data']['EntityID'] },
      { parameter: 'drop_date', value: values['drop_date'].format("YYYY/MM/DD") },
    ];

    // Drop the enrollment.
    this._drupalRESTService.httpGET('/api_rest/v1/dropEnrollment', params)
    .subscribe(data => {
      if (refund) {
      } else {
        this.displayProgressSpinner(false);
        console.log('data', data)
      }

      if (data['success']) {
        this.closeDialog();
      }
    },
      error => {
        this.errorMessage = "There was a problem dropping this enrollment.";
        this.handleError(error)
        this.displayProgressSpinner(false);
      }
    );

    if (refund) {
      let paymentID;
      // let paymentSaleDate = moment();
      let paymentSaleDate = moment(values['drop_date']);
      let refundForm;

      // Generate the refund for enrollments drops.
      this.getUniquePaymentID()
      .subscribe(data => {
        paymentID = data;

        // Note: the estimateRefund() method now returns the absolute value already
        // and handles negative refunds by setting them to 0
        refundForm =
        {
          "title": 'Default Refund',
          "field_payment_id": paymentID,
          "field_date_and_time": paymentSaleDate.format("YYYY-MM-DD[T]HH:mm:ss"),
          "field_gross_tuition": -(this.estimatedRefund),
          "field_student_name": this.enrollmentEntityData.field_student.id,
          "field_scheduled_payment": {
            "target_id": this.enrollmentEntityData.field_scheduled_payments.id
          },
          "field_payment_type": "71",
          // "cc_number": "",
          // "cc_expiration": "",
          // "cc_postal_code": null,
          "field_enrollment_name": {
            "target_id": this.enrollmentEntityData.id
          }
        };

        this._entityRESTService.createEntity('payments', 'payment', refundForm).subscribe(data => {
          console.log('data', data);

          this.displayProgressSpinner(false);
          this.closeDialog();
        },
          error => this.handleError(error)
        )
      });
    }
  }

}

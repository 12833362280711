<div class="full-screen-payment-container">
  <div class="payment-content">
    <h3 class="text-center" *ngIf="!paymentStatus">
      Initiating the payment of {{ amount | currency:currency }} on the Square Terminal.
    </h3>
    <h3 class="text-center" *ngIf="paymentStatus === 'PENDING'">
      Payment in progress. Please complete the transaction on the Square Terminal.
    </h3>
    <h3 class="text-center" *ngIf="paymentStatus === 'SUCCESS'">
      Payment completed successfully!
    </h3>
    <h3 class="text-center" *ngIf="paymentStatus === 'FAILURE'">
      Payment failed
    </h3>
    <h3 class="text-center" *ngIf="paymentStatus === 'CANCELED'">
      Payment was canceled.
    </h3>

    <mat-progress-bar mode="indeterminate" *ngIf="isProcessing || paymentStatus === 'PENDING'"></mat-progress-bar>

    <div *ngIf="paymentStatus" class="payment-status mt-3"
         [ngClass]="{
           'text-success': paymentStatus === 'SUCCESS',
           'text-warning': paymentStatus === 'PENDING',
           'text-danger': paymentStatus === 'FAILURE',
           'text-info': paymentStatus === 'CANCELED'
         }">
      <mat-icon>
        {{ paymentStatus === 'SUCCESS' ? 'check_circle' :
           paymentStatus === 'PENDING' ? 'hourglass_empty' :
           paymentStatus === 'CANCELED' ? 'cancel' : 'error' }}
      </mat-icon>
      {{ paymentStatusMessage }}
    </div>

    <!-- Show specific help information for no device configured error -->
    <div *ngIf="paymentStatus === 'FAILURE' && paymentStatusMessage.includes('No Square Terminal device is configured')" class="error-help-info mt-3">
      <mat-card>
        <mat-card-content>
          <p><strong>What to do:</strong></p>
          <ol>
            <li>Contact your system administrator</li>
            <li>Ask them to configure a Square Terminal device for this studio</li>
            <li>Once configured, try the payment again</li>
          </ol>
          <p>In the meantime, you may want to try a different payment method.</p>

          <mat-divider class="my-3"></mat-divider>

          <div class="admin-note">
            <p><strong>⚙️ Administrator Note:</strong></p>
            <p>To configure a Square Terminal device for this studio:</p>
            <ol>
              <li>In Agenda Master, navigate to <strong>Configuration → Square Integration</strong></li>
              <li>First, ensure you've clicked "Authorize Square" if you haven't already connected your account</li>
              <li>In the "Terminal Devices" section, click "Refresh Devices" to see your available devices</li>
              <li>Select your device by clicking on it (it will be highlighted when selected)</li>
              <li>Click "Save Settings" to save your configuration</li>
            </ol>
            <p>After configuring the device, reload this page and try the payment again.</p>

            <!-- <div class="mt-3">
              <a mat-raised-button color="primary" href="/configuration/integrations/square" target="_blank">
                <mat-icon>settings</mat-icon>
                Go to Square Configuration
              </a>
            </div> -->
          </div>
        </mat-card-content>
      </mat-card>
    </div>

    <!-- Show specific help information for authentication errors -->
    <div *ngIf="paymentStatus === 'FAILURE' && paymentStatusMessage.includes('Square authentication failed')" class="error-help-info mt-3">
      <mat-card>
        <mat-card-content>
          <p><strong>What to do:</strong></p>
          <ol>
            <li>Contact your system administrator</li>
            <li>Ask them to reauthorize the Square account for this studio</li>
            <li>Once reauthorized, try the payment again</li>
          </ol>
          <p>In the meantime, you may want to try a different payment method.</p>

          <mat-divider class="my-3"></mat-divider>

          <div class="admin-note">
            <p><strong>⚙️ Administrator Note:</strong></p>
            <p>To reauthorize your Square account:</p>
            <ol>
              <li>In Agenda Master, navigate to <strong>Configuration → Square Integration</strong></li>
              <li>Click the "Deauthorize Square" tile to disconnect the current authorization</li>
              <li>Click the "Authorize Square" tile to reconnect</li>
              <li>Follow the prompts to log in to your Square account and grant permissions</li>
              <li>After successful authorization, select the device you want to use for this studio</li>
              <li>Click "Save Settings" to save your configuration</li>
            </ol>
            <p>After reauthorizing, try the payment again.</p>

            <!-- <div class="mt-3">
              <a mat-raised-button color="primary" href="/configuration/integrations/square" target="_blank">
                <mat-icon>settings</mat-icon>
                Go to Square Configuration
              </a>
            </div> -->
          </div>
        </mat-card-content>
      </mat-card>
    </div>

    <div *ngIf="checkoutId" class="checkout-id mt-2">
      Checkout ID: {{ checkoutId }}
    </div>

    <div *ngIf="amount" class="amount-display mt-2">
      Amount: {{ amount | currency:currency:'symbol':'1.2-2' }}
    </div>

    <!-- <button mat-raised-button
            color="primary"
            (click)="onCapturePaymentClick()"
            [disabled]="isProcessing || paymentStatus === 'PENDING'"
            *ngIf="!paymentStatus || paymentStatus === 'FAILURE'"
            type="button">
      <mat-icon>point_of_sale</mat-icon>
      Create Terminal Checkout
    </button> -->

    <button mat-raised-button
            color="warn"
            (click)="onCancelPaymentClick()"
            [disabled]="isProcessing || paymentStatus !== 'PENDING'"
            *ngIf="paymentStatus === 'PENDING'"
            type="button">
      <mat-icon>cancel</mat-icon>
      Cancel Checkout
    </button>

    <button mat-raised-button
            color="primary"
            (click)="onClose()"
            *ngIf="paymentStatus === 'SUCCESS' || paymentStatus === 'CANCELED' || paymentStatus === 'FAILURE'"
            type="button">
      <mat-icon>{{ paymentStatus === 'FAILURE' ? 'arrow_back' : 'check' }}</mat-icon>
      {{ paymentStatus === 'FAILURE' ? 'Go Back' : 'Close' }}
    </button>

  </div>

</div>
